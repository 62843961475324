import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import pathToRegexp from 'path-to-regexp'
import { keyBy, pick } from 'lodash'
import {
  vlCompanies,
  departments,
  legalForms,
  salaryForms,
  statuses,
  booleanOptions,
  countries,
  maritalStatuses,
  healthInsurances,
  offices,
  contractTypes,
  genders,
  multiFilters,
  subcontractTypes,
  branches,
  leaveReasons,
  departure,
  roles,
  cateringForms,
  entitlementValues,
  currencyValues,
  subdepartments,
  documentTypes,
  pensionOptions,
  permancyOfContractOptions,
  levelOptions,
} from './common/enums'
import styles from './Employees.module.scss'
import {
  validateRequired,
  validateDateRequiredForStudentAndServiceAgreement,
  validatePercentage,
  validateMoney,
  validateJiraIdUnique,
  validateRequiredOnContractChange,
  validateNonNegative,
  validateIsZero,
  validateLessOrEqualThan,
  validateNonZeroFTE,
  validateEmail,
  validateNullableEmail,
  validateWithoutSpaces,
  validateSWIFT,
  validateShorterThanMaxLength,
  validateNonNegativeInteger,
  nonRequiredField,
  requiredFieldOnlyForEmployment,
  requiredFieldOnlyForInternalContracts,
  requiredFieldExceptSomeVlCompanies,
  validateRequiredForEmployment,
  validateRequiredForInternalContracts,
  validateRequiredMultipleRules,
  validateRequiredForVlCompanyExceptSome,
  resolveDateField,
  stopPropagation,
  getBooleanFieldValue,
  parseDateField,
  parseBooleanField,
  parseNumberField,
  getFullNameReversed,
  getFteEquivalent,
  getSalaryEur,
  getSalaryUnits,
  getConfig,
  getSuperGrossSalary,
  validatePhoneNumber,
  validateDate,
  renderPhoneNumber,
  renderIBAN,
  validateEmptyOrDate,
  resolveUTCDate,
  parseNullableString,
  validateSubcontractSumLeq,
  validateSubcontractSumSalaryEur,
  nullableValidation,
  validateDecimalPercentage,
  validateSubdepartment,
  validateDepartment,
  validateCompany,
  applyOrganizationStructureRules,
} from './utils'
import moment from 'moment'

const toJiraUserPage = (...args) =>
  pathToRegexp.compile(getConfig().jiraUserPage)(...args)
const toSlackUserPage = (...args) =>
  pathToRegexp.compile(getConfig().slackUserPage)(...args)
const toGithubUserPage = (...args) =>
  pathToRegexp.compile(getConfig().githubUserPage)(...args)

const wincentId = 'nozdormu'

export const employeeFields = [
  {
    name: 'rowNumber',
    label: '#',
    edit: false,
    exportable: false,
  },
  {
    name: 'name',
    label: 'Name',
    edit: false,
    resolve: getFullNameReversed,
    exportable: false,
  },
  /* Personal details */
  {
    name: 'firstName',
    label: 'First Name',
    validation: ({ firstName }) => [
      [validateRequired, firstName],
      [validateShorterThanMaxLength, firstName],
    ],
    viewInTable: false,
    personalDetail: true,
  },
  {
    name: 'lastName',
    label: 'Last Name',
    validation: ({ lastName }) => [
      [validateRequired, lastName],
      [validateShorterThanMaxLength, lastName],
    ],
    viewInTable: false,
    personalDetail: true,
  },
  {
    name: 'maidenName',
    label: 'Maiden Name',
    validation: ({ maidenName }) => [
      [validateShorterThanMaxLength, maidenName],
    ],
    required: nonRequiredField,
    viewInTable: false,
    personalDetail: true,
  },
  {
    name: 'startingDate',
    validation: ({ startingDate }) => [[validateDate, startingDate]],
    formControl: {
      type: 'date',
    },
    label: 'Starting Date',
    parse: parseDateField,
    resolve: resolveUTCDate('startingDate'),
    personalDetail: true,
    dateRangeFilter: true,
  },
  {
    name: 'contractDate',
    label: 'Contract Date',
    validation: ({ contractDate }) => [[validateEmptyOrDate, contractDate]],
    required: nonRequiredField,
    formControl: {
      type: 'date',
    },
    resolve: resolveDateField('contractDate'),
    personalDetail: true,
  },
  {
    name: 'eSigningDeclaration',
    label: 'E-signing declaration',
    validation: ({ eSigningDeclaration }) => [
      [validateEmptyOrDate, eSigningDeclaration],
    ],
    required: nonRequiredField,
    formControl: {
      type: 'date',
    },
    resolve: resolveDateField('eSigningDeclaration'),
    personalDetail: true,
  },
  {
    name: 'jiraId',
    label: 'Jira ID',
    validation: ({ jiraId }, originalValue) => [
      [validateRequired, jiraId],
      [validateJiraIdUnique, jiraId, originalValue],
      [validateShorterThanMaxLength, jiraId],
    ],
    render: ({ jiraId }) => (
      <a
        href={toJiraUserPage({ jiraId })}
        target="_blank"
        rel="noopener noreferrer"
        onClick={stopPropagation}
      >
        {jiraId}
      </a>
    ),
    personalDetail: true,
  },
  {
    name: 'accountId',
    label: 'Account ID',
    validation: ({ accountId, vlCompany }) => [
      [
        validateRequiredForVlCompanyExceptSome,
        accountId,
        vlCompany,
        [wincentId],
      ],
      [validateShorterThanMaxLength, accountId],
    ],
    viewInTable: false,
    personalDetail: true,
    required: ({ vlCompany }) =>
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'phone',
    label: 'Phone',
    validation: ({ phone, contractType }) => [
      [validateRequiredForInternalContracts, phone, contractType],
      [validateShorterThanMaxLength, phone],
      [
        validatePhoneNumber,
        phone,
        !contractTypes[contractType].internalContract,
      ],
    ],
    render: ({ phone }) => renderPhoneNumber(phone),
    personalDetail: true,
    required: requiredFieldOnlyForInternalContracts,
  },
  {
    name: 'email',
    label: 'Email',
    validation: ({ email, vlCompany }) => [
      [
        validateEmail,
        email,
        !requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
      ],
      [validateShorterThanMaxLength, email],
      [validateRequiredForVlCompanyExceptSome, email, vlCompany, [wincentId]],
    ],
    formControl: {
      type: 'email',
    },
    render: ({ email }) => (
      <a href={`mailto:${email}`} onClick={stopPropagation}>
        {email}
      </a>
    ),
    personalDetail: true,
    required: ({ vlCompany }) =>
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'emailVL',
    label: 'Email VL',
    validation: ({ emailVL }) => [
      [validateNullableEmail, emailVL],
      [validateShorterThanMaxLength, emailVL],
    ],
    formControl: {
      type: 'email',
    },
    render: ({ emailVL }) => (
      <a href={`mailto:${emailVL}`} onClick={stopPropagation}>
        {emailVL}
      </a>
    ),
    required: nonRequiredField,
    viewInTable: false,
    personalDetail: true,
  },
  {
    name: 'slackId',
    label: 'Slack ID',
    validation: ({ slackId, contractType }) => [
      [validateRequiredForInternalContracts, slackId, contractType],
    ],
    render: ({ slackId }) =>
      slackId != null &&
      slackId.trim() !== '' && (
        <a
          href={toSlackUserPage({ slackId })}
          target="_blank"
          rel="noopener noreferrer"
          onClick={stopPropagation}
        >
          {slackId}
        </a>
      ),
    personalDetail: true,
    required: requiredFieldOnlyForInternalContracts,
  },
  {
    name: 'githubUsername',
    label: 'Github Username',
    validation: ({ githubUsername }) => [
      [validateShorterThanMaxLength, githubUsername],
    ],
    render: ({ githubUsername }) =>
      githubUsername != null &&
      githubUsername.trim() !== '' && (
        <a
          href={toGithubUserPage({ githubUsername })}
          target="_blank"
          rel="noopener noreferrer"
          onClick={stopPropagation}
        >
          {githubUsername}
        </a>
      ),
    required: nonRequiredField,
    personalDetail: true,
  },
  {
    name: 'birthDate',
    label: 'Birth Date',
    validation: ({ birthDate, contractType, vlCompany }) => [
      [
        validateDate,
        birthDate,
        !requiredFieldOnlyForInternalContracts({ contractType }) ||
          !requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
      ],
      [
        validateRequiredMultipleRules,
        [
          validateRequiredForVlCompanyExceptSome(birthDate, vlCompany, [
            wincentId,
          ]),
          validateRequiredForInternalContracts(birthDate, contractType),
        ],
      ],
    ],
    formControl: {
      type: 'date',
    },
    parse: parseDateField,
    resolve: resolveDateField('birthDate'),
    personalDetail: true,
    required: ({ contractType, vlCompany }) =>
      requiredFieldOnlyForInternalContracts({ contractType }) &&
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'gender',
    label: 'Gender',
    formControl: {
      componentClass: 'select',
    },
    options: genders,
    personalDetail: true,
    required: requiredFieldOnlyForInternalContracts,
  },
  {
    name: 'street',
    label: 'Street',
    validation: ({ street, contractType, vlCompany }) => [
      [
        validateRequiredMultipleRules,
        [
          validateRequiredForVlCompanyExceptSome(street, vlCompany, [
            wincentId,
          ]),
          validateRequiredForInternalContracts(street, contractType),
        ],
      ],
      [validateShorterThanMaxLength, street],
    ],
    personalDetail: true,
    required: ({ contractType, vlCompany }) =>
      requiredFieldOnlyForInternalContracts({ contractType }) &&
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'city',
    label: 'City',
    validation: ({ city, contractType, vlCompany }) => [
      [
        validateRequiredMultipleRules,
        [
          validateRequiredForVlCompanyExceptSome(city, vlCompany, [wincentId]),
          validateRequiredForInternalContracts(city, contractType),
        ],
      ],
      [validateShorterThanMaxLength, city],
    ],
    personalDetail: true,
    required: ({ contractType, vlCompany }) =>
      requiredFieldOnlyForInternalContracts({ contractType }) &&
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'zipCode',
    label: 'ZIP Code',
    validation: ({ zipCode, contractType, vlCompany }) => [
      [
        validateRequiredMultipleRules,
        [
          validateRequiredForVlCompanyExceptSome(zipCode, vlCompany, [
            wincentId,
          ]),
          validateRequiredForInternalContracts(zipCode, contractType),
        ],
      ],
      [validateShorterThanMaxLength, zipCode],
    ],
    personalDetail: true,
    required: ({ contractType, vlCompany }) =>
      requiredFieldOnlyForInternalContracts({ contractType }) &&
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'country',
    label: 'Country',
    validation: ({ country, contractType, vlCompany }) => [
      [
        validateRequiredMultipleRules,
        [
          validateRequiredForVlCompanyExceptSome(country, vlCompany, [
            wincentId,
          ]),
          validateRequiredForInternalContracts(country, contractType),
        ],
      ],
    ],
    formControl: {
      componentClass: 'select',
    },
    options: countries,
    personalDetail: true,
    required: ({ contractType, vlCompany }) =>
      requiredFieldOnlyForInternalContracts({ contractType }) &&
      requiredFieldExceptSomeVlCompanies({ vlCompany }, [wincentId]),
  },
  {
    name: 'postalAddress',
    label: 'Postal Address',
    validation: ({ postalAddress }) => [
      [validateShorterThanMaxLength, postalAddress],
    ],
    required: nonRequiredField,
    personalDetail: true,
  },
  /* Draft */
  {
    name: 'isDraft',
    label: 'Is Draft',
    exportValue: ({ isDraft }) => (isDraft ? 1 : 0),
    viewInTable: false,
  },
  /* Referral */
  {
    name: 'referral',
    label: 'Referred By',
    viewInEditor: false,
    personalDetail: true,
  },
  {
    name: 'referralId',
    label: 'Referred By',
    formControl: {
      componentClass: 'select',
      creatable: true,
    },
    personalDetail: true,
    viewInTable: false,
    exportable: false,
  },
  {
    name: 'msDynamicsId',
    label: 'MS Dynamics ID',
    personalDetail: true,
    viewInTable: true,
    exportable: true,
    omitInDatabase: true,
    edit: false,
  },
  /* Contract */
  {
    name: 'validFrom',
    label: 'Valid From',
    validation: ({ validFrom }) => [[validateDate, validFrom]],
    formControl: {
      type: 'date',
    },
    salaryInfo: true,
    contractInfo: true,
    contractField: true,
    dateRangeFilter: true,
    parse: parseDateField,
    resolve: resolveDateField('validFrom'),
    monthlyExportable: false,
  },
  {
    name: 'validTo',
    label: 'Valid To',
    salaryInfo: true,
    contractInfo: true,
    viewInTable: false,
    edit: false,
    exportable: false,
    resolve: resolveDateField('validTo'),
  },
  {
    name: 'endingDate',
    label: 'Ending Date',
    contractInfo: true,
    viewInTable: false,
    edit: false,
    resolve: resolveDateField('endingDate'),
    exportable: false,
  },
  {
    name: 'isActive',
    label: 'Status',
    validation: ({ isActive }) => [[validateRequired, isActive]],
    formControl: {
      componentClass: 'select',
    },
    options: statuses,
    resolve: ({ isActive }) =>
      isActive ? statuses.active.label : statuses.inactive.label,
    parse: (value) => {
      const status = statuses[value]?.value
      return typeof value !== 'boolean' && status !== undefined ? status : value
    },
    viewInTable: false,
    contractField: true,
    monthlyExportable: false,
  },
  /* Start of monthly export values */
  {
    name: 'activeFrom',
    label: 'Active From',
    resolve: resolveDateField('activeFrom'),
    viewInTable: false,
    edit: false,
    monthlyExportable: true,
  },
  {
    name: 'activeTo',
    label: 'Active To',
    resolve: resolveDateField('activeTo'),
    viewInTable: false,
    edit: false,
    monthlyExportable: true,
  },
  /* End of monthly export values */
  {
    name: 'subcontractType',
    label: 'Subcontract Type',
    validation: ({ subcontractType }) => [[validateRequired, subcontractType]],
    salaryInfo: false,
    contractInfo: false,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: true,
    viewInTable: false,
    options: subcontractTypes,
    filter: multiFilters.simple,
  },
  {
    name: 'legalForm',
    label: 'Legal Form',
    validation: ({ legalForm }) => [[validateRequired, legalForm]],
    formControl: {
      componentClass: 'select',
    },
    options: legalForms,
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    contractField: true,
    filter: multiFilters.simple,
  },
  {
    name: 'companyId',
    label: 'Default Company',
    formControl: {
      componentClass: 'select',
    },
    hideOnCreate: true,
    viewInTable: false,
    companyDetail: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    parse: (value) =>
      value !== undefined && value !== null ? Number(value) : value,
    exportable: false,
  },
  {
    name: 'employer',
    label: 'Employer',
    validation: ({ employer }) => [[validateRequired, employer]],
    formControl: {
      componentClass: 'select',
    },
    options: branches,
    resolve: ({ employer }) => (employer ? branches[employer].label : ''),
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    viewInTable: false,
    employmentDetail: true,
  },
  {
    name: 'permancyOfContract',
    label: 'Permanency of Contract',
    formControl: {
      componentClass: 'select',
    },
    isNullable: true,
    options: permancyOfContractOptions,
    employmentDetail: true,
    viewInTable: false,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
  },
  {
    name: 'vlCompany',
    label: 'VL Company',
    validation: ({ vlCompany, validFrom }) => [
      [validateCompany, vlCompany, validFrom],
    ],
    formControl: {
      componentClass: 'select',
    },
    options: vlCompanies,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: true,
    contractField: true,
    filter: multiFilters.multi,
  },
  {
    name: 'department',
    label: 'Department',
    validation: ({ department, vlCompany, validFrom }) => [
      [validateDepartment, department, vlCompany, validFrom],
    ],
    formControl: {
      componentClass: 'select',
    },
    options: departments,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: true,
    contractField: true,
    filter: multiFilters.multi,
  },
  {
    name: 'subdepartment',
    label: 'Subdepartment',
    validation: ({ subdepartment, department, vlCompany, validFrom }) => [
      [validateSubdepartment, subdepartment, department, vlCompany, validFrom],
    ],
    formControl: {
      componentClass: 'select',
    },
    required: () => false,
    options: subdepartments,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: true,
    contractField: true,
    filter: multiFilters.multi,
  },
  {
    name: 'reportsTo',
    label: 'Reports To',
    formControl: {
      componentClass: 'select',
    },
    viewInTable: false,
    contractInfo: true,
    contractField: true,
    reportingSubcontractInfo: true,
    required: () => true,
  },
  {
    name: 'costCenter',
    label: 'Cost Center',
    validation: ({ costCenter }) => [
      [validateShorterThanMaxLength, costCenter],
    ],
    required: nonRequiredField,
    viewInTable: false,
    contractField: true,
    contractInfo: true,
    reportingSubcontractInfo: true,
  },
  {
    name: 'role',
    label: 'Role',
    resolve: ({ role }) => (role ? roles[role].label : ''),
    formControl: {
      componentClass: 'select',
    },
    options: roles,
    isNullable: true,
    parse: parseNullableString,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: false,
    contractField: true,
    filter: multiFilters.multi,
  },
  {
    name: 'office',
    label: 'Office',
    validation: ({ office }) => [[validateRequired, office]],
    formControl: {
      componentClass: 'select',
    },
    options: offices,
    contractInfo: true,
    contractField: true,
    filter: multiFilters.multi,
  },
  {
    name: 'contractType',
    label: 'Contract Type',
    validation: ({ contractType }) => [[validateRequired, contractType]],
    formControl: {
      componentClass: 'select',
    },
    options: contractTypes,
    contractInfo: true,
    contractField: true,
    filter: multiFilters.simple,
  },
  {
    name: 'salaryForm',
    label: 'Salary Form',
    validation: ({ salaryForm }) => [[validateRequired, salaryForm]],
    formControl: {
      componentClass: 'select',
    },
    render: ({ salaryForm }) =>
      salaryForms[salaryForm]?.abbr ||
      salaryForms[salaryForm]?.label ||
      salaryForm,
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    contractField: true,
    options: salaryForms,
    filter: multiFilters.simple,
  },
  {
    name: 'salary',
    label: 'Salary',
    validation: ({ salary }) => [[validateMoney, salary]],
    formControl: {
      type: 'number',
      step: '0.01',
    },
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    parse: (value) => Number(value).toFixed(2),
    units: getSalaryUnits(true),
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'currency',
    label: 'Currency',
    validation: ({ currency }) => [[validateRequired, currency]],
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    contractField: true,
    formControl: {
      componentClass: 'select',
    },
    options: currencyValues,
    viewInTable: false,
  },
  {
    name: 'salaryEur',
    label: 'Salary EUR',
    validation: ({ salary, currency, subcontracts }) => [
      [
        validateSubcontractSumSalaryEur,
        getSalaryEur({ salary, currency }),
        subcontracts,
      ],
    ],
    formControl: {
      type: 'number',
      readOnly: true,
    },
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    parse: (value) => Number(value).toFixed(2),
    resolve: getSalaryEur,
    units: getSalaryUnits(false),
    contractField: true,
    rangeFilter: true,
    omitInDatabase: true,
  },
  {
    name: 'level',
    label: 'Level',
    formControl: {
      componentClass: 'select',
    },
    options: levelOptions,
    sortByValue: true,
    contractInfo: true,
    contractField: true,
    viewInTable: true,
  },
  {
    name: 'hasSalaryBonus',
    label: 'Has Salary Bonus',
    options: booleanOptions,
    resolve: getBooleanFieldValue('hasSalaryBonus'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    viewInTable: false,
    edit: false,
    exportable: false,
  },
  {
    name: 'salaryBonus',
    label: 'Salary Bonus',
    validation: ({ salaryBonus }) => [[validatePercentage, salaryBonus]],
    formControl: {
      type: 'number',
    },
    parse: (value) => Number(value),
    exportValue: ({ salaryBonus }) => `${Number(salaryBonus)}%`,
    units: () => '%',
    salaryInfo: true,
    contractInfo: true,
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'superGrossSalary',
    label: 'SuperGross Salary',
    formControl: {
      type: 'number',
      readOnly: true,
    },
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    resolve: getSuperGrossSalary,
    parse: (value) => Number(value).toFixed(2),
    units: getSalaryUnits(false),
    contractField: true,
    omitInDatabase: true,
  },
  {
    name: 'fte',
    label: 'FTE',
    validation: ({ fte, salaryForm, subcontracts }) => [
      [validateNonNegative, fte],
      [validateNonZeroFTE, fte, salaryForm],
      [validateLessOrEqualThan(100), fte],
      [
        validateSubcontractSumLeq,
        fte,
        subcontracts,
        subcontractTypes.reporting.key,
        'fte',
      ],
    ],
    formControl: {
      type: 'number',
    },
    salaryInfo: true,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: true,
    parse: (value) => Number(value).toFixed(1),
    exportValue: ({ fte }) => `${Number(fte)}%`,
    units: () => '%',
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'fte',
    label: 'FTE',
    validation: ({ fte, salaryForm, subcontracts }) => [
      [nullableValidation, fte, validateNonNegative],
      [nullableValidation, fte, validateLessOrEqualThan(100)],
    ],
    formControl: {
      type: 'number',
    },
    salaryInfo: false,
    contractInfo: false,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
    parse: (value) => Number(value).toFixed(1),
    exportValue: ({ fte }) => `${Number(fte)}%`,
    units: () => '%',
    contractField: true,
    rangeFilter: true,
    required: () => false,
  },
  {
    name: 'fteEquivalent',
    label: 'FTE Equivalent',
    formControl: {
      type: 'number',
      readOnly: true,
    },
    salaryInfo: true,
    contractInfo: true,
    parse: (value) => Number(value),
    resolve: getFteEquivalent,
    units: () => '€/m',
    contractField: true,
    omitInDatabase: true,
  },
  {
    name: 'officeTime',
    label: 'Office Time',
    validation: ({ officeTime }) => [
      [validateNonNegative, officeTime],
      [validateLessOrEqualThan(100), officeTime],
    ],
    formControl: {
      type: 'number',
    },
    contractInfo: true,
    parse: parseNumberField,
    exportValue: ({ officeTime }) => `${Number(officeTime)}%`,
    units: () => '%',
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'isSubcontract',
    label: 'Is subcontract',
    formControl: {
      type: 'boolean',
      readOnly: true,
    },
    contractInfo: true,
    salaryInfo: true,
    options: booleanOptions,
    parse: (value) => {
      if (value == null) {
        return false
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    resolve: getBooleanFieldValue('isSubcontract'),
    filter: multiFilters.simple,
    filterDefaultValue: [booleanOptions.false.key],
    viewInTable: true,
    omitInDatabase: true,
  },
  {
    name: 'signingBonus',
    label: 'Signing Bonus',
    validation: ({ signingBonus }) => [[validateMoney, signingBonus]],
    formControl: {
      type: 'number',
    },
    contractInfo: true,
    parse: (value) => Number(value),
    units: () => '€',
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'equipmentBonus',
    label: 'Equipment Bonus',
    validation: ({ equipmentBonus }) => [[validateMoney, equipmentBonus]],
    formControl: {
      type: 'number',
    },
    contractInfo: true,
    parse: (value) => Number(value),
    units: () => '€',
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'sharePurchase',
    label: 'Share Purchase',
    validation: ({ sharePurchase }) => [
      [validateNonNegative, sharePurchase],
      [validateLessOrEqualThan(10), sharePurchase],
    ],
    formControl: {
      type: 'number',
    },
    contractInfo: true,
    parse: (value) => Number(value),
    exportValue: ({ sharePurchase }) => `${Number(sharePurchase)}%`,
    units: () => '%',
    contractField: true,
    rangeFilter: true,
    displayForReadOnly: false,
  },
  {
    name: 'shareBonus',
    label: 'Share Bonus',
    validation: ({ shareBonus }) => [[validatePercentage, shareBonus]],
    formControl: {
      type: 'number',
    },
    contractInfo: true,
    parse: (value) => Number(value),
    units: () => '%',
    exportValue: ({ shareBonus }) => `${Number(shareBonus)}%`,
    contractField: true,
    rangeFilter: true,
    displayForReadOnly: false,
  },
  {
    name: 'sparringShareBonus',
    label: 'SparringShare Bonus',
    validation: ({ sparringShareBonus }) => [
      [validatePercentage, sparringShareBonus],
    ],
    formControl: {
      type: 'number',
      label: 'Sparring Share Bonus',
    },
    contractInfo: true,
    parse: (value) => Number(value),
    units: () => '%',
    exportValue: ({ sparringShareBonus }) => `${Number(sparringShareBonus)}%`,
    contractField: true,
    rangeFilter: true,
    displayForReadOnly: false,
  },
  {
    name: 'sharesTotal',
    label: 'Shares',
    resolve: ({ shareNumber, manualShares }) =>
      Number(shareNumber) + Number(manualShares),
    tooltipText: ({ shareNumber, manualShares }) => (
      <span>
        Manual shares: {manualShares}
        <br />
        Salary shares: {shareNumber}
      </span>
    ),
    viewInTable: false,
    contractField: false,
    contractInfo: true,
    edit: false,
  },
  {
    name: 'legacyShares',
    label: 'Legacy Shares',
    validation: ({ legacyShares }) => [[validateRequired, legacyShares]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('legacyShares'),
    parse: parseBooleanField,
    contractInfo: true,
    contractField: true,
    personalDetail: false,
    filter: multiFilters.simple,
    viewInTable: true,
    displayForReadOnly: false,
  },
  {
    name: 'rollingShares',
    label: 'Rolling Shares',
    validation: ({ rollingShares }) => [[validateRequired, rollingShares]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('rollingShares'),
    parse: parseBooleanField,
    contractInfo: true,
    contractField: true,
    personalDetail: false,
    filter: multiFilters.simple,
    viewInTable: true,
    displayForReadOnly: false,
  },
  {
    name: 'eligibleCLevelShares',
    label: 'Eligible for C-levelShares',
    validation: ({ eligibleCLevelShares }) => [
      [validateRequired, eligibleCLevelShares],
    ],
    formControl: {
      componentClass: 'select',
      label: 'Eligible for C-level Shares',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('eligibleCLevelShares'),
    parse: parseBooleanField,
    contractInfo: true,
    contractField: true,
    personalDetail: false,
    filter: multiFilters.simple,
    viewInTable: true,
  },
  {
    name: 'cLevelSharePurchase',
    label: 'C-level SharePurchase',
    validation: ({ cLevelSharePurchase, eligibleCLevelShares }) => [
      [
        validateIsZero,
        cLevelSharePurchase,
        parseBooleanField(eligibleCLevelShares),
      ],
      [validatePercentage, cLevelSharePurchase],
    ],
    formControl: {
      type: 'number',
      label: 'C-level Share Purchase',
    },
    contractInfo: true,
    parse: (value) => Number(value),
    units: () => '%',
    exportValue: ({ cLevelSharePurchase }) => `${Number(cLevelSharePurchase)}%`,
    contractField: true,
    rangeFilter: true,
    displayForReadOnly: false,
  },
  {
    name: 'vlSharePurchase',
    label: 'VL Share Purchase',
    validation: ({ vlSharePurchase }) => [
      [validateDecimalPercentage, vlSharePurchase],
    ],
    formControl: {
      type: 'number',
      step: '0.01',
      label: 'VL Share Purchase',
    },
    contractInfo: true,
    parse: (value) => Number(value).toFixed(2),
    units: () => '%',
    exportValue: ({ vlSharePurchase }) => `${Number(vlSharePurchase)}%`,
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'vgSharePurchase',
    label: 'VG Share Purchase',
    validation: ({ vgSharePurchase }) => [
      [validateDecimalPercentage, vgSharePurchase],
    ],
    formControl: {
      type: 'number',
      step: '0.01',
      label: 'VG Share Purchase',
    },
    contractInfo: true,
    parse: (value) => Number(value).toFixed(2),
    units: () => '%',
    exportValue: ({ vgSharePurchase }) => `${Number(vgSharePurchase)}%`,
    contractField: true,
    rangeFilter: true,
  },
  {
    name: 'cateringPolicy',
    label: 'Catering Policy',
    validation: ({ cateringPolicy }) => [[validateRequired, cateringPolicy]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('cateringPolicy'),
    parse: (value) => {
      if (value == null) {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    contractField: true,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: false,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'sabbatical',
    label: 'Sabbatical',
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('sabbatical'),
    parse: (value) => {
      if (value == null) {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    contractField: true,
    contractInfo: true,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: false,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'reasonForLeaving',
    label: 'Reason For Leaving',
    formControl: {
      componentClass: 'select',
    },
    options: leaveReasons,
    isNullable: true,
    parse: parseNullableString,
    contractInfo: true,
    contractField: true,
    personalDetail: false,
    employmentDetail: false,
    viewInTable: false,
  },
  {
    name: 'departure',
    label: 'Departure',
    formControl: {
      componentClass: 'select',
    },
    options: departure,
    isNullable: true,
    parse: parseNullableString,
    contractInfo: true,
    contractField: true,
    personalDetail: false,
    employmentDetail: false,
    viewInTable: false,
  },
  {
    name: 'note',
    label: 'General Note',
    formControl: {
      componentClass: 'textarea',
    },
    render: ({ note }) => (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{note}</Tooltip>}
      >
        <p className={styles.textTableValue}>{note}</p>
      </OverlayTrigger>
    ),
    contractField: true,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: true,
  },
  {
    name: 'comment',
    label: 'Change Reason',
    formControl: {
      componentClass: 'textarea',
    },
    validation: ({ comment }, originalValue, contractChanged) => [
      [
        validateRequiredOnContractChange,
        comment,
        originalValue,
        contractChanged,
      ],
    ],
    viewInTable: false,
    exportable: false,
    hideOnCreate: true,
  },
  /* Employment details */
  {
    name: 'contractExpiration',
    label: 'Contract Expiration',
    formControl: {
      type: 'date',
    },
    required: nonRequiredField,
    validation: ({ contractExpiration, legalForm }) => [
      [
        validateDateRequiredForStudentAndServiceAgreement,
        contractExpiration,
        legalForm,
      ],
    ],
    parse: parseDateField,
    resolve: resolveDateField('contractExpiration'),
    employmentDetail: true,
    showForAuthor: true,
    viewInTable: false,
    dateRangeFilter: true,
  },
  {
    name: 'terminationPeriod',
    label: 'Termination Period',
    validation: ({ terminationPeriod }) => [
      [validateNonNegativeInteger, terminationPeriod],
    ],
    units: () => 'months',
    employmentDetail: true,
    companyDetail: true,
    viewInTable: false,
    required: () => false,
  },
  {
    name: 'documentType',
    label: 'Document Type',
    formControl: {
      componentClass: 'select',
    },
    isNullable: true,
    options: documentTypes,
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'idCard',
    label: 'ID Card',
    validation: ({ idCard, legalForm }) => [
      [validateRequiredForEmployment, idCard, legalForm],
      [validateShorterThanMaxLength, idCard],
    ],
    required: requiredFieldOnlyForEmployment,
    employmentDetail: true,
    companyDetail: true,
    viewInTable: false,
  },
  {
    name: 'birthId',
    label: 'Birth ID',
    validation: ({ birthId }) => [
      [validateRequired, birthId],
      [validateShorterThanMaxLength, birthId],
    ],
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'birthPlace',
    label: 'Birth Place',
    validation: ({ birthPlace }) => [
      [validateRequired, birthPlace],
      [validateShorterThanMaxLength, birthPlace],
    ],
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'nationality',
    label: 'Nationality',
    validation: ({ nationality }) => [[validateRequired, nationality]],
    formControl: {
      componentClass: 'select',
    },
    options: countries,
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'maritalStatus',
    label: 'Marital Status',
    validation: ({ maritalStatus }) => [[validateRequired, maritalStatus]],
    formControl: {
      componentClass: 'select',
    },
    options: maritalStatuses,
    employmentDetail: true,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'healthInsurance',
    label: 'Health Insurance',
    validation: ({ healthInsurance }) => [[validateRequired, healthInsurance]],
    formControl: {
      componentClass: 'select',
    },
    options: healthInsurances,
    employmentDetail: true,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'isStudent',
    label: 'Is Student',
    validation: ({ isStudent }) => [[validateRequired, isStudent]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('isStudent'),
    parse: (value) => {
      if (value == null) {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'isUniversityStudent',
    label: 'Is University Student',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('isUniversityStudent'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: false,
  },
  {
    name: 'isHighScoolStudent',
    label: 'Is High School Student',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('isHighScoolStudent'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    paymentSubcontractInfo: false,
    reportingSubcontractInfo: false,
  },
  {
    name: 'levyExemption',
    label: 'Levy Exemption',
    validation: ({ levyExemption }) => [[validateRequired, levyExemption]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('levyExemption'),
    parse: (value) => {
      if (value == null) {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'nonTaxableBase',
    label: 'Non Taxable Base',
    validation: ({ nonTaxableBase }) => [[validateRequired, nonTaxableBase]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('nonTaxableBase'),
    parse: (value) => {
      if (value == null) {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'iban',
    label: 'IBAN',
    validation: ({ iban }) => [[validateWithoutSpaces, iban]],
    render: ({ iban }) => renderIBAN(iban),
    employmentDetail: true,
    showForAuthor: true,
    viewInTable: false,
  },
  {
    name: 'swift',
    label: 'SWIFT',
    validation: ({ swift }) => [
      [validateSWIFT, swift],
      [validateShorterThanMaxLength, swift],
    ],
    employmentDetail: true,
    showForAuthor: true,
    viewInTable: false,
  },
  {
    name: 'cateringForm',
    label: 'Catering Form',
    formControl: {
      componentClass: 'select',
    },
    isNullable: true,
    parse: parseNullableString,
    options: cateringForms,
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'entitlement',
    label: 'Entitlement',
    formControl: {
      componentClass: 'select',
    },
    isNullable: true,
    parse: parseNumberField,
    options: entitlementValues,
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'accountingNumber',
    label: 'Accounting Number',
    validation: ({ accountingNumber }) => [
      [validateShorterThanMaxLength, accountingNumber],
    ],
    required: nonRequiredField,
    employmentDetail: true,
    viewInTable: false,
    paymentSubcontractInfo: true,
  },
  {
    name: 'taxBonusOnChildren',
    label: 'Tax Bonus on Children',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('taxBonusOnChildren'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
  },
  {
    name: 'taxBonusNumberOfChildren',
    label: 'Tax Bonus Number of Children',
    isNullable: true,
    formControl: {
      type: 'number',
    },
    parse: (value) => Number(value),
    employmentDetail: true,
    viewInTable: false,
    paymentSubcontractInfo: true,
    reportingSubcontractInfo: false,
  },
  {
    name: 'pension',
    label: 'Pension',
    formControl: {
      componentClass: 'select',
    },
    isNullable: true,
    options: pensionOptions,
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'deductionsAndForeclosures',
    label: 'Deductions & Foreclosures',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('deductionsAndForeclosures'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    filter: multiFilters.simple,
  },
  {
    name: 'disabilityPension',
    label: 'Disability Pension',
    isNullable: true,
    validation: ({ disabilityPension }) => [
      [nullableValidation, disabilityPension, validatePercentage],
    ],
    formControl: {
      type: 'number',
    },
    parse: (value) => Number(value),
    units: () => '%',
    exportValue: ({ disabilityPension }) => `${Number(disabilityPension)}%`,
    rangeFilter: true,
    employmentDetail: true,
    viewInTable: false,
    required: () => false,
  },
  {
    name: 'citizenship',
    label: 'Citizenship',
    isNullable: true,
    formControl: {
      componentClass: 'select',
    },
    options: countries,
    employmentDetail: true,
    viewInTable: false,
    required: () => false,
  },
  {
    name: 'workPosition',
    label: 'Work Position',
    isNullable: true,
    employmentDetail: true,
    viewInTable: false,
  },
  {
    name: 'workDescription',
    label: 'Description of Work (Employment)',
    isNullable: true,
    employmentDetail: true,
    viewInTable: false,
    formControl: {
      componentClass: 'textarea',
    },
  },
  {
    name: 'healthInsuranceNumber',
    label: 'Health Insurance Number',
    isNullable: true,
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
  {
    name: 'motherName',
    label: "Mother's Name",
    isNullable: true,
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
  {
    name: 'taxId',
    label: 'Tax ID',
    isNullable: true,
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
  {
    name: 'illnessTaxExemption',
    label: 'Illness Tax Exemption',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('illnessTaxExemption'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
  {
    name: 'familyTaxExemption',
    label: 'Family Tax Exemption',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('familyTaxExemption'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
  {
    name: 'otherAllowances',
    label: 'Other Allowances',
    formControl: {
      componentClass: 'textarea',
    },
    isNullable: true,
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
  {
    name: 'paysChildCareFee',
    label: 'Pays Child Care Fee?',
    isNullable: true,
    options: booleanOptions,
    formControl: {
      componentClass: 'select',
    },
    resolve: getBooleanFieldValue('paysChildCareFee'),
    parse: (value) => {
      if (value == null || value === '') {
        return null
      }
      return typeof value === 'boolean' ? value : booleanOptions[value].value
    },
    employmentDetail: true,
    viewInTable: false,
    displayForReadOnly: false,
  },
]

export const legacyFields = [
  {
    name: 'selfBilling',
    label: 'Self Billing',
  },
]

export const employeeFieldNames = keyBy(employeeFields, (field) => field.name)

export const employeeFieldsViewableInTable = employeeFields.filter(
  ({ viewInTable, personalDetail }) =>
    viewInTable !== false && personalDetail !== true,
)

export const employeeFieldsPersonalDetails = employeeFields.filter(
  ({ viewInTable, contractField, contractInfo }) =>
    viewInTable !== false && contractField !== true && contractInfo !== true,
)

export const employeeFieldsExportable = employeeFields.filter(
  ({ exportable, monthlyExportable }) =>
    exportable !== false && monthlyExportable !== true,
)

export const employeeFieldsMonthlyExportable = employeeFields.filter(
  ({ exportable, monthlyExportable }) =>
    exportable !== false && monthlyExportable !== false,
)

export const personalDetailsFields = employeeFields.filter(
  ({ personalDetail }) => personalDetail === true,
)

export const employeeFieldsEditable = employeeFields.filter(
  ({ edit }) => edit !== false,
)

export const salaryInfoFields = employeeFields.filter(
  ({ salaryInfo }) => salaryInfo === true,
)

export const contractInfoFields = employeeFields.filter(
  ({ contractInfo, displayForReadOnly }) =>
    contractInfo === true && displayForReadOnly !== false,
)

export const contractFields = employeeFields.filter(
  ({ contractField }) => contractField === true,
)

export const paymentSubcontractFields = employeeFields.filter(
  ({ paymentSubcontractInfo }) => paymentSubcontractInfo === true,
)

export const reportingSubcontractFields = employeeFields.filter(
  ({ reportingSubcontractInfo }) => reportingSubcontractInfo === true,
)

export const companyDetailsFields = employeeFields.filter(
  ({ companyDetail }) => companyDetail === true,
)

export const companyOnlyDetailsFields = companyDetailsFields.filter(
  ({ employmentDetail }) => employmentDetail !== true,
)

export const employmentDetailsFields = employeeFields.filter(
  ({ employmentDetail }) => employmentDetail === true,
)

export const readOnlyEmploymentDetailsFields = employeeFields.filter(
  ({ employmentDetail, displayForReadOnly }) =>
    employmentDetail === true && displayForReadOnly !== false,
)

export const authorDetailsFields = employmentDetailsFields.filter(
  ({ showForAuthor }) => showForAuthor === true,
)

export const nonEmploymentFieldsEditable = employeeFieldsEditable.filter(
  ({ employmentDetail, companyDetail }) =>
    employmentDetail !== true || companyDetail === true,
)

export const employmentFieldsEditable = employeeFieldsEditable.filter(
  ({ employmentDetail, companyDetail }) =>
    employmentDetail === true || companyDetail !== true,
)

export const rowNumberAndName = employeeFields.filter(
  ({ name }) => name === 'rowNumber' || name === 'name',
)

export const getSubcontractFields = (subcontract, withType = false) => {
  const isEmployment = subcontract.legalForm
    ? legalForms[subcontract.legalForm].isEmployment
    : true
  const fields =
    subcontract.subcontractType === 'payment'
      ? isEmployment
        ? paymentSubcontractFields.filter((field) => field.name !== 'companyId')
        : paymentSubcontractFields.filter((field) => field.name !== 'employer')
      : reportingSubcontractFields.map((field) =>
          field.name !== 'fte' ? field : { ...field, label: 'Amount' },
        )

  return withType
    ? fields
    : fields.filter((field) => field.name !== 'subcontractType')
}

export const getExtendedSubcontractFields = (subcontract) =>
  subcontract.subcontractType === 'payment'
    ? paymentSubcontractFields
    : reportingSubcontractFields.map((field) =>
        field.name !== 'fte' ? field : { ...field, label: 'Amount' },
      )

export const filterVlCompanyOptions = (companies, currentValue, validFrom) => {
  const {
    capabilities: { writeAll, writeCompanies },
  } = getConfig()
  if (!writeAll && writeCompanies.length > 0) {
    companies = pick(companies, [currentValue, ...writeCompanies])
  }
  if (applyOrganizationStructureRules(validFrom)) {
    companies = pick(companies, [
      currentValue,
      'group',
      'theSpot',
      'vacuumlabs',
      'adalite',
    ])
  }
  return companies
}

export const filterDepartmentOptions = (
  departments,
  validFrom,
  currentCompanyKey,
  currentDepartmentKey,
) => {
  const currentCompany = vlCompanies[currentCompanyKey]
  if (
    applyOrganizationStructureRules(validFrom) &&
    currentCompany?.hasHierarchy &&
    departments
  ) {
    const departmentsList = Object.keys(departments).map(
      (departmentKey) => departments[departmentKey],
    )
    const filteredDepartmentsKeys = departmentsList
      .filter((department) =>
        department?.allowedParentVlCompanies?.includes(currentCompanyKey),
      )
      .map((filteredDepartment) => filteredDepartment.key)
    return pick(departments, [currentDepartmentKey, ...filteredDepartmentsKeys])
  } else {
    return departments
  }
}

export const filterSubdepartmentOptions = (
  subdepartments,
  validFrom,
  currentDepartmentKey,
  currentSubdepartmentKey,
) => {
  const currentDepartment = departments[currentDepartmentKey]
  if (
    applyOrganizationStructureRules(validFrom) &&
    currentDepartment?.hasHierarchy &&
    subdepartments
  ) {
    const subdepartmentsList = Object.keys(subdepartments).map(
      (departmentKey) => subdepartments[departmentKey],
    )
    const filteredSubdepartmentsKeys = subdepartmentsList
      .filter((subdepartment) =>
        subdepartment?.allowedParentDepartments?.includes(currentDepartmentKey),
      )
      .map((filteredSubdepartment) => filteredSubdepartment.key)
    return pick(subdepartments, [
      currentSubdepartmentKey,
      ...filteredSubdepartmentsKeys,
    ])
  } else {
    return subdepartments
  }
}
