import React, { Component } from 'react'
import { keyBy, mapValues, pick } from 'lodash'
import { Grid, Row, Col, Button, ButtonToolbar } from 'react-bootstrap'
import SimpleModal from './SimpleModal'
import NotificationContext from 'utils/context/NotificationContext'
import {
  apiRequest,
  dateFormat,
  getConfig,
  notifications,
  pushNotification,
} from '../utils'
import { parseDateField } from 'utils/fields'
import { prepareUpdate, formatValues } from 'EmployeeEditor'
import { ContractConflictEditor } from '../ContractConflictEditor'
import moment from 'moment'
import { parse } from 'csv-parse/lib/sync'
import TimelineEvent from './TimelineEvent'
import {
  employeeFieldsEditable,
  nonEmploymentFieldsEditable,
  personalDetailsFields,
} from '../employeeFields'

export const getFieldErrorMessages = async (values, field) => {
  const fieldErrorMessages = []
  const value = values[field.name]

  if (
    field.options &&
    ((field.name !== 'isActive' && !(value in field.options)) ||
      (field.name === 'isActive' && typeof value !== 'boolean'))
  ) {
    fieldErrorMessages.push(
      `Available values: {${Object.keys(field.options).join(', ')}}`,
    )
  }

  if (field.validation) {
    for (const fn of field.validation(values)) {
      const validationMessage = await fn[0](...fn.slice(1))
      if (validationMessage !== null) {
        fieldErrorMessages.push(validationMessage)
      }
    }
  }
  return fieldErrorMessages.length > 0
    ? `ERROR: ${fieldErrorMessages.join('. ')}`
    : null
}

const nonContractualModification = (employeeData) => {
  const personalFieldNames = personalDetailsFields.map(({ name }) => name)
  const extraFieldsName = ['comment']

  const nonContractualFieldsNames = new Set([
    ...personalFieldNames,
    ...extraFieldsName,
  ])

  return (
    employeeData !== undefined &&
    Object.keys(employeeData).every((field) =>
      nonContractualFieldsNames.has(field),
    )
  )
}

export default class BulkUpdateModal extends Component {
  static contextType = NotificationContext

  constructor() {
    super()
    this.state = {
      preparedUpdates: null,
      resolvingIdx: null,
    }
  }

  close() {
    this.props.onCancel()
    this.setState({ preparedUpdates: null })
  }

  async validateEmployeeFields(employeeData, fields) {
    const validationMessages = {}
    for (const field of fields) {
      validationMessages[field.name] = await getFieldErrorMessages(
        employeeData,
        field,
      )
    }
    return validationMessages
  }

  async loadBulkChanges(records) {
    const { userId } = getConfig()

    if (records.length < 1) {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ' (empty file)',
      )
      return
    }

    const { queryDate } = this.props
    const activeEmployees = await apiRequest(`employees`, {
      query: { date: queryDate, active: true },
    })
    const inactiveEmployees = await apiRequest(`employees`, {
      query: { date: queryDate, active: false },
    })

    const employeesMap = keyBy(
      [...activeEmployees, ...inactiveEmployees],
      'jiraId',
    )

    if (!this.validateRecords(records, employeesMap)) {
      return
    }

    const createdAt = moment.utc()
    const preparedUpdates = await Promise.all(
      records.map(async (desiredChanges) => {
        const validFrom = parseDateField(desiredChanges.validFrom)
        const options = { query: { date: validFrom } }
        const employee = employeesMap[desiredChanges.jiraId]
        const employeeAtTime = formatValues({
          ...employee,
          ...(await apiRequest(`employees/${employee.id}/contract`, options)),
        }).values

        const { data, changedData, conflictChanges, fieldsToChange } =
          await prepareUpdate(
            { ...employeeAtTime, ...desiredChanges },
            employeeAtTime,
          )

        // get only employee fields from parsed data
        const fields = [
          ...nonEmploymentFieldsEditable,
          ...employeeFieldsEditable,
        ].filter(({ name }) => data[name] !== undefined)

        // validate all fields
        const validationMessages = await this.validateEmployeeFields(
          { ...employeeAtTime, ...data },
          fields,
        )

        const changes = mapValues(
          pick(changedData, Object.keys(desiredChanges)),
          (to, field) => ({
            from: employeeAtTime[field],
            to,
            extra: validationMessages[field],
          }),
        )
        const event = {
          createdAt,
          createdBy: userId,
          type: nonContractualModification(desiredChanges)
            ? 'personalDetails'
            : 'contract',
          changes,
          validFrom: data.validFrom,
          firstName: employee.firstName,
          lastName: employee.lastName,
          comment: desiredChanges.comment,
        }

        return {
          jiraId: employee.jiraId,
          data,
          event,
          conflictChanges,
          fieldsToChange,
          deleteContracts: {},
          validFrom: moment.utc(data.validFrom),
          resolved: !conflictChanges.needManualResolution,
          validFields: !Object.values(validationMessages).filter(
            (err) => err !== null,
          ).length,
        }
      }),
    )
    this.setState({ preparedUpdates })
  }

  validateRecords(records, employeesMap) {
    let isValid = true

    // check again if file is empty - no records
    if (records.length < 1) {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ' (empty file)',
      )
      return false
    }

    // extract records with duplicated jira ids
    const duplicateJiraIds = []
    records
      .map((_) => _.jiraId)
      .reduce((arr, cur) => {
        if (arr.includes(cur) && !duplicateJiraIds.includes(cur))
          duplicateJiraIds.push(cur)
        return [...arr, cur]
      }, [])
    if (duplicateJiraIds.length > 0) {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ` (duplicate jira ids: ${duplicateJiraIds})`,
      )
      return false
    }

    // get invalid row's number
    const missingFieldRecords = []
    records.forEach((record, i) => {
      if (
        !nonContractualModification(record) &&
        (!record.jiraId || !record.validFrom || !record.comment)
      ) {
        missingFieldRecords.push(i + 2)
      }
    })

    if (missingFieldRecords.length > 0) {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ` ("jiraId", "validFrom", "comment" cannot be empty; invalid rows: ${missingFieldRecords.join(
          ', ',
        )})`,
      )
      isValid = false
    }

    // get all jiraIDs of non-existing employees
    const invalidEmployeeRecords = []
    records.forEach((record) => {
      if (!employeesMap[record.jiraId]) {
        invalidEmployeeRecords.push(record.jiraId ? record.jiraId : 'empty')
      }
    })

    if (invalidEmployeeRecords.length > 0) {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ` (cannot find employees with jiraId: ${invalidEmployeeRecords.join(
          ', ',
        )})`,
      )
      isValid = false
    }

    // "starting date" should never be larger than the contract's "valid from" date
    const invalidStartingDateRecords = []
    records.forEach((record) => {
      if (record.validFrom) {
        const validFrom = moment.utc(record.validFrom).format(dateFormat)
        const startingDate = moment
          .utc(record.startingDate || employeesMap[record.jiraId]?.startingDate)
          .format(dateFormat)

        if (validFrom < startingDate) {
          invalidStartingDateRecords.push(
            record.jiraId ? record.jiraId : 'empty',
          )
        }
      }
    })

    if (invalidStartingDateRecords.length > 0) {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ` ("validFrom" can't be smaller than "startingDate. Check the values with the following jiraId(s)": ${invalidStartingDateRecords.join(
          ', ',
        )})`,
      )
      isValid = false
    }

    return isValid
  }

  async onFileChange(event) {
    const uploadedFile = event.target.files[0]

    const text = await uploadedFile.text()

    if (uploadedFile.name.split('.').pop() === 'csv') {
      const records = parse(text, {
        bom: true,
        columns: true,
        trim: true,
      })
      await this.loadBulkChanges(records)
    } else {
      pushNotification(
        notifications.invalidFile,
        this.context.addNotification,
        ' (only CSV files are supported)',
      )
    }
  }

  async doUpdate() {
    const { preparedUpdates } = this.state
    await apiRequest('employees', {
      method: 'POST',
      body: {
        updates: preparedUpdates.map(
          ({ data, fieldsToChange, deleteContracts }) => ({
            data,
            fieldsToChange,
            contractsToDelete: Object.keys(deleteContracts),
          }),
        ),
      },
      ignoreAuthError: true,
    })
    pushNotification(notifications.bulkUpdateDone, this.context.addNotification)
    this.close()
  }

  render() {
    const { show } = this.props
    const { preparedUpdates, resolvingIdx } = this.state
    const resolvingUpdate =
      resolvingIdx !== null && preparedUpdates[resolvingIdx]
    const allResolved =
      preparedUpdates !== null &&
      preparedUpdates.every(({ resolved }) => resolved)
    const allFieldsValid =
      preparedUpdates !== null &&
      preparedUpdates.every(({ validFields }) => validFields)
    return (
      <SimpleModal
        show={show}
        onCancel={() => this.close()}
        title={'Bulk update'}
        footer={
          <ButtonToolbar className="pull-right">
            <Button
              disabled={!allResolved || !allFieldsValid}
              onClick={() => this.doUpdate()}
            >
              Submit
            </Button>
            <Button onClick={() => this.close()}>Cancel</Button>
          </ButtonToolbar>
        }
        large
      >
        <h3>Step 1: upload your CSV file</h3>
        <input
          type="file"
          accept=".csv"
          onChange={(e) => this.onFileChange(e)}
        />
        {preparedUpdates !== null && <h3>Step 2: check your changes</h3>}
        {preparedUpdates !== null &&
          preparedUpdates.map(({ event }, i) => (
            <TimelineEvent
              key={i}
              event={event}
              initialEvent={false}
              date={null}
              companies={{}}
            />
          ))}
        {preparedUpdates && !allFieldsValid && (
          <p>Invalid fields detected. Please resolve them.</p>
        )}
        {preparedUpdates && <h3>Step 3: resolve any conflicts</h3>}
        {preparedUpdates && (
          <Grid>
            {preparedUpdates.some(
              (u) => u.conflictChanges.needManualResolution,
            ) && (
              <p>
                The following employees have conflicts, you need to resolve
                them.
              </p>
            )}
            {preparedUpdates.map((preparedUpdate, i) => {
              const {
                jiraId,
                conflictChanges: { needManualResolution },
                resolved,
              } = preparedUpdate
              return (
                needManualResolution && (
                  <Row key={i} style={resolved ? {} : { fontWeight: 'bold' }}>
                    <Col xs={3}>{jiraId}</Col>
                    <Col xs={3}>
                      <Button
                        onClick={() => this.setState({ resolvingIdx: i })}
                      >
                        Resolve
                      </Button>
                    </Col>
                  </Row>
                )
              )
            })}
          </Grid>
        )}
        {allResolved && allFieldsValid && (
          <p>
            All conflicts resolved successfully. You can now submit your
            changes.
          </p>
        )}
        {resolvingUpdate && (
          <ContractConflictEditor
            show
            validFrom={resolvingUpdate.validFrom}
            closeConfirmDialog={() => this.setState({ resolvingIdx: null })}
            handleUpdateConfirm={(fieldsToChange, deleteContracts) => {
              const newUpdates = [...preparedUpdates]
              newUpdates[resolvingIdx] = {
                ...newUpdates[resolvingIdx],
                fieldsToChange,
                deleteContracts,
                resolved: true,
              }
              this.setState({ preparedUpdates: newUpdates, resolvingIdx: null })
            }}
            conflictChanges={resolvingUpdate.conflictChanges}
            fieldsToChange={resolvingUpdate.fieldsToChange}
            deleteContracts={resolvingUpdate.deleteContracts}
          />
        )}
      </SimpleModal>
    )
  }
}
