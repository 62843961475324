import React from 'react'
import moment from 'moment'
import { get } from 'lodash'
import styles from './RecordList.module.scss'

export default ({ record, recordsFields }) => (
  <dl className="dl-horizontal" key={record.validFrom}>
    {recordsFields.map((item) => {
      let value
      if (item.render) {
        value = item.render(record)
      } else if (item.resolve) {
        value = item.resolve(record, { date: moment(record.validFrom) })
      } else {
        value = record[item.name]
        if (item.options) {
          value = get(item.options, [value, 'label'], value)
        }
      }
      return [
        <dt className={styles.record} key={`term-${item.name}`}>
          {item.label}
        </dt>,
        <dd key={`def-${item.name}`}>
          {value}
          {item.units && !item.render && (
            <span>&nbsp;{item.units(record)}</span>
          )}
        </dd>,
      ]
    })}
  </dl>
)
