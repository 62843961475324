import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Badge, Button, Glyphicon } from 'react-bootstrap'
import HorizontalTimeline from 'react-horizontal-timeline'
import moment from 'moment'
import { isEqual } from 'lodash'
import { legalForms } from '../common/enums'
import { dateFormatHuman } from '../utils'
import RecordList from './RecordList'
import styles from './HorizontalTimelineContracts.module.scss'
import {
  contractInfoFields,
  employeeFieldsPersonalDetails,
  readOnlyEmploymentDetailsFields,
  getSubcontractFields,
} from '../employeeFields'
import { companyFieldsForTable } from '../companyFields'

export default class HorizontalTimelineContracts extends Component {
  static propTypes = {
    employee: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    showDelete: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedContractDate: PropTypes.object,
    date: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = this.createContent()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.records, this.props.records)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(this.createContent())
    } else {
      const index = this.findIndexOfCurrentContract(this.state.dates)
      if (this.state.index !== index) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ index })
      }
    }
  }

  findIndexOfCurrentContract(dates) {
    const selectedDate = this.props.selectedContractDate || this.props.date
    const index = dates.findIndex((date) => selectedDate.isBefore(date, 'day'))

    if (index < 0) {
      return dates.length - 1
    } else {
      return Math.max(index - 1, 0)
    }
  }

  createContent = () => {
    const { records, showDelete } = this.props
    const content = records.map((record) => {
      const date = moment.utc(record.validFrom)
      return {
        date,
        component: (
          <>
            <Row>
              <Col md={6}>
                <h4 className={styles.heading}>
                  Contract Details
                  {showDelete && (
                    <span>
                      &nbsp;
                      <Button
                        bsSize="small"
                        bsStyle="danger"
                        onClick={this.handleDeleteClick}
                      >
                        <Glyphicon glyph="trash" />
                      </Button>
                    </span>
                  )}
                </h4>
                <RecordList
                  record={record}
                  recordsFields={contractInfoFields}
                />
              </Col>
              {legalForms[record.legalForm].isEmployment && (
                <Col md={6}>
                  <h4 className={styles.heading}>Employment Details</h4>
                  <RecordList
                    record={record}
                    recordsFields={readOnlyEmploymentDetailsFields}
                  />
                </Col>
              )}
              {record.companyId && (
                <Col md={6}>
                  <h4 className={styles.heading}>Company Details</h4>
                  <RecordList
                    record={record.company}
                    recordsFields={companyFieldsForTable.filter(
                      ({ name }) =>
                        name !== 'rowNumber' && name !== 'employeeIds',
                    )}
                  />
                </Col>
              )}
            </Row>
            {!!record.subcontracts?.length && (
              <Row>
                {record.subcontracts.map((subcontract, index) => (
                  <Col md={6} key={subcontract.contractId}>
                    <h4 className={styles.heading}>
                      Subcontract {index + 1} Details
                    </h4>
                    <RecordList
                      record={subcontract}
                      recordsFields={getSubcontractFields(subcontract, true)}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </>
        ),
        isActive: record.isActive,
      }
    })
    const dates = content.map((entry) => entry.date)
    const index = this.findIndexOfCurrentContract(dates)

    return { content, dates, index }
  }

  handleIndexClick = (index) => {
    this.props.onSelect(this.state.dates[index])
    this.setState({ index })
  }

  handleDeleteClick = () => {
    const { records, onDelete } = this.props
    const { index } = this.state
    onDelete(records[index])
  }

  getLabel = (date, index) => (
    <span
      className={!this.state.content[index].isActive ? styles.inactive : ''}
    >
      {!this.state.content[index].isActive && (
        <Badge className={styles.badge}>Inactive</Badge>
      )}
      {date.format(dateFormatHuman)}
    </span>
  )

  render() {
    const { index } = this.state

    return (
      <Row>
        <Col xs={12}>
          <div className={styles.timeline}>
            <HorizontalTimeline
              index={index}
              indexClick={this.handleIndexClick}
              getLabel={this.getLabel}
              labelWidth={100}
              styles={{
                background: 'white',
                foreground: '#d90065',
                outline: '#dfdfdf',
              }}
              values={this.state.dates}
              isOpenBeginning={false}
            />
          </div>
        </Col>
        <Col xs={12}>
          <Row>
            <Col md={4} className={styles.personalDetails}>
              <h4 className={styles.heading}>Personal Details</h4>
              <RecordList
                record={this.props.employee}
                recordsFields={employeeFieldsPersonalDetails.filter(
                  ({ name }) => name !== 'rowNumber',
                )}
              />
            </Col>
            <Col
              md={8}
              className={
                !this.state.content[index].isActive ? styles.inactive : ''
              }
            >
              {this.state.content[index].component}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
