import React, { Component, ReactNode, SyntheticEvent } from 'react'
import DateTime from 'react-datetime'
import {
  Form,
  FormGroup,
  FormControl,
  Row,
  Col,
  Button,
  Glyphicon,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import moment, { Moment } from 'moment'
import _, { keyBy, uniq } from 'lodash'
import classnames from 'classnames'
import Loading from './components/Loading'
import TimelineEvent from './components/TimelineEvent'
import SimpleModal from './components/SimpleModal'
import OfficeData from './components/OfficeData'
import { employeeFields } from './employeeFields'
import { eventFields } from './eventFields'
import {
  getConfig,
  monthFormatHuman,
  dateFormat,
  getFullName,
  getFullNameReversed,
  apiRequest,
  handleUncaughtError,
  compareOptionsLexicographicallyByLabel,
  getTimelineAsXLSX,
  filterTimelineEvents,
} from './utils'
import styles from './Timeline.module.scss'
import NotificationContext from 'utils/context/NotificationContext'
import {
  EmployeeType,
  LegalNameType,
  OfficeResponseType,
  TimelineEventType,
} from 'components/types.common'
import { vlCompanies } from 'common/enums'

const renderOption = ({
  key,
  label,
}: {
  key: string | number | null
  label: ReactNode
}): ReactNode =>
  key == null ? null : (
    <option key={key} value={key}>
      {label}
    </option>
  )

interface ITimelineProps {
  show: boolean
  employeeId: number
  date: Moment
  month: Moment
  editedBy: number
  forEmployee: number
  selectedCompany: string
  getEmployeeById: (id: number) => Promise<EmployeeType>
  onCancel: () => void
  handleTimelineFilter: (
    month: Moment,
    editedBy: number,
    employeeId: number,
    selectedCompany: string,
  ) => void
}

interface ITimelineState {
  companies: Record<number, LegalNameType>
  employees: Record<number, EmployeeType>
  events: TimelineEventType[]
  employee: EmployeeType | null
  offices: OfficeResponseType | null
}

export default class Timeline extends Component<
  ITimelineProps,
  ITimelineState
> {
  private _pollingId: number | null = null

  constructor(props: ITimelineProps) {
    super(props)
    this.state = {
      companies: {},
      employees: {},
      events: [],
      employee: null,
      offices: null,
    }
  }

  static contextType = NotificationContext

  componentDidMount() {
    this.update({} as ITimelineProps, this.props)
  }

  componentDidUpdate(prevProps: ITimelineProps) {
    this.update(prevProps, this.props)
  }

  componentWillUnmount() {
    this.clearPolling()
  }

  private getAllCompanyNames() {
    apiRequest('companies/legalNames')
      .then((response: LegalNameType[]) => {
        this.setState({ companies: keyBy(response, 'id') })
      })
      .catch((err: string) =>
        handleUncaughtError(err, this.context.addNotification),
      )
  }

  private getAllEmployees() {
    apiRequest('employees/identifiers')
      .then((response: EmployeeType[]) => {
        this.setState({ employees: keyBy(response, 'id') })
      })
      .catch((err: string) =>
        handleUncaughtError(err, this.context.addNotification),
      )
  }

  private getFilterDate(props = this.props) {
    const { date, month } = props
    return month || date
  }

  private update(props: ITimelineProps, nextProps: ITimelineProps) {
    const { show: oldShow, employeeId: oldEmployeeId } = props
    const { show, employeeId, getEmployeeById } = nextProps
    const {
      capabilities: { readAll },
    } = getConfig()

    if (show) {
      const oldFilterDate = this.getFilterDate(props)
      const filterDate = this.getFilterDate(nextProps)
      if (
        !oldShow ||
        employeeId !== oldEmployeeId ||
        !oldFilterDate.isSame(filterDate)
      ) {
        this.setState({ events: [] })
        this.getTimelineEvents(employeeId, filterDate)
        getEmployeeById(employeeId).then((employee: EmployeeType) =>
          this.setState({ employee }),
        )
        if (readAll) {
          this.getOfficeData(filterDate)
        }
      }
      if (!oldShow && employeeId == null) {
        this.getAllEmployees()
      }
      if (!oldShow) {
        this.getAllCompanyNames()
      }
      this.setPolling()
    } else {
      this.clearPolling()
    }
  }

  private setPolling = () => {
    if (!this._pollingId) {
      this._pollingId = window.setInterval(
        this.getTimelineEvents,
        getConfig().polling,
      )
    }
  }

  private clearPolling = () => {
    if (this._pollingId) {
      window.clearInterval(this._pollingId)
      this._pollingId = null
    }
  }

  private getTimelineEvents = (
    id = this.props.employeeId,
    date = this.getFilterDate(),
  ) => {
    const options = {
      query: { date: date.format(dateFormat) },
    }
    const idParam = id ? `${id}/` : ''
    apiRequest(`employees/${idParam}timeline`, options)
      .then((response: TimelineEventType[]) => {
        this.setState({ events: response })
      })
      .catch((err: string) =>
        handleUncaughtError(err, this.context.addNotification),
      )
  }

  private getOfficeData(date = this.getFilterDate()) {
    const options = {
      query: { date: date.format(dateFormat) },
    }
    apiRequest('offices', options)
      .then((response: OfficeResponseType) => {
        this.setState({ offices: response })
      })
      .catch((err: string) =>
        handleUncaughtError(err, this.context.addNotification),
      )
  }

  private generateExport = () => {
    const { editedBy, forEmployee, selectedCompany } = this.props
    const { employees, companies } = this.state
    const date = this.getFilterDate()

    getTimelineAsXLSX(
      employees,
      companies,
      date.format(dateFormat),
      editedBy,
      forEmployee,
      selectedCompany,
      { eventFields, employeeFields },
      this.context.addNotification,
    )
  }

  private changeTimelineMonth = (month: string | Moment) => {
    if (moment.isMoment(month)) {
      const { handleTimelineFilter, editedBy, forEmployee, selectedCompany } =
        this.props
      handleTimelineFilter(month, editedBy, forEmployee, selectedCompany)
    }
  }

  private changeTimelineEditor = (e: SyntheticEvent<EventTarget>) => {
    const { value } = e.target as HTMLInputElement
    const { handleTimelineFilter, month, forEmployee, selectedCompany } =
      this.props
    handleTimelineFilter(month, Number(value), forEmployee, selectedCompany)
  }

  private changeTimelineEmployee = (e: SyntheticEvent<EventTarget>) => {
    const { value } = e.target as HTMLInputElement
    const { handleTimelineFilter, month, editedBy, selectedCompany } =
      this.props
    handleTimelineFilter(month, editedBy, Number(value), selectedCompany)
  }

  private changeTimelineCompany = (e: SyntheticEvent<EventTarget>) => {
    const { value } = e.target as HTMLInputElement
    const { handleTimelineFilter, month, editedBy, forEmployee } = this.props
    handleTimelineFilter(month, editedBy, forEmployee, value)
  }

  private renderEvents() {
    const { events, companies } = this.state
    const { date, employeeId, editedBy, forEmployee, selectedCompany } =
      this.props
    if (!events) {
      return <Loading />
    }

    const filteredEvents = filterTimelineEvents(events, {
      editedBy,
      forEmployee,
      selectedCompany,
      type: null,
    })

    if (filteredEvents.length > 0) {
      return filteredEvents.map((e: TimelineEventType, i: number) => (
        <TimelineEvent
          key={i}
          event={e}
          date={employeeId != null ? date : null}
          initialEvent={e.comment === null}
          companies={companies}
        />
      ))
    } else {
      return (
        <Row>
          <Col xs={12} className="text-center">
            <h4 className={styles.noEntry}>There are no changes.</h4>
          </Col>
        </Row>
      )
    }
  }

  private renderFilters() {
    const { employeeId, editedBy, forEmployee, selectedCompany } = this.props
    const { events = [], employees } = this.state
    const date = this.getFilterDate()

    if (!date || employeeId != null || employees == null) {
      return null
    }

    const editedByList: (number | null)[] = uniq(
      events.map((event: TimelineEventType) => event.createdBy),
    ) as (number | null)[]
    const employeeIdList = uniq(
      events.map(({ employeeId }: TimelineEventType) => employeeId),
    ) as number[]
    if (editedBy && !editedByList.includes(editedBy)) {
      if (editedBy === -1) {
        const vacuumId = null
        if (!editedByList.includes(vacuumId)) {
          editedByList.push(vacuumId)
        }
      } else {
        editedByList.push(editedBy)
      }
    }
    if (forEmployee && !employeeIdList.includes(forEmployee)) {
      employeeIdList.push(forEmployee)
    }

    const editedByOptions = editedByList
      .map((employeeId) => {
        const employee = employees[employeeId as number]
        if (employee) {
          return {
            key: employeeId,
            label: getFullNameReversed(employee),
          }
        } else {
          return {
            key: -1,
            label: 'vacuum',
          }
        }
      })
      .sort(compareOptionsLexicographicallyByLabel)

    const employeeIdOptions = employeeIdList
      .map((employeeId) => {
        const employee = employees[employeeId]
        if (employee) {
          return {
            key: employeeId,
            label: getFullNameReversed(employee),
          }
        } else {
          return {
            key: null,
            label: '',
          }
        }
      })
      .sort(compareOptionsLexicographicallyByLabel)

    return (
      <Form inline className={styles.filterForm}>
        <FormGroup controlId="formMonth">
          <DateTime
            value={date}
            onChange={this.changeTimelineMonth}
            dateFormat={monthFormatHuman}
            viewMode="months"
            closeOnSelect
            inputProps={{
              className: classnames(['form-control', styles.dateInput]),
            }}
          />
        </FormGroup>
        &nbsp;
        <FormControl
          componentClass="select"
          onChange={this.changeTimelineEditor}
          value={editedBy || ''}
        >
          <option value="0">All Editors</option>
          {editedByOptions.map(renderOption)}
        </FormControl>
        &nbsp;
        <FormControl
          componentClass="select"
          onChange={this.changeTimelineEmployee}
          value={forEmployee || ''}
        >
          <option value="0">All Employees</option>
          {employeeIdOptions.map(renderOption)}
        </FormControl>
        <FormControl
          componentClass="select"
          onChange={this.changeTimelineCompany}
          value={selectedCompany || ''}
        >
          <option value="">All Companies</option>
          {_.values(vlCompanies).map(renderOption)}
        </FormControl>
        &nbsp;
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip id="download">Download as XLSX</Tooltip>}
        >
          <Button onClick={this.generateExport}>
            <Glyphicon glyph="download-alt" />
            &zwnj;
          </Button>
        </OverlayTrigger>
      </Form>
    )
  }

  render() {
    const { show, onCancel, employeeId } = this.props
    const { employee, offices } = this.state
    const {
      capabilities: { readAll },
    } = getConfig()

    let title = ''

    if (employeeId == null) {
      title = 'Monthly Timeline'
    } else {
      const employeeName = employee ? getFullName(employee) : ''
      title = `${employeeName}'s Timeline`
    }

    return (
      <SimpleModal
        show={show}
        onCancel={onCancel}
        title={title}
        titleAddon={this.renderFilters()}
        className={styles.modal}
        classNameBody={styles.modalBody}
      >
        {readAll && <OfficeData offices={offices} />}
        {this.renderEvents()}
      </SimpleModal>
    )
  }
}
