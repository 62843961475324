import React from 'react'
import {
  countries,
  statuses,
  booleanOptions,
  branches,
  branches2,
  multiFilters,
  currencyValues,
  invoicedServiceOptions,
} from './common/enums'
import {
  validateRequired,
  validateEmptyOrWithoutSpaces,
  validateEmptyOrSWIFT,
  validateShorterThanMaxLength,
  parseMultiIdSelect,
  getBooleanFieldValue,
  nonRequiredField,
  renderIBAN,
  resolveDateField,
  validateEmptyOrDate,
  validateCompanyIdUnique,
  validateNullableEmail,
  parseNullableString,
  stopPropagation,
  requiredIfOtherFieldPresent,
  validateRequiredOnlyIfOtherFieldPresent,
} from './utils'
import { keyBy } from 'lodash'

export const companyFields = [
  {
    name: 'rowNumber',
    label: '#',
    displayField: true,
    editField: false,
    exportable: false,
    companyInfo: true,
    companyDetail: true,
  },
  {
    name: 'employeeId',
    label: 'Beneficiary',
    displayField: false,
    editField: false,
    exportable: false,
  },
  {
    name: 'employeeIds',
    label: 'Beneficiaries',
    formControl: {
      componentClass: 'select',
      multiple: true,
      size: 5,
    },
    parse: parseMultiIdSelect,
    displayField: true,
    editField: true,
    exportable: false,
    companyInfo: true,
    companyDetail: true,
  },
  {
    name: 'legalName',
    label: 'Legal Name',
    validation: ({ legalName }) => [
      [validateRequired, legalName],
      [validateShorterThanMaxLength, legalName],
    ],
    displayField: true,
    editField: true,
    companyInfo: true,
    companyDetail: true,
  },
  {
    name: 'isActive',
    label: 'Status',
    validation: ({ isActive }) => [[validateRequired, isActive]],
    formControl: {
      componentClass: 'select',
    },
    options: statuses,
    resolve: ({ isActive }) =>
      isActive ? statuses.active.label : statuses.inactive.label,
    parse: (value) =>
      typeof value === 'boolean' ? value : statuses[value].value,
    displayField: false,
    editField: true,
  },
  {
    name: 'owner',
    label: 'Owner',
    validation: ({ owner }) => [[validateShorterThanMaxLength, owner]],
    required: nonRequiredField,
    parse: parseNullableString,
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'ownerEmail',
    label: "Owner's Email",
    validation: ({ ownerEmail }) => [
      [validateNullableEmail, ownerEmail],
      [validateShorterThanMaxLength, ownerEmail],
    ],
    required: nonRequiredField,
    parse: parseNullableString,
    render: ({ ownerEmail }) => (
      <a href={`mailto:${ownerEmail}`} onClick={stopPropagation}>
        {ownerEmail}
      </a>
    ),
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'street',
    label: 'Street',
    validation: ({ street }) => [
      [validateRequired, street],
      [validateShorterThanMaxLength, street],
    ],
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'city',
    label: 'City',
    validation: ({ city }) => [
      [validateRequired, city],
      [validateShorterThanMaxLength, city],
    ],
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'zipCode',
    label: 'ZIP Code',
    validation: ({ zipCode }) => [
      [validateRequired, zipCode],
      [validateShorterThanMaxLength, zipCode],
    ],
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'country',
    label: 'Country',
    validation: ({ country }) => [[validateRequired, country]],
    formControl: {
      componentClass: 'select',
    },
    options: countries,
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'companyId',
    label: 'Company ID',
    validation: ({ companyId }, originalValue) => [
      [validateRequired, companyId],
      [validateCompanyIdUnique, companyId, originalValue],
      [validateShorterThanMaxLength, companyId],
      [validateEmptyOrWithoutSpaces, companyId],
    ],
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'taxId',
    label: 'Tax ID',
    validation: ({ taxId }) => [
      [validateShorterThanMaxLength, taxId],
      [validateEmptyOrWithoutSpaces, taxId],
    ],
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'vatId',
    label: 'VAT ID',
    validation: ({ vatId }) => [
      [validateShorterThanMaxLength, vatId],
      [validateEmptyOrWithoutSpaces, vatId],
    ],
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'vatPayer',
    label: 'VAT Payer',
    validation: ({ vatPayer }) => [[validateRequired, vatPayer]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('vatPayer'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'randomSalary',
    label: 'Random Salary',
    validation: ({ randomSalary }) => [[validateRequired, randomSalary]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('randomSalary'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'registerCity',
    label: 'Register City',
    validation: ({ registerCity }) => [
      [validateShorterThanMaxLength, registerCity],
    ],
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'section',
    label: 'Section',
    validation: ({ section }) => [[validateShorterThanMaxLength, section]],
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'insertNumber',
    label: 'Insert/No.',
    validation: ({ insertNumber }) => [
      [validateShorterThanMaxLength, insertNumber],
    ],
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'taxAgreement',
    label: 'Tax Agreement',
    validation: ({ taxAgreement }) => [[validateEmptyOrDate, taxAgreement]],
    formControl: {
      type: 'date',
    },
    required: nonRequiredField,
    resolve: resolveDateField('taxAgreement'),
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'deskRental',
    label: 'Desk Rental',
    validation: ({ deskRental }) => [[validateRequired, deskRental]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('deskRental'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'spotMembership',
    label: 'Spot Membership',
    validation: ({ spotMembership }) => [[validateRequired, spotMembership]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('spotMembership'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'spotCatering',
    label: 'Spot Catering',
    validation: ({ spotCatering }) => [[validateRequired, spotCatering]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('spotCatering'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'selfBilling',
    label: 'Self Billing',
    validation: ({ selfBilling }) => [[validateRequired, selfBilling]],
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('selfBilling'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'isPlayrollFreelancer',
    label: 'Is Playroll Freelancer',
    formControl: {
      componentClass: 'select',
    },
    options: booleanOptions,
    resolve: getBooleanFieldValue('isPlayrollFreelancer'),
    parse: (value) =>
      typeof value === 'boolean' ? value : booleanOptions[value].value,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'billingTo',
    label: 'Billing To',
    validation: ({ billingTo }) => [[validateRequired, billingTo]],
    formControl: {
      componentClass: 'select',
    },
    options: branches,
    resolve: ({ billingTo }) => branches[billingTo].label,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'paymentCurrency',
    label: 'Payment currency',
    validation: ({ paymentCurrency }) => [[validateRequired, paymentCurrency]],
    formControl: {
      componentClass: 'select',
    },
    options: currencyValues,
    displayField: true,
    editField: true,
    companyInfo: true,
  },
  {
    name: 'iban',
    label: 'IBAN',
    validation: ({ iban }) => [[validateEmptyOrWithoutSpaces, iban]],
    render: ({ iban }) => renderIBAN(iban),
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'swift',
    label: 'SWIFT',
    validation: ({ swift, iban }) => [
      [validateEmptyOrSWIFT, swift],
      [validateShorterThanMaxLength, swift],
      [validateRequiredOnlyIfOtherFieldPresent, swift, iban],
    ],
    displayField: true,
    editField: true,
    companyDetail: true,
    required: ({ iban }) => requiredIfOtherFieldPresent(iban),
  },
  {
    name: 'billingTo2',
    label: 'Billing To 2',
    formControl: {
      componentClass: 'select',
    },
    options: branches2,
    resolve: ({ billingTo2 }) =>
      billingTo2 ? branches2[billingTo2].label : '',
    isNullable: true,
    parse: parseNullableString,
    displayField: true,
    editField: true,
    companyInfo: true,
    filter: multiFilters.simple,
  },
  {
    name: 'paymentCurrency2',
    label: 'Payment currency2',
    formControl: {
      label: 'Payment currency 2',
      componentClass: 'select',
    },
    validation: ({ paymentCurrency2, billingTo2 }) => [
      [validateRequiredOnlyIfOtherFieldPresent, paymentCurrency2, billingTo2],
    ],
    options: currencyValues,
    isNullable: true,
    parse: parseNullableString,
    displayField: true,
    editField: true,
    companyInfo: true,
    required: ({ billingTo2 }) => requiredIfOtherFieldPresent(billingTo2),
  },
  {
    name: 'iban2',
    label: 'IBAN 2',
    validation: ({ iban2 }) => [[validateEmptyOrWithoutSpaces, iban2]],
    render: ({ iban2 }) => renderIBAN(iban2),
    required: nonRequiredField,
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'swift2',
    label: 'SWIFT 2',
    validation: ({ swift2, iban2 }) => [
      [validateEmptyOrSWIFT, swift2],
      [validateShorterThanMaxLength, swift2],
      [validateRequiredOnlyIfOtherFieldPresent, swift2, iban2],
    ],
    required: ({ iban2 }) => requiredIfOtherFieldPresent(iban2),
    displayField: true,
    editField: true,
    companyDetail: true,
  },
  {
    name: 'invoicedService',
    label: 'Invoiced Service',
    viewInTable: false,
    formControl: {
      componentClass: 'select',
    },
    options: invoicedServiceOptions,
    isNullable: true,
    displayField: false,
    editField: true,
    exportable: false,
  },
  {
    name: 'workDescription',
    label: 'Description of Work (Freelancing)',
    viewInTable: false,
    formControl: {
      componentClass: 'textarea',
    },
    isNullable: true,
    displayField: false,
    editField: true,
    exportable: false,
  },
  {
    name: 'comment',
    label: 'Change Reason',
    formControl: {
      componentClass: 'textarea',
    },
    validation: ({ comment }) => [[validateRequired, comment]],
    hideOnCreate: true,
    displayField: false,
    editField: true,
    exportable: false,
  },
]

export const companyFieldNames = keyBy(companyFields, (field) => field.name)

export const companyFieldsForTable = companyFields.filter(
  ({ displayField }) => displayField === true,
)

export const companyFieldsForEditor = companyFields.filter(
  ({ editField }) => editField === true,
)

export const companyFieldsExportable = companyFields.filter(
  ({ exportable }) => exportable !== false,
)

export const companyFieldsInfo = companyFields.filter(
  ({ companyInfo }) => companyInfo === true,
)

export const companyFieldsDetails = companyFields.filter(
  ({ companyDetail }) => companyDetail === true,
)

// fields in employees' CSV and XLSX export; refactor to employeeExport: true when adding a new field
export const companyFieldsForEmployeeExport = companyFields.filter(
  ({ name }) => name === 'legalName',
)
