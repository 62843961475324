import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, Button } from 'react-bootstrap'
import classnames from 'classnames'
import { shareFieldsForTable as shareFields } from './shareFields'
import { getConfig } from './utils'
import styles from './Shares.module.scss'

export default class Share extends Component {
  static propTypes = {
    rowNumber: PropTypes.number.isRequired,
    share: PropTypes.object.isRequired,
    deleteShareValue: PropTypes.func.isRequired,
  }

  handleDeleteClick = (event) => {
    event.stopPropagation()
    this.props.deleteShareValue(this.props.share.id)
  }

  render() {
    const { share, rowNumber } = this.props

    return (
      <div className={styles.sharesBodyRow}>
        {shareFields.map(({ name, units, resolve }) => {
          let value = share[name]

          if (resolve) {
            value = resolve(share)
          }

          if (name === 'rowNumber') {
            value = rowNumber
          }

          return (
            <div
              key={name}
              className={classnames([styles.sharesCell, styles[`box-${name}`]])}
            >
              {value}
              {value != null && units && <span>&nbsp;{units(share)}</span>}
            </div>
          )
        })}
        <div className={classnames([styles.sharesCell, styles.sharesButtons])}>
          {getConfig().writeAccess && (
            <Button
              bsStyle="danger"
              title="Delete"
              onClick={this.handleDeleteClick}
              data-test="deleteShareValue"
            >
              <Glyphicon glyph="trash" />
            </Button>
          )}
        </div>
      </div>
    )
  }
}
