import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { get } from 'lodash'
import * as Diff from 'diff'
import { statuses, booleanOptions } from '../common/enums'
import { dateFormatHuman, emptyValue } from '../utils'
import { companyFields, companyFieldNames } from '../companyFields'
import Avatar from './Avatar'
import styles from './TimelineEvent.module.scss'

const FormatValue = ({ attribute, value, employees }) => {
  const labelForEmployee = (value) =>
    employees.find((e) => e.value === value)?.label ?? emptyValue
  if (attribute === 'employeeId') {
    return <span>{value != null ? labelForEmployee(value) : emptyValue}</span>
  } else if (attribute === 'employeeIds') {
    return (
      <span>
        {value && Array.isArray(value) && value.length > 0
          ? value.map(labelForEmployee).join(', ')
          : emptyValue}
      </span>
    )
  } else if (attribute === 'taxAgreement') {
    return (
      <span>
        {companyFieldNames[attribute].resolve({ [attribute]: value })}
      </span>
    )
  } else if (attribute === 'selfBilling') {
    return (
      <span>
        {value ? booleanOptions.true.label : booleanOptions.false.label}
      </span>
    )
  } else if (attribute === 'isActive') {
    return (
      <span>{value ? statuses.active.label : statuses.inactive.label}</span>
    )
  }
  return (
    <span>
      {get(companyFieldNames, [attribute, 'options', value, 'label'], value)}
    </span>
  )
}

const AttributeRow = ({ attribute, changes, initialEvent, employees }) => {
  const change = changes[attribute.name]

  if (change && change.from && attribute.name === 'note') {
    const from = change.from
    const to = change.to

    let diff = Diff.diffWordsWithSpace(from, to)

    if (diff.length > 20) {
      diff = Diff.diffLines(from, to)
    }

    const diffText = diff.map((part, i) => (
      <span key={i}>
        {part.added ? (
          <span key={part.value}>
            {' '}
            <span className={styles.textAdded}>
              <FormatValue
                attribute={attribute.name}
                employees={employees}
                value={part.value}
              />
            </span>
          </span>
        ) : part.removed ? (
          <span key={part.value} className={styles.textRemoved}>
            <FormatValue
              attribute={attribute.name}
              employees={employees}
              value={part.value}
            />
          </span>
        ) : (
          <span key={part.value}>
            <FormatValue
              attribute={attribute.name}
              employees={employees}
              value={part.value}
            />{' '}
          </span>
        )}
      </span>
    ))

    return (
      <div className={styles.attrRow}>
        <div className={styles.attrLabel}>{attribute.label}</div>
        <div className={styles.attrValue}>{diffText}</div>
      </div>
    )
  }

  return change ? (
    <div className={styles.attrRow}>
      <div className={styles.attrLabel}>{attribute.label}</div>
      <div className={styles.attrValue}>
        {change.from != null || !initialEvent ? (
          <span>
            <span className={styles.textRemoved}>
              <FormatValue
                attribute={attribute.name}
                value={change.from}
                employees={employees}
              />
            </span>{' '}
          </span>
        ) : (
          ''
        )}
        <FormatValue
          attribute={attribute.name}
          value={change.to}
          employees={employees}
        />
      </div>
    </div>
  ) : null
}

const getActionClass = (event) => {
  if (event.comment) {
    return styles.updatedEvent
  } else {
    return styles.createdEvent
  }
}

export default ({
  event,
  initialEvent,
  date,
  employees,
  companies,
  monthlyTimeline,
}) => (
  <Col xs={12}>
    <Row className={getActionClass(event)}>
      <Col xs={3} className={styles.date}>
        {moment.utc(event.createdAt).format(dateFormatHuman)}
        {monthlyTimeline && (
          <div className={styles.name}>
            {get(companies, [event.companyId, 'legalName'])}
          </div>
        )}
      </Col>
      <Col xs={9}>
        <div className={styles.eventTitle}>
          <Avatar employeeId={event.createdBy} />
          {initialEvent ? (
            <span> added new company</span>
          ) : (
            <span> changed details</span>
          )}
        </div>
        {companyFields.map((atr, i) => (
          <AttributeRow
            initialEvent={initialEvent}
            key={i}
            attribute={atr}
            changes={{ ...event.changes }}
            employees={employees}
          />
        ))}
        {event.comment && (
          <p className={styles.reason}>
            <strong>Reason:</strong> {event.comment}
          </p>
        )}
        {event.deletedBy && (
          <p className={styles.deleted}>
            <strong>Deleted by: </strong>
            <Avatar employeeId={event.deletedBy} />
          </p>
        )}
      </Col>
    </Row>
  </Col>
)
