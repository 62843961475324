import moment from 'moment'
import {
  contractTypes,
  departments,
  legalForms,
  salaryForms,
  subcontractTypes,
  subdepartments,
  vlCompanies,
} from '../common/enums'
import { isEmail as isValidEmail } from 'validator'
import { isValidBIC } from 'ibantools'
import { apiRequest } from './network'
import { sum } from 'lodash'
import { getSalaryEur } from 'utils'

const maxLength = 256

export const rulesMessages = {
  isRequired: 'Field is required',
  isPercentage: 'Field has to be an integer between 0 and 100 inclusive',
  isAnyPercentage:
    'Field has to be a number between 0 and 100 inclusive with max 2 decimal places',
  isMoney: 'Field has to be a positive number with max 2 decimal places',
  isPositiveFloat: 'Field has to be a positive number',
  isNonZeroInteger: 'Field has to be a non-zero integer',
  isNonNegative: 'Field has to be a non-negative number',
  isZero: 'Field has to be zero',
  isLessOrEqualThan: (threshold) =>
    `Field has to be less or equal than ${threshold}`,
  isJiraIdUnique: 'Jira ID has to be unique',
  isCompanyIdUnique: 'Company ID has to be unique',
  isShareDateUnique: 'Valid from date has to be unique',
  isNonZeroFTE: `FTE has to be nonzero when salary form is ${salaryForms.fs.label} or ${salaryForms.fst.label}`,
  isEmail: 'Field has to be a valid email',
  isNullableEmail: 'Field has to be a valid email or empty',
  isDate: 'Field has to be a valid date',
  isWithoutSpaces: 'Field cannot contain spaces',
  isEmptyOrWithoutSpaces: 'Field has to be without spaces or empty',
  isSWIFT: 'Field has to be a valid SWIFT',
  isEmptyOrSWIFT: 'Field has to be a valid SWIFT or empty',
  isShorterThanMaxLength: 'Field has to be shorter than 256 characters',
  isValidPhoneNumber:
    'Phone number has to contain a country code and no spaces',
  isEmptyOrDate: 'Field has to be a valid date or empty',
  isSubcontractSumLeq: (subcontractType, subcontractsSum) =>
    `The sum from the ${subcontractTypes[subcontractType].label} subcontracts (${subcontractsSum}) should not exceed this value.`,
  isInvalidCompany: (invalidCompanyLabel) =>
    `Company ${invalidCompanyLabel} is not valid.`,
  isInvalidDepartment: (invalidDepartmentLabel) =>
    `Department ${invalidDepartmentLabel} is not valid.`,
  isInvalidSubdepartment: (invalidSubdepartmentLabel) =>
    `Subdepartment ${invalidSubdepartmentLabel} is not valid.`,
}

export function validateRequired(value) {
  return value !== undefined && value !== '' ? null : rulesMessages.isRequired
}

export function validateRequiredForEmployment(value, legalForm) {
  return legalForm && legalForms[legalForm].isEmployment
    ? validateRequired(value)
    : null
}

export function validateRequiredForInternalContracts(value, contractType) {
  return contractType && contractTypes[contractType].internalContract
    ? validateRequired(value)
    : null
}

export function validateRequiredOnlyIfOtherFieldPresent(value, field) {
  return field != null && field !== '' ? validateRequired(value || '') : null
}

export function validateRequiredForVlCompanyExceptSome(
  value,
  vlCompany,
  exceptedCompanies,
) {
  return vlCompany && !exceptedCompanies.includes(vlCompany)
    ? validateRequired(value)
    : null
}

export function validateRequiredMultipleRules(rules) {
  return rules.some((rule) => rule === null) ? null : rulesMessages.isRequired
}

export function validateRequiredOnContractChange(
  value,
  originalValue,
  contractChanged,
) {
  return !contractChanged ? null : validateRequired(value)
}

function validateContractExpirationRequired(legalForm) {
  return (
    legalForm === legalForms.student.key ||
    legalForm === legalForms.serviceAgreement.key
  )
}

export function validateDateRequiredForStudentAndServiceAgreement(
  value,
  legalForm,
) {
  return validateContractExpirationRequired(legalForm)
    ? validateDate(value)
    : null
}

function isInteger(value) {
  return /^[+-]?\d+$/.test(value)
}

export function validateIsZero(value, allowNonZero = false) {
  return allowNonZero || (isInteger(value) && parseInt(value, 10) === 0)
    ? null
    : rulesMessages.isZero
}

export function validateNonZeroInteger(value) {
  return isInteger(value) && parseInt(value, 10) !== 0
    ? null
    : rulesMessages.isNonZeroInteger
}

export function validateNonNegativeInteger(value) {
  return isInteger(value) && parseInt(value, 10) >= 0
    ? null
    : rulesMessages.isNonNegative
}

export function validateNonNegative(value) {
  return parseFloat(value) >= 0 ? null : rulesMessages.isNonNegative
}

export function validateLessOrEqualThan(threshold) {
  return (value) =>
    parseFloat(value) <= threshold
      ? null
      : rulesMessages.isLessOrEqualThan(threshold)
}

export function isFloat(value) {
  return /^\d+(\.(\d)*)?$/.test(value)
}

// max 2 decimal points
function max2decimalPoints(value) {
  return /^\d+(\.(\d){1,2})?$/.test(value)
}

export function nullableValidation(value, validation) {
  return value == null || value === '' ? null : validation(value)
}

export function validatePercentage(value) {
  const number = parseInt(value, 10)
  return isInteger(value) && number >= 0 && number <= 100
    ? null
    : rulesMessages.isPercentage
}

export function validateDecimalPercentage(value) {
  const number = Number(value)
  return max2decimalPoints(value) && number >= 0 && number <= 100
    ? null
    : rulesMessages.isAnyPercentage
}

export function validateMoney(value) {
  const number = parseFloat(value)
  return max2decimalPoints(value) && number >= 0 ? null : rulesMessages.isMoney
}

export function validatePositiveFloat(value) {
  const number = parseFloat(value)
  return isFloat(value) && number >= 0 ? null : rulesMessages.isPositiveFloat
}

async function validateUniqueId(
  currentId,
  originalId,
  url,
  query,
  ruleMessage,
) {
  if (!currentId) {
    return false
  }
  if (currentId === originalId) {
    return true
  }

  try {
    const options = {
      cache: 'no-cache',
      query,
      ignoreAuthError: true,
    }
    const res = await apiRequest(url, options)

    return !res.exists ? null : ruleMessage
  } catch (e) {
    console.error(e) // eslint-disable-line no-console
    return false
  }
}

export function validateJiraIdUnique(jiraId, originalJiraId) {
  return validateUniqueId(
    jiraId,
    originalJiraId,
    'employees/exists',
    { jiraId },
    rulesMessages.isJiraIdUnique,
  )
}

export function validateCompanyIdUnique(companyId, originalCompanyId) {
  return validateUniqueId(
    companyId,
    originalCompanyId,
    'companies/exists',
    { companyId },
    rulesMessages.isCompanyIdUnique,
  )
}

export function validateShareDateUnique(validFrom, shareValues) {
  return !shareValues.some((value) =>
    moment(value.validFrom).isSame(validFrom, 'day'),
  )
    ? null
    : rulesMessages.isShareDateUnique
}

export function validateNonZeroFTE(fte, salaryForm) {
  fte = parseInt(fte, 10)
  return (salaryForm === salaryForms.fs.key ||
    salaryForm === salaryForms.fst.key) &&
    fte === 0
    ? rulesMessages.isNonZeroFTE
    : null
}

export function validateEmail(value, allowEmpty = false) {
  return allowEmpty || (typeof value === 'string' && isValidEmail(value))
    ? null
    : rulesMessages.isEmail
}

export function validateNullableEmail(value) {
  return !value || validateEmail(value) === null
    ? null
    : rulesMessages.isNullableEmail
}

export function validateDate(value, allowEmpty = false) {
  return allowEmpty || (value && moment(value).isValid())
    ? null
    : rulesMessages.isDate
}

function isWithoutSpaces(value) {
  return typeof value === 'string' && !value.includes(' ')
}

export function validateWithoutSpaces(value) {
  return isWithoutSpaces(value) ? null : rulesMessages.isWithoutSpaces
}

export function validateEmptyOrWithoutSpaces(value) {
  return !value || isWithoutSpaces(value)
    ? null
    : rulesMessages.isEmptyOrWithoutSpaces
}

export function validateSWIFT(value) {
  return typeof value === 'string' && isValidBIC(value)
    ? null
    : rulesMessages.isSWIFT
}

export function validateEmptyOrSWIFT(value) {
  return !value || (typeof value === 'string' && isValidBIC(value))
    ? null
    : rulesMessages.isEmptyOrSWIFT
}

export function validateShorterThanMaxLength(value) {
  return value && value.length >= maxLength
    ? rulesMessages.isShorterThanMaxLength
    : null
}

export function validatePhoneNumber(value, allowEmpty = false) {
  // eslint-disable-next-line max-len
  const phoneNumberRegexp =
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
  return allowEmpty || phoneNumberRegexp.test(value)
    ? null
    : rulesMessages.isValidPhoneNumber
}

export function validateEmptyOrDate(value) {
  return !value || moment(value).isValid() ? null : rulesMessages.isEmptyOrDate
}

export function validateSubcontractSumLeq(
  valueStr,
  subcontracts,
  subcontractType,
  field,
) {
  if (!subcontracts) return null
  const value = parseFloat(valueStr)
  const subcontractsSum =
    sum(
      subcontracts.flatMap((sc) =>
        sc.subcontractType === subcontractType ? [parseFloat(sc[field])] : [],
      ),
    ) ?? 0
  return subcontractsSum <= value
    ? null
    : rulesMessages.isSubcontractSumLeq(subcontractType, subcontractsSum)
}

export function validateSubcontractSumSalaryEur(salaryEurStr, subcontracts) {
  if (!subcontracts) return null
  const salaryEur = parseFloat(salaryEurStr)
  const subcontractsSum =
    sum(
      subcontracts
        .filter(
          ({ subcontractType }) =>
            subcontractType === subcontractTypes.payment.key,
        )
        .map((sc) => parseFloat(getSalaryEur(sc))),
    ) ?? 0
  return subcontractsSum <= salaryEur
    ? null
    : rulesMessages.isSubcontractSumLeq(
        subcontractTypes.payment.key,
        subcontractsSum.toFixed(2),
      )
}

export const validateCompany = (companyKey, validFrom) =>
  applyOrganizationStructureRules(validFrom) &&
  !['group', 'theSpot', 'vacuumlabs', 'adalite'].includes(companyKey)
    ? rulesMessages.isInvalidCompany(vlCompanies[companyKey]?.label)
    : null

export const validateDepartment = (departmentKey, companyKey, validFrom) => {
  const company = vlCompanies[companyKey]
  const currentDeparment = departments[departmentKey]
  return applyOrganizationStructureRules(validFrom) &&
    company?.hasHierarchy &&
    !currentDeparment?.allowedParentVlCompanies?.includes(companyKey)
    ? rulesMessages.isInvalidDepartment(currentDeparment?.label)
    : null
}

export const validateSubdepartment = (
  subdepartmentKey,
  departmentKey,
  companyKey,
  validFrom,
) => {
  if (!subdepartmentKey) return null
  const department = departments[departmentKey]
  const currentSubdepartment = subdepartments[subdepartmentKey]
  return (applyOrganizationStructureRules(validFrom) &&
    department?.hasHierarchy &&
    !currentSubdepartment?.allowedParentDepartments?.includes(departmentKey)) ||
    validateDepartment(departmentKey, companyKey) !== null
    ? rulesMessages.isInvalidSubdepartment(currentSubdepartment?.label)
    : null
}

export const applyOrganizationStructureRules = (validFrom) =>
  validFrom >= moment('2025-01-01', 'YYYY-MM-DD')

export const isEmployment = (legalForm) =>
  ['employee', 'author', 'oneTimeWork', 'serviceAgreement', 'student'].includes(
    legalForm,
  )

export const isFreelancing = (legalForm) => !isEmployment(legalForm)
