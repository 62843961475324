import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import enhanceWithClickOutside from 'react-click-outside'
import classnames from 'classnames'
import { isArray } from 'lodash'
import { compareOptionsLexicographicallyByLabel } from '../utils'
import { multiFilters } from '../common/enums'
import styles from './ColumnDropdown.module.scss'

class _ColumnDropdown extends PureComponent {
  state = { showOptions: false }

  static propTypes = {
    label: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    download: PropTypes.bool,
  }

  handleClickInside = (e) => {
    e.stopPropagation()

    this.setState({ showOptions: !this.state.showOptions })
  }

  handleClickOutside = () => {
    if (this.state.showOptions) {
      this.setState({ showOptions: false })
    }
  }

  handleSelectOption = (e, value) => {
    e.stopPropagation()

    const { name, value: oldFilterValue, filterType, onClick } = this.props

    if (filterType === multiFilters.simple) {
      onClick({ name, value: [value] })
    } else if (filterType === multiFilters.multi) {
      const newFilterValue = oldFilterValue.includes(value)
        ? oldFilterValue.filter((v) => v !== value)
        : [...oldFilterValue, value]
      onClick({ name, value: newFilterValue })
    } else {
      onClick({ value })
    }
    this.setState({ showOptions: false })
  }

  handleSelectAll = (e) => {
    e.stopPropagation()

    const { name, onClick } = this.props

    onClick({ name, value: [] })
    this.setState({ showOptions: false })
  }

  render() {
    const { label, options, value, isValueDefault, className, download } =
      this.props
    const { showOptions } = this.state
    const optionsArray = isArray(options)
      ? options
      : Object.values(options).sort(compareOptionsLexicographicallyByLabel)

    return (
      <>
        <div>
          {showOptions && (
            <ul className={styles.dropdownContent}>
              {!download && (
                <li
                  className={styles.selectAll}
                  onClick={(e) => this.handleSelectAll(e)}
                >
                  All values
                </li>
              )}
              {optionsArray.map(({ key, label, tooltip }) => {
                let option = (
                  <li
                    key={key}
                    className={classnames(
                      value?.includes(key) && styles.selected,
                    )}
                    onClick={(e) => this.handleSelectOption(e, key)}
                  >
                    {label}
                  </li>
                )
                if (tooltip) {
                  option = (
                    <OverlayTrigger
                      placement="left"
                      key={key}
                      overlay={<Tooltip id={key}>{tooltip}</Tooltip>}
                    >
                      {option}
                    </OverlayTrigger>
                  )
                }
                return option
              })}
            </ul>
          )}
        </div>
        <div
          className={classnames([
            className,
            (isValueDefault == null ? value?.length : !isValueDefault) &&
              styles.selected,
            download ? styles.dropdownDownload : styles.dropdown,
          ])}
          onClick={download ? this.handleClickOutside : this.handleClickInside}
        >
          {label && <span>{label}</span>}
          {download && (
            <Button onClick={this.handleClickInside}>
              <Glyphicon glyph="download-alt" />
            </Button>
          )}
        </div>
      </>
    )
  }
}

const ColumnDropdown = enhanceWithClickOutside(_ColumnDropdown)

export default ColumnDropdown
