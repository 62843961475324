import { get, isString, isNumber } from 'lodash'

import { createXLSX, separator } from './export'

export const exportCompaniesOptions = [
  {
    key: 'xlsx',
    label: 'XLSX',
    tooltip: 'Download current table view as XLSX',
  },
]

export function generateCompaniesXLSX(companies, datePart, fields) {
  const rows = companies.map((company) =>
    getSpreadSheetRow(company, false, fields),
  )
  rows.unshift(getSpreadSheetHeaders(fields))
  createXLSX(rows, 'companies', datePart)
}

function getSpreadSheetRow(company, csv = false, { companyFields }) {
  const formattedCompanyFields = companyFields.map(
    ({ name, options, resolve, exportValue }) => {
      let value

      if (resolve) {
        value = resolve(company, { exportable: true })
      } else if (exportValue) {
        value = exportValue(company)
      } else {
        value = company[name]
        if (options) {
          value = get(options, [value, 'label'], value)
        }
      }

      if (csv) {
        value = isNumber(value) || isString(value) ? value.toString() : ''
        if (value.includes(separator) || value.includes('"')) {
          value = `"${value.replace(/"/g, '""')}"`
        }
      } else {
        if (name === 'salary' || name === 'fteEquivalent') {
          value = parseFloat(value)
        }
        value =
          (!isNaN(value) && isNumber(value)) || isString(value) ? value : ''
      }
      return value
    },
  )

  return formattedCompanyFields
}

function getSpreadSheetHeaders({ companyFields = [] }) {
  return companyFields.map(({ label }) => label)
}
