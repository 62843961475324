import React, { Component } from 'react'
import { Button, ButtonGroup, Col, Glyphicon, Row } from 'react-bootstrap'
import styles from './EmployeeEditor.module.scss'
import { filterVlCompanyOptions, getSubcontractFields } from './employeeFields'
import Field from './components/Field'
import { subcontractTypes } from './common/enums'

/**
 * Get subcontract fields based on subcontract type
 *
 * In payment subcontract type we want to display "Employer" field if legal form
 * is of employment type and "Default Company" field if legal form is of company
 * type.
 */
export class EmployeeSubcontractsEditor extends Component {
  render() {
    const {
      employees,
      values,
      validation,
      field,
      valueOrEmptyString,
      handleDropSubcontract,
      onChange,
      companyOptions,
      companiesSearchLink,
    } = this.props

    const isDisabled = (field, subcontract) => {
      const { name, contractField } = field
      const { taxBonusOnChildren } = subcontract

      if (name === 'taxBonusNumberOfChildren') {
        return taxBonusOnChildren !== 'true'
      }
      return false
    }

    return (
      <Row>
        {values.subcontracts.map((subcontract, subcontractIndex) => (
          <Col key={`subcontract-${subcontractIndex}`} xs={4}>
            <h4 className={styles.subcontractTitle}>
              Subcontract {subcontractIndex + 1} Details
              <ButtonGroup>
                {Object.keys(subcontractTypes).map((type) => (
                  <Button
                    key={`subcontract-type-${type}`}
                    name={`${subcontractIndex}-subcontractType`}
                    value={type}
                    className={
                      subcontract.subcontractType === type && styles.highlighted
                    }
                    onClick={onChange}
                  >
                    {subcontractTypes[type].label}
                  </Button>
                ))}
              </ButtonGroup>
              <Button onClick={() => handleDropSubcontract(subcontractIndex)}>
                <Glyphicon glyph="trash" />
              </Button>
            </h4>
            {getSubcontractFields(subcontract).map(
              (subcontractField, index) => {
                const subcontractFieldString = `${subcontractIndex}-${subcontractField.name}`
                let options =
                  subcontractField.name === 'companyId'
                    ? companyOptions
                    : subcontractField.options
                const link =
                  subcontractField.name === 'companyId'
                    ? companiesSearchLink
                    : null

                if (subcontractField.name === 'vlCompany') {
                  options = filterVlCompanyOptions(
                    options,
                    subcontract[subcontractField.name],
                  )
                } else if (subcontractField.name === 'reportsTo') {
                  options = employees.map((e) => ({
                    label: e.jiraId,
                  }))
                  options.push({ label: 'Nobody' })
                }

                return (
                  <Field
                    {...subcontractField}
                    name={`${subcontractIndex}-${subcontractField.name}`}
                    key={index}
                    labelLength={4}
                    valueLength={8}
                    value={valueOrEmptyString(
                      subcontract[subcontractField.name],
                    )}
                    validationState={validation[subcontractFieldString]}
                    units={
                      subcontractField.units &&
                      subcontractField.units(subcontract)
                    }
                    required={
                      subcontractField.required
                        ? subcontractField.required(subcontract)
                        : !!validation[subcontractFieldString]
                    }
                    options={options}
                    disabled={isDisabled(subcontractField, subcontract)}
                    link={link}
                    {...field(subcontractFieldString, onChange)}
                  />
                )
              },
            )}
            {((t) => t && <p>{t}</p>)(
              subcontractTypes[subcontract.subcontractType].editHelpMessage,
            )}
          </Col>
        ))}
      </Row>
    )
  }
}
