import React, { FC, useCallback, useState, useContext } from 'react'
import { keyBy, mapValues } from 'lodash'
import { Button, ButtonToolbar } from 'react-bootstrap'

import SimpleModal from './SimpleModal'
import {
  apiRequest,
  dateFormat,
  generateCSVImportTemplate,
  getConfig,
  notifications,
  pushNotification,
  trimSpaces,
  validateRequired,
} from '../utils'
import moment from 'moment'
import { parse } from 'csv-parse/lib/sync'
import TimelineEvent from './TimelineEvent'
import {
  employeeFieldsEditable,
  employeeFieldsExportable,
  employmentFieldsEditable,
  nonEmploymentFieldsEditable,
} from '../employeeFields'
import { companyFieldsForEmployeeExport } from '../companyFields'
import { getFieldErrorMessages } from './BulkUpdateModal'
import NotificationContext from 'utils/context/NotificationContext'
import { legalForms } from 'common/enums'
import { TimelineEventType } from './types.common'

type FormControl = {
  type?: string | null
  componentClass?: string | null
  creatable?: boolean | null
  step?: string | null
  readOnly?: boolean | null
}

type employeeField = {
  name: string
  label: string
  edit?: boolean
  exportable?: boolean
  viewInTable?: boolean
  personalDetail?: boolean
  formControl?: FormControl
  dateRangeFilter?: boolean
  options?: any // new type should be defined
  viewInEditor?: boolean
  salaryInfo?: boolean
  contractInfo?: boolean
  contractField?: boolean
  monthlyExportable?: boolean
  paymentSubcontractInfo?: boolean
  reportingSubcontractInfo?: boolean
  filter?: string
  hideOnCreate?: boolean
  companyDetail?: boolean
  employmentDetail?: boolean
  isNullable?: boolean
  rangeFilter?: boolean
  omitInDatabase?: boolean
  filterDefaultValue?: string[]
  showForAuthor?: boolean
  validation?: any // new type should be defined
  parse?: (value: string) => string | number | boolean | null
  resolve?: () => string | number | undefined
  required?: () => boolean
  units?: () => string
  render?: () => JSX.Element | string | boolean | null | undefined
  exportValue?: () => string | number
}

const validateEmployeeFields = async (
  employeeData: Record<string, string | string[]>,
  fields: employeeField[],
) => {
  const validationMessages: Record<string, string | null> = {}
  for (const field of fields) {
    validationMessages[field.name] = await getFieldErrorMessages(
      employeeData[field.name],
      field,
    )
  }
  return validationMessages
}

const getTemplateFile = () => {
  const dateFormatted = moment.utc().format(dateFormat)
  const fields = {
    employeeFields: employeeFieldsExportable,
    companyFields: companyFieldsForEmployeeExport,
  }
  generateCSVImportTemplate([], dateFormatted, fields)
}

type BulkImportState = {
  jiraId: string
  data: Record<string, string | number | boolean>
  event: TimelineEventType
  resolved: boolean
}

type BulkImportModalProps = {
  show: () => void
  onCancel: () => void
}

const BulkImportModal: FC<BulkImportModalProps> = ({ show, onCancel }) => {
  const [preparedImport, setPreparedImport] = useState<
    BulkImportState[] | null
  >(null)
  const { addNotification } = useContext(NotificationContext)

  const close = () => {
    onCancel()
    setPreparedImport(null)
  }

  const saveDrafts = async () => {
    if (preparedImport === null) {
      return
    }
    try {
      await Promise.all(
        preparedImport.map((employee) => {
          const options = {
            method: 'POST',
            body: {
              ...employee.data,
              id: undefined,
            },
          }
          return apiRequest('drafts', options)
        }),
      )
      pushNotification(notifications.draftSaved, addNotification)
    } catch {
      pushNotification(notifications.networkError, addNotification)
    }

    close()
  }

  const loadBulkChanges = async (records: Record<string, string>[]) => {
    const { userId } = getConfig()

    if (records.length < 1) {
      pushNotification(
        notifications.invalidFile,
        addNotification,
        ' (empty file)',
      )
      return
    }

    const createdAt = moment.utc()
    const importedData: BulkImportState[] = await Promise.all(
      records.map(async (data: Record<string, string>) => {
        const areEmployeeLegalFormsEmploymentArray = (
          values: Record<string, string>,
        ): boolean[] => [
          values.legalForm && legalForms[values.legalForm].isEmployment,
        ]
        // get fields based on employment status
        const isSomeLegalFormEmployment =
          areEmployeeLegalFormsEmploymentArray(data).some(Boolean)
        const isSomeLegalFormNotEmployment =
          !areEmployeeLegalFormsEmploymentArray(data).every(Boolean)

        let fields: employeeField[] = [
          ...nonEmploymentFieldsEditable,
          ...employeeFieldsEditable,
        ]

        if (isSomeLegalFormEmployment && !isSomeLegalFormNotEmployment) {
          fields = employmentFieldsEditable
        }
        if (!isSomeLegalFormEmployment && isSomeLegalFormNotEmployment) {
          fields = nonEmploymentFieldsEditable
        }

        // remove fields that are not supposed to be edited by user
        fields = fields.filter(
          ({ formControl }) => !formControl || !formControl.readOnly,
        )

        fields = fields.filter(
          ({ name, validation }) =>
            validation && validation[0] !== validateRequired && data[name],
        )

        // parsed input values
        fields.forEach((field) => {
          try {
            // parse may throw exception if the value does not exist in the map of options
            data[field.name] = trimSpaces(
              field.parse ? field.parse(data[field.name]) : data[field.name],
            )
          } catch {
            // do nothing - let the value unmodified and let the validation to handle possible errors
          }
        })

        // validate all fields
        const validationMessages = await validateEmployeeFields(data, fields)

        const changes = mapValues(
          keyBy(fields, 'name'),
          (field: { name: string }) => ({
            to: data[field.name],
            extra: validationMessages[field.name],
          }),
        )

        const event: TimelineEventType = {
          createdAt,
          createdBy: userId,
          type: 'contract',
          changes,
          validFrom: createdAt,
          firstName: data.firstName,
          lastName: data.lastName,
        }

        return {
          jiraId: data.jiraId,
          data,
          event,
          resolved: !Object.values(validationMessages).filter(
            (err) => err !== null,
          ).length,
        }
      }),
    )
    setPreparedImport(importedData)
  }

  const onFileChange = useCallback(
    async (event) => {
      const uploadedFile = event.target.files[0]

      const text = await uploadedFile.text()

      try {
        const records = parse(text, {
          bom: true,
          columns: true,
          trim: true,
        })
        await loadBulkChanges(records)
      } catch {
        pushNotification(
          notifications.invalidFile,
          addNotification,
          ' (file corrupted)',
        )
      }
    },
    [addNotification, loadBulkChanges],
  )

  const allResolved =
    preparedImport !== null && preparedImport.every(({ resolved }) => resolved)
  return (
    <SimpleModal
      show={show}
      onCancel={close}
      title={'Bulk import'}
      footer={
        <ButtonToolbar className="pull-right">
          <Button disabled={!allResolved} onClick={saveDrafts}>
            Submit
          </Button>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={getTemplateFile}>Download template file</Button>
        </ButtonToolbar>
      }
      large
    >
      <h3>Step 1: upload your CSV file</h3>
      <input type="file" accept=".csv" onChange={onFileChange} />
      {preparedImport !== null && <h3>Step 2: check your changes</h3>}
      {preparedImport !== null &&
        preparedImport.map(({ event }, i) => (
          <TimelineEvent
            key={i}
            event={event}
            initialEvent
            date={null}
            companies={{}}
          />
        ))}
      {allResolved && (
        <p>No errors detected. You can now submit your changes.</p>
      )}
      {preparedImport !== null && !allResolved && (
        <p>Errors detected (marked as red). Please resolve them.</p>
      )}
    </SimpleModal>
  )
}

export default BulkImportModal
