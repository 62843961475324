import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon, ButtonToolbar } from 'react-bootstrap'
import classnames from 'classnames'
import { get } from 'lodash'
import { getConfig } from './utils'
import styles from './Companies.module.scss'

export default class Company extends Component {
  static propTypes = {
    rowNumber: PropTypes.number.isRequired,
    company: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
    companyFields: PropTypes.array.isRequired,
    showTimeline: PropTypes.func.isRequired,
    showPrefilledEditor: PropTypes.func.isRequired,
  }

  handleTimelineClick = (event) => {
    event.stopPropagation()
    this.props.showTimeline(this.props.company)
  }

  handleEditClick = (event) => {
    event.stopPropagation()
    this.props.showPrefilledEditor(this.props.company)
  }

  render() {
    const { company, rowNumber, employees, companyFields } = this.props

    return (
      <div className={styles.companiesBodyRow}>
        {companyFields.map(
          ({ name, options, resolve, render, formControl, units }) => {
            let value = company[name]
            const numberClass =
              formControl && formControl.type === 'number' ? styles.number : ''

            if (render) {
              value = render(company)
            } else if (resolve) {
              value = resolve(company)
            } else if (options) {
              value = get(options, [value, 'label'], value)
            }

            if (name === 'rowNumber') {
              value = rowNumber
            }

            if (name === 'employeeIds') {
              value = value
                .map(
                  (employeeId) =>
                    employees.find((e) => e.value === employeeId).label,
                )
                .join(', ')
            }

            return (
              <div
                key={name}
                className={classnames([
                  numberClass,
                  styles.companiesCell,
                  styles[`box-${name}`],
                ])}
              >
                {value}
                {value != null && units && !render && (
                  <span>&nbsp;{units(company)}</span>
                )}
              </div>
            )
          },
        )}
        <div
          className={classnames([
            styles.companiesCell,
            styles.companiesButtons,
          ])}
        >
          <ButtonToolbar className="pull-right">
            <Button
              data-test="timelineCompany"
              bsSize="small"
              title="Timeline"
              onClick={this.handleTimelineClick}
            >
              <Glyphicon glyph="time" />
            </Button>
            {getConfig().writeAccess && company.writeAccess && (
              <Button
                data-test="editCompany"
                bsSize="small"
                bsStyle="primary"
                title="Edit"
                onClick={this.handleEditClick}
              >
                <Glyphicon glyph="pencil" />
              </Button>
            )}
          </ButtonToolbar>
        </div>
      </div>
    )
  }
}
