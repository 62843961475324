import React from 'react'
import { ButtonToolbar, Button, Modal } from 'react-bootstrap'

export default ({ onCancel, onConfirm, show, large, body, footer }) => (
  <Modal show={show} onHide={onCancel} bsSize={large ? 'large' : null}>
    <Modal.Header>
      <h4>Warning!</h4>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
      {footer || (
        <ButtonToolbar className="pull-right">
          <Button onClick={onCancel}>Cancel</Button>
          <Button bsStyle="primary" type="submit" onClick={onConfirm}>
            Confirm
          </Button>
        </ButtonToolbar>
      )}
    </Modal.Footer>
  </Modal>
)
