import React, { useEffect, useRef, useState } from 'react'
import { Route } from 'react-router-dom'
import { Switch, Redirect } from 'react-router'
import NotificationSystem from 'react-notification-system'
import Employees from './Employees'
import Companies from './Companies'
import Shares from './Shares'
import Login from './components/Login'
import Navbar from './components/Navbar'
import { getConfig } from './utils'
import { fetchCurrencyRates } from './utils/network'

import 'react-select/dist/react-select.css'
import 'react-datetime/css/react-datetime.css'
import './customBootstrap.global.scss'
import NotificationContext from 'utils/context/NotificationContext'
import Notification from 'types/Notification'

export default function App() {
  const notificationSystem: React.RefObject<any> = useRef(null)

  const addNotification = (notification: Notification) => {
    if (!notificationSystem.current) {
      return
    }
    notificationSystem.current.addNotification({
      ...notification,
      position: 'tr',
      autoDismiss: 10,
    })
  }

  const { loggedIn } = getConfig()

  // currency rates are cached in global variable in network.js
  // TODO: rewrite to use context
  const [, setCurrencyRates] = useState({})
  useEffect(() => {
    if (loggedIn) {
      fetchCurrencyRates().then((response) => setCurrencyRates(response))
    }
  }, [loggedIn])

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {loggedIn ? (
        <div data-test="mainMenu">
          <Navbar />
          <Switch>
            <Route exact path="/(employees)?" component={Employees} />
            <Route exact path="/companies" component={Companies} />
            <Route exact path="/shares" component={Shares} />
            <Redirect to="/" />
          </Switch>
        </div>
      ) : (
        <Route path="/" component={Login} />
      )}
      <NotificationSystem ref={notificationSystem} />
    </NotificationContext.Provider>
  )
}
