import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { keyBy } from 'lodash'
import { getConfig, apiRequest, handleUncaughtError, dateFormat } from './utils'
import NotificationContext from 'utils/context/NotificationContext'
import CompanyTimeline from './CompanyTimeline'

export default class CompanyTimelineWrapper extends Component {
  state = {
    events: null,
    companyName: null,
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    companyId: PropTypes.number,
    month: PropTypes.object,
    editedBy: PropTypes.number,
    forCompany: PropTypes.number,
    getCompanyNameById: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    handleTimelineFilter: PropTypes.func.isRequired,
  }

  static contextType = NotificationContext

  componentDidMount() {
    this.update({}, this.props)
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps, this.props)
  }

  componentWillUnmount() {
    this.clearPolling()
  }

  getAllCompanies = () => {
    apiRequest('companies/legalNames')
      .then((response) => {
        this.setState({ companies: keyBy(response, 'id') })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  getFilterDate = (props = this.props) => props.month || moment.utc()

  update(props, nextProps) {
    const { show: oldShow, companyId: oldCompanyId } = props
    const { show, companyId, getCompanyNameById } = nextProps

    if (show) {
      const oldFilterDate = this.getFilterDate(props)
      const filterDate = this.getFilterDate(nextProps)
      if (
        !oldShow ||
        companyId !== oldCompanyId ||
        !oldFilterDate.isSame(filterDate)
      ) {
        this.setState({ events: undefined })
        this.getTimelineEvents(companyId, filterDate)
        getCompanyNameById(companyId).then((companyName) =>
          this.setState({ companyName }),
        )
      }
      if (!oldShow && companyId == null) {
        this.getAllCompanies()
      }
      this.setPolling()
    } else {
      this.clearPolling()
    }
  }

  setPolling = () => {
    if (!this.polling) {
      this.polling = window.setInterval(
        this.getTimelineEvents,
        getConfig().polling,
      )
    }
  }

  clearPolling = () => {
    if (this.polling) {
      window.clearInterval(this.polling)
      this.polling = null
    }
  }

  getTimelineEvents = (
    id = this.props.companyId,
    date = this.getFilterDate(),
  ) => {
    const options = {
      query: { date: date.format(dateFormat) },
    }
    const idParam = id ? `${id}/` : ''
    apiRequest(`companies/${idParam}timeline`, options)
      .then((response) => {
        this.setState({ events: response })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  render() {
    const {
      show,
      onCancel,
      companyId,
      employees,
      editedBy,
      forCompany,
      handleTimelineFilter,
      month,
    } = this.props
    const { companyName, events, companies } = this.state
    const date = this.getFilterDate()

    return (
      <CompanyTimeline
        show={show}
        onCancel={onCancel}
        companyId={companyId}
        employees={employees}
        editedBy={editedBy}
        forCompany={forCompany}
        handleTimelineFilter={handleTimelineFilter}
        month={month}
        companyName={companyName}
        events={events}
        companies={companies}
        date={date}
      />
    )
  }
}
