import React from 'react'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { get, has } from 'lodash'
import * as Diff from 'diff'
import { statuses, booleanOptions } from '../common/enums'
import {
  dateFormatHuman,
  monthFormatHuman,
  getFullNameReversed,
  emptyValue,
} from '../utils'
import {
  employeeFields,
  employeeFieldNames,
  legacyFields,
  getSubcontractFields,
  personalDetailsFields,
} from '../employeeFields'
import classnames from 'classnames'
import Avatar from './Avatar'
import styles from './TimelineEvent.module.scss'

const FormatValue = ({ attribute, value, companies }) => {
  if (attribute === 'isActive') {
    if (typeof value !== 'boolean') {
      value = employeeFieldNames[attribute].parse(value)
    }
    return (
      <span>{value ? statuses.active.label : statuses.inactive.label}</span>
    )
  } else if (attribute === 'hasSalaryBonus') {
    return (
      <span>
        {value ? booleanOptions.true.label : booleanOptions.false.label}
      </span>
    )
  } else if (
    attribute === 'validFrom' ||
    attribute === 'contractExpiration' ||
    attribute === 'birthDate' ||
    attribute === 'contractDate'
  ) {
    return (
      <span>
        {employeeFieldNames[attribute].resolve({ [attribute]: value })}
      </span>
    )
  } else if (attribute === 'companyId') {
    return (
      <span>{value ? get(companies, [value, 'legalName']) : emptyValue}</span>
    )
  } else if (attribute === 'subcontracts') {
    return (
      <span>
        {value && value.length
          ? value.map((o, i) => (
              <div className={styles.subcontract} key={i}>
                <span className={styles.subcontractsSubtitle}>
                  Subcontract {i + 1}
                </span>
                {getSubcontractFields(o, true).map(
                  ({ name, label, options, resolve, render, units }) => {
                    let value = o[name]

                    if (render) {
                      value = render(o)
                    } else if (resolve) {
                      try {
                        value = resolve(o)
                      } catch {
                        // eslint-disable-next-line no-console
                        console.warn(
                          `Cannot resolve value '${value}' of field ${name}`,
                        )
                      }
                    } else if (options) {
                      value = get(options, [value, 'label'], value)
                    }

                    return (
                      <div key={`${i}-${name}`}>
                        {label}: {value} {units && units(o)}
                      </div>
                    )
                  },
                )}
              </div>
            ))
          : emptyValue}
      </span>
    )
  } else {
    return (
      <span>
        {get(employeeFieldNames, [attribute, 'options', value, 'label'], value)}
      </span>
    )
  }
}

const addUnits = (value, attribute, salaryForm) => {
  if (attribute.name === 'salary' || attribute.name === 'superGrossSalary') {
    return `${value} ${attribute.units({ salaryForm })}`
  } else {
    return attribute.units ? `${value}${attribute.units()}` : value
  }
}

const AttributeRow = ({ attribute, changes, initialEvent, companies }) => {
  if (attribute.name === 'validFrom' && !initialEvent) {
    return null
  }
  const change = changes[attribute.name]

  if (change && change.from && attribute.name === 'note') {
    const from = change.from
    const to = change.to || ''
    const extra = change.extra

    let diff = Diff.diffWordsWithSpace(from, to)

    if (diff.length > 20) {
      diff = Diff.diffLines(from, to)
    }

    const diffText = diff.map((part, i) => (
      <span key={i}>
        {part.added ? (
          <span key={part.value}>
            {' '}
            <span className={styles.textAdded}>
              <FormatValue
                attribute={attribute.name}
                value={addUnits(part.value, attribute)}
              />
            </span>
          </span>
        ) : part.removed ? (
          <span key={part.value} className={styles.textRemoved}>
            <FormatValue
              attribute={attribute.name}
              value={addUnits(part.value, attribute)}
            />
          </span>
        ) : (
          <span key={part.value}>
            <FormatValue
              attribute={attribute.name}
              value={addUnits(part.value, attribute)}
            />{' '}
          </span>
        )}
      </span>
    ))

    return (
      <div
        className={classnames([
          styles.attrRow,
          { [styles.note]: attribute.name === 'note' },
        ])}
      >
        <div className={styles.attrLabel}>{attribute.label}</div>
        <div className={styles.attrValue}>{diffText}</div>
        <div className={styles.textRemoved}>{extra}</div>
      </div>
    )
  }

  return change ? (
    <div
      className={classnames([
        styles.attrRow,
        styles.newValue,
        { [styles.note]: attribute.name === 'note' && change.to },
      ])}
    >
      <div className={styles.attrLabel}>{attribute.label}</div>

      <div className={styles.attrValue}>
        {change.from != null ? (
          <span>
            <span className={styles.textRemoved}>
              <FormatValue
                attribute={attribute.name}
                value={addUnits(
                  change.from,
                  attribute,
                  get(changes, ['salaryForm', 'from']),
                )}
                companies={companies}
              />
            </span>{' '}
          </span>
        ) : (
          ''
        )}
        <FormatValue
          attribute={attribute.name}
          value={addUnits(
            change.to,
            attribute,
            get(changes, ['salaryForm', 'to']),
          )}
          companies={companies}
        />
      </div>
      <div className={styles.textRemoved}>{change.extra}</div>
    </div>
  ) : null
}

const wasContractMoved = ({ type, changes }) => {
  /**
   * changes will include only employees editable fields, but not all of them are
   * related to contract, namely personal details
   * we don't want to permanetly remove them (e.g. bulk update includes these),
   * just when checking what type of operation was performed (create/update/move)
   */
  const personalFieldNames = personalDetailsFields.map(({ name }) => name)
  const extraExcludedFieldsName = ['comment']

  const excludedFields = new Set([
    ...personalFieldNames,
    ...extraExcludedFieldsName,
  ])

  const contractFields = Object.keys(changes).filter(
    (name) => !excludedFields.has(name),
  )
  return (
    type === 'contract' &&
    contractFields.length === 1 &&
    has(changes, 'validFrom')
  )
}

const ActionText = ({ event }) => {
  const { validFrom, shareNumber, type, changes } = event
  const format = type === 'contract' ? dateFormatHuman : monthFormatHuman
  const validFromText = moment.utc(validFrom).format(format)

  if (type === 'contract') {
    if (wasContractMoved(event)) {
      const oldValue = moment
        .utc(changes.validFrom.from)
        .format(dateFormatHuman)
      const newValue = moment.utc(changes.validFrom.to).format(dateFormatHuman)
      return (
        <span>
          {' '}
          moved contract from <strong>{oldValue}</strong> to
          <strong> {newValue}</strong>
        </span>
      )
    } else if (!has(changes, 'validFrom')) {
      return (
        <span>
          {' '}
          updated contract starting from <strong>{validFromText}</strong>
        </span>
      )
    } else {
      return (
        <span>
          {' '}
          created new contract starting from <strong>{validFromText}</strong>
        </span>
      )
    }
  } else if (type === 'personalDetails') {
    return <span> updated employee's personal data</span>
  } else {
    const action = shareNumber > 0 ? 'added' : 'removed'
    const value = Math.abs(shareNumber)
    return (
      <span>
        {' '}
        {action} {value} manual shares in <strong>{validFromText}</strong>
      </span>
    )
  }
}

const getActionClass = (event, date) => {
  if (event.deletedAt) {
    return styles.deletedEvent
  } else if (
    event.validFrom != null &&
    date &&
    moment.utc(event.validFrom).isAfter(date)
  ) {
    return styles.futureEvent
  } else if (wasContractMoved(event)) {
    return styles.updatedEvent // moved contract
  } else if (!has(event.changes, 'validFrom')) {
    return styles.updatedEvent
  } else {
    return styles.createdEvent
  }
}

export default ({ event, initialEvent, date, companies }) => (
  <Col xs={12}>
    <Row className={getActionClass(event, date)}>
      <Col xs={3} className={styles.date}>
        {moment.utc(event.createdAt).format(dateFormatHuman)}
        {event.firstName && (
          <div className={styles.name}>{getFullNameReversed(event)}</div>
        )}
      </Col>
      <Col xs={9}>
        <div className={styles.eventTitle}>
          <Avatar employeeId={event.createdBy} />
          {initialEvent ? (
            <span> added new employee</span>
          ) : (
            <ActionText event={event} />
          )}
        </div>
        {event.changes &&
          employeeFields
            .concat(legacyFields)
            .concat([{ name: 'subcontracts', label: 'Subcontracts' }])
            .map((atr, i) => (
              <AttributeRow
                initialEvent={initialEvent}
                key={i}
                attribute={atr}
                changes={event.changes}
                companies={companies}
              />
            ))}
        {event.comment && (
          <div className={styles.reason}>
            <strong>Reason: </strong>
            {event.comment}
          </div>
        )}
        {event.deletedBy && (
          <p className={styles.deleted}>
            <strong>Deleted by: </strong>
            <Avatar employeeId={event.deletedBy} />
          </p>
        )}
      </Col>
    </Row>
  </Col>
)
