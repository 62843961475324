import React from 'react'
import { Col, Row, Glyphicon } from 'react-bootstrap'
import { getConfig } from '../utils'
import styles from './TableTitle.module.scss'

export default ({ name, onClick }) => (
  <Row>
    <Col xs={12}>
      <h3 data-test="tableTitle">
        {name}{' '}
        {getConfig().writeAccess && (
          <small className={styles.addNew} onClick={onClick} data-test="addNew">
            <Glyphicon glyph="plus" /> add new
          </small>
        )}
      </h3>
    </Col>
  </Row>
)
