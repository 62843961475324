import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, ButtonToolbar } from 'react-bootstrap'
import { validated } from 'react-custom-validation'
import { isEqual, isNumber, isString } from 'lodash'
import moment from 'moment'
import {
  statuses,
  countries,
  booleanOptions,
  branches,
  currencyValues,
} from './common/enums'
import { companyFieldsForEditor as companyFields } from './companyFields'
import SimpleModal from './components/SimpleModal'
import Field from './components/Field'
import {
  getConfig,
  apiRequest,
  handleUncaughtError,
  pushNotification,
  notifications,
  trimSpaces,
} from './utils'
import NotificationContext from 'utils/context/NotificationContext'

export const shownEditorState = {
  editor: {
    show: true,
    company: {},
    updating: false,
  },
}

export const hiddenEditorState = {
  editor: { show: false, company: {}, updating: false },
  employeeId: null,
  contractId: null,
}

export default class CompanyEditor extends Component {
  static contextType = NotificationContext

  static propTypes = {
    show: PropTypes.bool.isRequired,
    updating: PropTypes.bool.isRequired,
    company: PropTypes.object.isRequired,
    reloadEditorData: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    employeeId: PropTypes.number,
    contractId: PropTypes.number,
  }

  constructor(props) {
    super(props)

    const defaultFormState = this.getDefaultFormState()

    this.state = {
      values: {},
      ...defaultFormState,
    }
  }

  componentDidUpdate(prevProps) {
    const { company, updating } = this.props

    if (prevProps.show !== this.props.show) {
      this.clearForm()
    }

    if (updating && !isEqual(company, prevProps.company)) {
      const values = { ...company }
      values.isActive = values.isActive
        ? statuses.active.key
        : statuses.inactive.key
      values.employeeIds = values.employeeIds.join(',')
      values.deskRental = values.deskRental
        ? booleanOptions.true.key
        : booleanOptions.false.key
      values.spotMembership = values.spotMembership
        ? booleanOptions.true.key
        : booleanOptions.false.key
      values.spotCatering = values.spotCatering
        ? booleanOptions.true.key
        : booleanOptions.false.key
      values.vatPayer = values.vatPayer
        ? booleanOptions.true.key
        : booleanOptions.false.key
      values.selfBilling = values.selfBilling
        ? booleanOptions.true.key
        : booleanOptions.false.key
      values.isPlayrollFreelancer = values.isPlayrollFreelancer
        ? booleanOptions.true.key
        : booleanOptions.false.key
      values.taxAgreement =
        values.taxAgreement && !moment.isMoment(values.taxAgreement)
          ? moment.utc(values.taxAgreement)
          : values.taxAgreement
      values.randomSalary = values.randomSalary
        ? booleanOptions.true.key
        : booleanOptions.false.key
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ values, updating: true })
    }

    if (this.props.show) {
      if (!this.polling && updating) {
        this.polling = window.setInterval(
          prevProps.reloadEditorData,
          getConfig().polling,
        )
      }
    } else if (this.polling) {
      window.clearInterval(this.polling)
      this.polling = null
    }
  }

  componentWillUnmount() {
    if (this.polling) {
      window.clearInterval(this.polling)
      this.polling = null
    }
  }

  getDefaultFormState = () => ({
    values: {
      country: countries.sk.key,
      isActive: statuses.active.key,
      employeeIds: this.props.employeeId
        ? this.props.employeeId.toString()
        : '',
      selfBilling: booleanOptions.false.key,
      isPlayrollFreelancer: booleanOptions.false.key,
      deskRental: booleanOptions.false.key,
      spotMembership: booleanOptions.false.key,
      spotCatering: booleanOptions.false.key,
      vatPayer: booleanOptions.false.key,
      billingTo: branches.vlsk.key,
      randomSalary: booleanOptions.true.key,
      paymentCurrency: currencyValues.EUR.key,
    },
    updating: false,
  })

  clearForm = () => {
    this.setState(this.getDefaultFormState())
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ values: { ...this.state.values, [name]: value } })
  }

  handleCreate = (onCreate) => (e) => {
    const {
      state: { values },
      props: { onSave },
    } = this
    const data = {}
    companyFields.forEach(({ name, parse }) => {
      const value = trimSpaces(values[name])
      data[name] = parse ? parse(value) : value
    })

    this.upsertCompany(data, () => {
      this.clearForm()
      pushNotification(
        notifications.companyCreated,
        this.context.addNotification,
      )
      onSave()
      onCreate()
    })
  }

  handleUpdate = (onUpdate) => (e) => {
    const {
      state: { values },
      props: { onSave },
    } = this
    const data = {
      id: values.id,
    }
    companyFields.forEach(({ name, parse }) => {
      const value = trimSpaces(values[name])
      data[name] = parse ? parse(value) : value
    })

    this.upsertCompany(data, () => {
      this.clearForm()
      pushNotification(
        notifications.companyUpdated,
        this.context.addNotification,
      )
      onSave()
      onUpdate()
    })
  }

  companyChanged = () => {
    const {
      state: { values, updating },
      props: { company },
    } = this
    if (updating) {
      return companyFields.some(({ name, parse }) => {
        const value = parse ? parse(values[name]) : values[name]
        const originalValue = parse ? parse(company[name]) : company[name]

        if (name === 'employeeIds') {
          // we don't care about the order of employeeIds
          return value.sort().join() !== originalValue.sort().join()
        } else {
          return value !== originalValue
        }
      })
    } else {
      return false
    }
  }

  upsertCompany = (data, onResponse) => {
    if (this.props.contractId) {
      data.contractId = this.props.contractId
    }
    const options = {
      method: 'POST',
      body: { data },
    }
    apiRequest('companies', options)
      .then(onResponse)
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  render() {
    const { onChange, handleCreate, handleUpdate } = this
    const { show, onCancel, company, employees } = this.props
    const { values, updating } = this.state

    return (
      <FormWrapper
        values={values}
        company={company}
        show={show}
        companyChanged={this.companyChanged()}
        updating={updating}
        onChange={onChange}
        onCancel={onCancel}
        onSubmit={updating ? handleUpdate : handleCreate}
        beneficiaryOptions={employees}
      />
    )
  }
}

class _FormWrapper extends Component {
  resetValidations = () => this.props.$fieldEvent('reset')

  handleCancel = () => {
    this.props.onCancel()
    this.resetValidations()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.$submit(this.props.onSubmit(this.resetValidations))
  }

  isDisabled = (field) => {
    const { name } = field
    const {
      values: { isActive, selfBilling },
      companyChanged,
      updating,
    } = this.props

    // new company has to be active
    if (name === 'isActive') {
      return !updating
    }

    if (name === 'comment') {
      return !companyChanged
    }

    if (name === 'invoicedService') {
      return !(selfBilling === 'true')
    }

    if (name !== 'employeeIds') {
      return isActive === statuses.inactive.key
    }

    return false
  }

  modalFooter = (action) => {
    const { updating, companyChanged } = this.props

    return (
      <ButtonToolbar className="pull-right">
        <Button onClick={this.handleCancel} data-test="closeButton">
          Cancel
        </Button>
        <Button
          disabled={updating && !companyChanged}
          type="submit"
          form="companyForm"
          bsStyle="primary"
        >
          {action}
        </Button>
      </ButtonToolbar>
    )
  }

  valueOrEmptyString = (value) =>
    isNumber(value) || isString(value) || moment.isMoment(value) ? value : ''

  getTitleAndAction = () => {
    const { company, updating } = this.props
    const name = company.legalName
    const result = {}
    if (!updating) {
      result.title = 'New Company'
      result.action = 'Create'
    } else {
      result.title = `Update Company ${name}`
      result.action = 'Update'
    }

    return result
  }

  render() {
    const {
      onChange,
      show,
      $field,
      $validation,
      values,
      beneficiaryOptions,
      updating,
    } = this.props
    const { handleSubmit, handleCancel } = this

    const { title, action } = this.getTitleAndAction()

    return (
      <SimpleModal
        show={show}
        onCancel={handleCancel}
        title={title}
        footer={this.modalFooter(action)}
      >
        <Form onSubmit={handleSubmit} id="companyForm" horizontal>
          {companyFields.map((field, index) => {
            if (!updating && field.hideOnCreate) {
              return null
            }

            const options =
              field.name === 'employeeIds' ? beneficiaryOptions : field.options
            const tooltip =
              field.name === 'companyId'
                ? "If company doesn't have an ID, use '@@[jiraID]'"
                : ''
            return (
              <Field
                {...field}
                key={index}
                labelLength={4}
                valueLength={8}
                disabled={this.isDisabled(field)}
                options={options}
                value={this.valueOrEmptyString(values[field.name])}
                tooltip={tooltip}
                validationState={$validation[field.name]}
                required={
                  field.required
                    ? field.required(values)
                    : !!$validation[field.name]
                }
                {...$field(field.name, onChange)}
              />
            )
          })}
        </Form>
      </SimpleModal>
    )
  }
}

const validationConfig = (props) => {
  const { values, company, updating } = props

  const fields = []
  const validations = {}

  companyFields
    .filter((field) => field.validation && !(!updating && field.hideOnCreate))
    .forEach((field) => {
      fields.push(field.name)
      validations[field.name] = field.validation(
        { ...values },
        company[field.name],
      )
    })

  return {
    fields,
    validations,
  }
}

const FormWrapper = validated(validationConfig)(_FormWrapper)
