import React from 'react'
import { Row, Col, ButtonToolbar, Button, Modal } from 'react-bootstrap'

export default ({ onCancel, show }) => (
  <Modal show={show} onHide={onCancel}>
    <Modal.Header>
      <h4>Warning!</h4>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Col xs={12}>
          <p>Your session has expired. Please re-login.</p>
        </Col>
      </Row>
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar className="pull-right">
        <Button onClick={onCancel}>Back</Button>
        <Button
          bsStyle="primary"
          type="submit"
          onClick={() => {
            window.open(window.location.href, '_blank')
          }}
        >
          Re-Login
        </Button>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
)
