import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup, Label, Row } from 'react-bootstrap'
import enhanceWithClickOutside from 'react-click-outside'
import classnames from 'classnames'
import { dateFormatHuman } from '../utils'
import styles from './ColumnDropdown.module.scss'

import moment from 'moment'
import DateTime from 'react-datetime'

import 'rc-slider/assets/index.css?global'
import 'rc-tooltip/assets/bootstrap.css?global'

class _ColumnDateRangeDropdown extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    minDateValue: PropTypes.instanceOf(Date),
    maxDateValue: PropTypes.instanceOf(Date),
    currentMinDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    currentMaxDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    onReset: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  state = {
    showOptions: false,
  }

  handleClickInside = () => {
    this.setState({ showOptions: !this.state.showOptions })
  }

  handleClickOutside() {
    this.setState({ showOptions: false })
  }

  handleClick = (event) => {
    event.stopPropagation()
  }

  handleMinDatePickerChange = (dateValue) => {
    if (
      moment.isMoment(dateValue) &&
      dateValue.format(dateFormatHuman) !== this.props.currentMinDate
    ) {
      if (dateValue > moment(this.props.currentMaxDate)) {
        this.props.onChange({
          currentMinDate: this.props.currentMaxDate,
          currentMaxDate: dateValue.toDate(),
          name: this.props.name,
        })
      } else {
        this.props.onChange({
          currentMinDate: dateValue.toDate(),
          currentMaxDate: this.props.currentMaxDate,
          name: this.props.name,
        })
      }
    }
  }

  handleMaxDatePickerChange = (dateValue) => {
    if (
      moment.isMoment(dateValue) &&
      dateValue.format(dateFormatHuman) !== this.props.currentMaxDate
    ) {
      if (dateValue < moment(this.props.currentMinDate)) {
        this.props.onChange({
          currentMinDate: dateValue.toDate(),
          currentMaxDate: this.props.currentMinDate,
          name: this.props.name,
        })
      } else {
        this.props.onChange({
          currentMinDate: this.props.currentMinDate,
          currentMaxDate: dateValue.toDate(),
          name: this.props.name,
        })
      }
    }
  }

  setDateRangeLastYear = () => {
    const minDate = moment(new Date())
      .add(-1, 'years')
      .add(1, 'days')
      .startOf('day')
    const maxDate = moment(new Date()).startOf('day')
    if (
      minDate.format(dateFormatHuman) !== this.props.currentMinDate ||
      maxDate.format(dateFormatHuman) !== this.props.currentMaxDate
    ) {
      this.props.onChange({
        currentMinDate: minDate.format(dateFormatHuman),
        currentMaxDate: maxDate.format(dateFormatHuman),
        name: this.props.name,
      })
    }
  }

  setDateRangeThisCalendarYear = () => {
    const minDate = moment(new Date()).startOf('year')
    const maxDate = moment(new Date()).startOf('day')
    if (
      minDate.format(dateFormatHuman) !== this.props.currentMinDate ||
      maxDate.format(dateFormatHuman) !== this.props.currentMaxDate
    ) {
      this.props.onChange({
        currentMinDate: minDate.format(dateFormatHuman),
        currentMaxDate: maxDate.format(dateFormatHuman),
        name: this.props.name,
      })
    }
  }

  resetFilter = () => {
    this.setState({ showOptions: false })
    this.props.onReset(this.props.name)
  }

  render() {
    const { label, currentMinDate, currentMaxDate, className, changed } =
      this.props
    const { showOptions } = this.state
    const openDatePicker = true

    if (currentMaxDate === undefined || currentMinDate === undefined) {
      return null
    }

    return (
      <>
        <div>
          {showOptions && (
            <div
              onClick={this.handleClick}
              className={styles.dropdownContentWrapper}
            >
              <Form className={styles.dropdownContentDateRange}>
                <Row className={styles.pickerRow}>
                  <FormGroup className={styles.picker}>
                    <Label className={styles.label}>From:</Label>
                    <DateTime
                      name="minDate"
                      dateFormat={dateFormatHuman}
                      timeFormat={false}
                      open={openDatePicker}
                      value={currentMinDate}
                      onChange={this.handleMinDatePickerChange}
                    />
                  </FormGroup>
                  <FormGroup className={styles.picker}>
                    <Label className={styles.label}>To:</Label>
                    <DateTime
                      name="maxDate"
                      dateFormat={dateFormatHuman}
                      timeFormat={false}
                      open={openDatePicker}
                      value={currentMaxDate}
                      onChange={this.handleMaxDatePickerChange}
                    />
                  </FormGroup>
                </Row>
                <Row className={styles.buttonRow}>
                  <Button
                    className={styles.button}
                    onClick={this.setDateRangeLastYear}
                    bsSize="small"
                  >
                    The year ending today
                  </Button>
                  <Button
                    className={styles.button}
                    onClick={this.setDateRangeThisCalendarYear}
                    bsSize="small"
                  >
                    Current calendar year
                  </Button>
                  <Button
                    className={classnames([styles.button, styles.buttonRight])}
                    onClick={this.resetFilter}
                    bsSize="small"
                  >
                    Reset
                  </Button>
                </Row>
              </Form>
            </div>
          )}
        </div>
        <div
          className={classnames([
            className,
            styles.dropdown,
            changed && styles.selected,
          ])}
          onClick={this.handleClickInside}
        >
          <div className={styles.clickableLabelDateRange}>{label}</div>
        </div>
      </>
    )
  }
}

const ColumnDateRangeDropdown = enhanceWithClickOutside(
  _ColumnDateRangeDropdown,
)

export default ColumnDateRangeDropdown
