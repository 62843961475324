import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ButtonToolbar, Button, Modal } from 'react-bootstrap'
import styles from './SimpleModal.module.scss'

export default class SimpleModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    large: PropTypes.bool,
    title: PropTypes.string,
    titleAddon: PropTypes.node,
    className: PropTypes.string,
    classNameBody: PropTypes.string,
  }

  render() {
    const {
      show,
      onCancel,
      large,
      title,
      titleAddon,
      children,
      className,
      classNameBody,
      footer,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={onCancel}
        bsSize={large ? 'large' : null}
        className={className}
      >
        {title && (
          <Modal.Header>
            <Modal.Title className={styles.title} data-test="modalTitle">
              {title}
              {titleAddon}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={classNameBody}>{children}</Modal.Body>
        <Modal.Footer>
          {footer || (
            <ButtonToolbar className="pull-right">
              <Button onClick={onCancel} data-test="closeButton">
                Close
              </Button>
            </ButtonToolbar>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}
