import React from 'react'
import Office from './Office'
import styles from './Office.module.scss'
import { EmployeeSummaryBySomething, OfficeResponseType } from './types.common'

export default (props: { offices: OfficeResponseType | null }): JSX.Element => {
  const { offices } = props
  if (!offices) {
    return <></>
  }

  const { total, byOffice, byCountry } = offices
  const toComponents = ({
    label,
    employees,
    change,
  }: EmployeeSummaryBySomething) => (
    <Office key={label} label={label} employees={employees} change={change} />
  )

  return (
    <>
      <div key="total">
        <Office
          label={total.label}
          employees={total.employees}
          change={total.change}
        />
      </div>
      <div className={styles.officeDataWrapper} key="byCountry">
        {byCountry.map(toComponents)}
      </div>
      <div className={styles.officeDataWrapper} key="byOffice">
        {byOffice.map(toComponents)}
      </div>
    </>
  )
}
