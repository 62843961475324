import React, { Component } from 'react'
import {
  Grid,
  Row,
  Col,
  Button,
  FormControl,
  InputGroup,
  Glyphicon,
  ButtonGroup,
} from 'react-bootstrap'
import ScrollArea from 'react-scrollbar'
import qs from 'qs'
import { remove as removeDiacritics } from 'diacritics'
import classnames from 'classnames'
import moment from 'moment'
import { get, pickBy, has } from 'lodash'
import update from 'immutability-helper'
import { statuses, multiFilters } from './common/enums'
import {
  dateFormat,
  monthFormat,
  getFullNameReversed,
  apiRequest,
  handleUncaughtError,
  getConfig,
  exportCompaniesOptions,
  generateCompaniesXLSX,
  getFiltersFromUrl,
  filtersToUrl,
} from './utils'
import {
  companyFields,
  companyFieldsInfo,
  companyFieldsDetails,
  companyFieldsExportable,
} from './companyFields'
import Loading from './components/Loading'
import TableTitle from './components/TableTitle'
import ColumnDropdown from './components/ColumnDropdown'
import CompanyEditor, {
  shownEditorState,
  hiddenEditorState,
} from './CompanyEditor'
import CompanyTimelineWrapper from './CompanyTimelineWrapper'
import Company from './Company'
import NotificationContext from 'utils/context/NotificationContext'
import styles from './Companies.module.scss'

const getCompanyFields = (showDetails) => {
  if (showDetails === 'details') {
    return companyFieldsDetails
  } else {
    return companyFieldsInfo
  }
}

export default class Companies extends Component {
  static contextType = NotificationContext

  constructor(props) {
    super(props)

    const { employeeId, contractId } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    })

    let initState = {
      active: true,
      search: '',
      filters: {},
      filteredCompanies: [],
      timeline: {
        show: false,
      },
      showDetails: 'companies',
    }

    if (employeeId && contractId) {
      props.history.replace(props.location.pathname)

      initState = {
        ...initState,
        employeeId: Number(employeeId),
        contractId: Number(contractId),
        ...shownEditorState,
      }
    } else {
      initState = {
        ...initState,
        ...hiddenEditorState,
      }
    }

    this.state = initState
  }

  componentDidMount() {
    this.getEmployees()

    this.updateFiltersAndTimelines()

    this.polling = window.setInterval(() => {
      this.getCompanies()
      this.getEmployees()
    }, getConfig().polling)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.updateFiltersAndTimelines()
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.polling)
  }

  updateUrl = () => {
    const { search, filters, active, timeline, showDetails } = this.state
    let timelineUrl = null
    if (timeline.companyId) {
      timelineUrl = { timelineFor: timeline.companyId }
    } else if (timeline.show) {
      timelineUrl = {
        monthlyTimeline: true,
        timelineMonth: timeline.month
          ? timeline.month.format(monthFormat)
          : null,
        editedBy: timeline.editedBy,
        forCompany: timeline.forCompany,
      }
    }

    const queryParams = {
      inactive: !active ? 1 : 0,
      details: showDetails,
      search,
      ...filtersToUrl(filters),
      ...timelineUrl,
    }

    const query = pickBy(queryParams, (prop) => prop)

    const queryString = qs.stringify(query, { addQueryPrefix: true })
    if (queryString !== this.props.location.search) {
      this.props.history.push(`${this.props.location.pathname}${queryString}`)
    }
  }

  updateFiltersAndTimelines() {
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })

    const showTimeline = has(query, 'timelineFor')
    const showMonthlyTimeline = has(query, 'monthlyTimeline')

    let timelineCompanyId = null,
      timelineMonth = null,
      editedBy = null,
      forCompany = null

    if (showTimeline) {
      timelineCompanyId = Number(query.timelineFor)
    }
    if (has(query, 'timelineMonth')) {
      timelineMonth = moment(query.timelineMonth, monthFormat)
    }
    if (has(query, 'editedBy')) {
      editedBy = Number(query.editedBy)
    }

    if (has(query, 'forCompany')) {
      forCompany = Number(query.forCompany)
    }

    const state = {
      active: !query.inactive,
      search: query.search || '',
      showDetails: query.details || 'companies',
      filters: getFiltersFromUrl(query, companyFields),
      timeline: {
        show: showTimeline || showMonthlyTimeline,
        companyId: timelineCompanyId,
        month: timelineMonth,
        editedBy,
        forCompany,
      },
    }

    let callback

    if (this.state.companies == null || state.active !== this.state.active) {
      callback = this.getCompanies
    } else {
      callback = this.filterCompanies
    }

    this.setState(state, callback)
  }

  getCompanies = () => {
    const { active } = this.state
    const options = {
      query: { active },
    }

    apiRequest('companies', options)
      .then((response) => {
        this.setState({ companies: response }, this.filterCompanies)
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  getEmployees = () => {
    apiRequest('employees/identifiers')
      .then((response) => {
        const employees = response.map((employee) => ({
          value: employee.id,
          label: getFullNameReversed(employee),
        }))
        this.setState({ employees })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  getCompanyNameById = async (companyId) => {
    const { companies } = this.state

    if (!companies || companyId == null) {
      return null
    }

    const company = companies.find(({ id }) => id === companyId)
    if (!company) {
      try {
        return await apiRequest(`companies/${companyId}/name`)
      } catch (err) {
        handleUncaughtError(err, this.context.addNotification)
        return null
      }
    } else {
      return company.legalName
    }
  }

  reloadEditorData = () => {
    const { id } = this.state.editor.company
    const options = {}

    apiRequest(`companies/${id}`, options)
      .then((response) => {
        const { editor } = this.state
        const company = { ...editor.company, ...response }
        this.setState({ editor: { ...editor, company } })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  filterCompanies = () => {
    const { companies, employees, search, filters } = this.state
    const formattedSearch = removeDiacritics(search).toLowerCase()
    // filters search bar
    let results = formattedSearch
      ? companies.filter(
          (c) =>
            removeDiacritics(c.legalName)
              .toLowerCase()
              .includes(formattedSearch) ||
            c.employeeIds.some((employeeId) =>
              removeDiacritics(
                employees.find((e) => e.value === employeeId).label,
              )
                .toLowerCase()
                .includes(formattedSearch),
            ),
        )
      : companies

    // filters select filters (VAT Payer, Random Salary, Desk Rental, Self Billing, Billing To)
    Object.entries(filters).forEach(([field, filter]) => {
      if (!filter.length) return

      results = results.filter(
        // filters use strings for all values so boolean values in company
        // have to be converted to strings in comparison
        (company) => filter.includes(String(company[field])),
      )
    })

    const { filteredCompanies } = this.state
    if (
      filteredCompanies &&
      filteredCompanies.length !== results.length &&
      this.scrollArea
    ) {
      this.scrollArea.scrollTop()
    }

    this.setState({ filteredCompanies: results })
  }

  toggleActive = () => {
    const stateChange = { active: !this.state.active }
    this.setState(stateChange, () => {
      this.getCompanies()
      this.updateUrl()
    })
  }

  handleTimelineFilter = (month, editedBy, forCompany) => {
    this.setState(
      (state) =>
        update(state, {
          timeline: { $merge: { month, editedBy, forCompany } },
        }),
      this.updateUrl,
    )
  }

  handleSearch = ({ target: { value } }) => {
    this.setState({ search: value }, () => {
      this.filterCompanies()
      this.updateUrl()
    })
  }

  clearSearch = () => {
    this.setState({ search: '' }, () => {
      this.filterCompanies()
      this.updateUrl()
    })
  }

  showTimeline = (company = null) => {
    this.setState(
      {
        timeline: {
          show: true,
          companyId: get(company, 'id', null),
          month: null,
          editedBy: null,
          forCompany: null,
        },
      },
      this.updateUrl,
    )
  }

  hideTimeline = () => {
    this.setState(
      {
        timeline: {
          show: false,
          companyId: null,
          month: null,
          editedBy: null,
          forCompany: null,
        },
      },
      this.updateUrl,
    )
  }

  showEditor = () => {
    this.setState(shownEditorState)
  }

  showPrefilledEditor = (company) => {
    this.setState({ editor: { show: true, company, updating: true } })
  }

  hideEditor = () => {
    this.setState(hiddenEditorState)
  }

  handleCompanySave = () => {
    this.hideEditor()
    this.getCompanies()
  }

  generateExport = () => {
    const { filteredCompanies, date } = this.state
    const dateFormatted = moment.utc(date).format(dateFormat)
    const fields = {
      companyFields: companyFieldsExportable,
    }
    generateCompaniesXLSX(filteredCompanies, dateFormatted, fields)
  }

  setScrollAreaRef = (ref) => {
    if (ref) {
      this.scrollArea = ref.scrollArea
    }
  }

  setShowDetails = (details) => {
    const stateChange = { showDetails: details }
    this.setState(stateChange, this.updateUrl)
  }

  handleFilter = ({ name, value }) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => {
        this.filterCompanies()
        this.updateUrl()
      },
    )
  }

  renderCompanyTable() {
    const { filteredCompanies, employees, showDetails } = this.state
    const companyFields = getCompanyFields(showDetails)

    return (
      <Col xs={12}>
        <div className={styles.companies}>
          <div className={styles.companiesHeader}>
            <div className={styles.companiesRow}>
              {companyFields.map(({ name, label, options, filter }) => {
                const dropdownStyles = classnames([
                  styles.companiesHeaderCell,
                  styles[`box-${name}`],
                ])
                if (filter) {
                  const value = this.state.filters[name]

                  return (
                    <ColumnDropdown
                      label={label}
                      name={name}
                      key={name}
                      value={value}
                      options={options}
                      filterType={filter}
                      className={dropdownStyles}
                      onClick={this.handleFilter}
                    />
                  )
                }
                return (
                  <div key={name} className={dropdownStyles}>
                    {label}
                  </div>
                )
              })}
              <ColumnDropdown
                download
                name="download"
                className={classnames([
                  styles.companiesHeaderCell,
                  styles.companiesHeaderButtons,
                ])}
                options={exportCompaniesOptions}
                filterType={multiFilters.simple}
                onClick={this.generateExport}
              />
            </div>
          </div>
          <ScrollArea
            className={styles.companiesBody}
            horizontal={false}
            ref={this.setScrollAreaRef}
            verticalContainerClassNameVertical="vertical"
          >
            {filteredCompanies.map((company, index) => (
              <Company
                key={company.id}
                rowNumber={index + 1}
                company={company}
                employees={employees}
                companyFields={companyFields}
                showTimeline={this.showTimeline}
                showPrefilledEditor={this.showPrefilledEditor}
              />
            ))}
          </ScrollArea>
        </div>
      </Col>
    )
  }

  render() {
    const {
      companies,
      search,
      active,
      editor,
      timeline,
      employees,
      employeeId,
      contractId,
      showDetails,
    } = this.state

    if (!companies || !employees) {
      return <Loading />
    }

    return (
      <Grid>
        <TableTitle name="Companies" onClick={this.showEditor} />
        <Row className={styles.filters}>
          <Col sm={4} md={4} lg={3}>
            <InputGroup>
              <FormControl
                type="text"
                className={search && styles.highlighted}
                value={search !== undefined ? search : ''}
                placeholder="Search by Name"
                onChange={this.handleSearch}
                onKeyUp={(e) => e.key === 'Escape' && this.clearSearch()}
              />
              <InputGroup.Button>
                <Button
                  title="Clear"
                  disabled={!search}
                  className={search && styles.highlighted}
                  onClick={this.clearSearch}
                >
                  <Glyphicon glyph="remove" />
                  &zwnj;
                </Button>
              </InputGroup.Button>
            </InputGroup>
          </Col>
          <Col xs={3} sm={1} className={styles.xsMarginTop}>
            <Button
              className={!active && styles.highlighted}
              onClick={this.toggleActive}
            >
              {active ? statuses.active.label : statuses.inactive.label}
            </Button>
          </Col>
          <Col xs={5} sm={4} md={3} className={styles.colRight}>
            <ButtonGroup>
              <Button
                className={showDetails !== 'details' && styles.highlighted}
                onClick={() => this.setShowDetails('companies')}
                data-test="companiesButton"
              >
                Companies
              </Button>
              <Button
                className={showDetails === 'details' && styles.highlighted}
                onClick={() => this.setShowDetails('details')}
                data-test="detailsButton"
              >
                Details
              </Button>
            </ButtonGroup>
          </Col>
          <Col
            lg={2}
            lgPush={3}
            md={3}
            mdPush={1}
            sm={3}
            smPush={0}
            xs={4}
            className={styles.colRight}
          >
            <Button onClick={this.showTimeline} data-test="openTimeline">
              Monthly Timeline
            </Button>
          </Col>
        </Row>
        <Row>
          {this.renderCompanyTable()}
          <CompanyEditor
            show={editor.show}
            updating={editor.updating}
            company={editor.company}
            reloadEditorData={this.reloadEditorData}
            onCancel={this.hideEditor}
            onSave={this.handleCompanySave}
            employees={employees}
            employeeId={employeeId}
            contractId={contractId}
          />
          <CompanyTimelineWrapper
            show={timeline.show}
            month={timeline.month}
            companyId={timeline.companyId}
            editedBy={timeline.editedBy}
            forCompany={timeline.forCompany}
            getCompanyNameById={this.getCompanyNameById}
            handleTimelineFilter={this.handleTimelineFilter}
            onCancel={this.hideTimeline}
            employees={employees}
          />
        </Row>
      </Grid>
    )
  }
}
