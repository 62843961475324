import React, { FC, useContext, useState } from 'react'
import SimpleModal from './SimpleModal'
import { Button, ButtonToolbar, FormControl } from 'react-bootstrap'
import { apiRequest, handleUncaughtError, dateFormat } from 'utils'
import NotificationContext from 'utils/context/NotificationContext'
import _, { get } from 'lodash'
import moment from 'moment'
import XLSX from 'xlsx-js-style'
import { saveAs } from 'file-saver'
import { employeeFields, employeeFieldsExportable } from '../employeeFields'
import { companyFieldsExportable } from '../companyFields'
import JSZip from 'jszip'

const HistoricalData = ({ show, onCancel, vlCompanies }) => {
  const [selectedVlCompany, setVlCompany] = useState(
    Object.values(vlCompanies)[0].key,
  )
  const { addNotification } = useContext(NotificationContext)

  const getHistoricalChanges = async () => {
    const options = {
      query: { vlCompany: selectedVlCompany },
    }

    try {
      return await apiRequest('employees/historical-data', options)
    } catch (e) {
      handleUncaughtError(e, addNotification)
      return null
    }
  }

  const getAllEmployees = async () => {
    try {
      const allEmployees = await apiRequest('employees')
      return allEmployees
    } catch (e) {
      handleUncaughtError(e, addNotification)
      return null
    }
  }

  const getEmployeeInitialData = async (jiraId, allEmployees) => {
    try {
      const employeeId = allEmployees.find((e) => e.jiraId === jiraId).id
      return await apiRequest(`employees/${employeeId}`)
    } catch (e) {
      handleUncaughtError(e, addNotification)
      return null
    }
  }

  const getCompanyLegalName = async (companyId) => {
    try {
      const company = await apiRequest(`companies/${companyId}`)
      return company?.legalName
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return null
    }
  }

  const createSheet = async (entity, entityChanges, exportableFields) => {
    const rows = [getHeader(exportableFields)]
    for (const changes of entityChanges) {
      applyChangesToEmployee(entity, changes.changes)
      const row = generateRow(entity, changes.changes, exportableFields)
      const metaData = await getChangeMetadata(changes)
      rows.push([...metaData, ...row])
    }

    return XLSX.utils.aoa_to_sheet(rows)
  }

  const toSheetName = (name) => name.substring(0, 31)

  const createXLSX = async (employeeJiraId, employeeData, allEmployees) => {
    const wb = XLSX.utils.book_new()

    const employeeCompanies = {}
    const sheets = []
    const employee = await getEmployeeInitialData(employeeJiraId, allEmployees)

    for (const date of Object.keys(employeeData).sort()) {
      const contractChanges = employeeData[date]
      for (const changes of contractChanges) {
        if (changes.changes?.companyId?.to != null) {
          const companyId = changes.changes.companyId.to
          const companyName = await getCompanyLegalName(companyId)
          if (companyName != null) {
            employeeCompanies[companyId] = companyName
            changes.changes.companyId.to = companyName
          }
        }
      }

      const companyIdField = employeeFields.filter(
        ({ name }) => name === 'companyId',
      )
      const employeeExportableFields = [
        ...employeeFieldsExportable,
        ...companyIdField,
      ]
      const sheet = await createSheet(
        employee,
        contractChanges,
        employeeExportableFields,
      )
      if (sheet === null) {
        return null
      }
      sheets.unshift([date, sheet])
    }

    for (const [date, sheet] of sheets.reverse()) {
      XLSX.utils.book_append_sheet(wb, sheet, toSheetName(date))
    }

    for (const [companyId, companyName] of Object.entries(employeeCompanies)) {
      const companyChanges = await apiRequest(`companies/${companyId}/timeline`)
      const sortedCompanyChanges = companyChanges.sort((a, b) =>
        moment(a.createdAt).diff(moment(b.createdAt)),
      )

      const sheet = await createSheet(
        {},
        sortedCompanyChanges,
        companyFieldsExportable,
      )
      if (sheet === null) {
        return null
      }
      XLSX.utils.book_append_sheet(wb, sheet, toSheetName(companyName))
    }

    return XLSX.write(wb, { type: 'binary' })
  }

  const getChangeMetadata = async (change) => {
    const { createdBy, createdAt, comment } = change
    let editorName = 'vacuum'
    if (createdBy !== null) {
      const editor = await apiRequest(`employees/${createdBy}`)
      editorName = `${editor.firstName} ${editor.lastName}`
    }
    const createdAtDate = moment(createdAt).format(dateFormat)
    return [{ v: editorName }, { v: createdAtDate }, { v: comment || '' }]
  }

  const applyChangesToEmployee = (employee, changes) => {
    Object.entries(changes).forEach(([field, change]) => {
      employee[field] = change.to
    })
  }

  const getHeader = (employeeExportableFields) => {
    const header = [{ v: 'Editor' }, { v: 'Date' }, { v: 'Comment' }]
    return [
      ...header,
      ...employeeExportableFields.map((field) => ({ v: field.label })),
    ]
  }

  const generateRow = (employee, employeeChanges, employeeExportableFields) => {
    const row = []
    employeeExportableFields.forEach((field) => {
      if (field.name in employeeChanges) {
        row.push({
          v: resolveValue(employeeChanges[field.name].to, field),
          s: { fill: { fgColor: { rgb: 'ffff00' } } },
        })
      } else {
        row.push({ v: resolveValue(employee[field.name], field) || '' })
      }
    })
    return row
  }

  const resolveValue = (fieldValue, field) => {
    const { resolve, exportValue, options, label, units, name } = field
    if (fieldValue == null) return ''

    if (resolve) {
      try {
        return resolve({ [field.name]: fieldValue }, { exportable: true })
      } catch {
        // eslint-disable-next-line no-console
        console.warn(`Cannot resolve value '${fieldValue}' of field ${name}`)
        return fieldValue
      }
    }

    if (exportValue) {
      return exportValue({ [name]: fieldValue })
    }

    if (units) {
      return `${fieldValue} ${units({ [name]: fieldValue })}`
    }

    if (options) {
      return get(options, [fieldValue, 'label'], fieldValue)
    }

    return fieldValue
  }

  const downloadArchive = async () => {
    const changes = await getHistoricalChanges()
    if (changes === null) {
      return
    }

    const allEmployees = await getAllEmployees()
    if (allEmployees === null) {
      return
    }

    const groupedChanges = _.groupBy(changes, 'jiraId')
    const groupedChangesByValidFrom = _.mapValues(groupedChanges, (changes) =>
      _.groupBy(changes, (change) =>
        moment.utc(change.validFrom).format(dateFormat),
      ),
    )
    const sortedChanges = _.mapValues(groupedChangesByValidFrom, (changes) =>
      _.mapValues(changes, (changes) =>
        _.sortBy(changes, 'createdAt').map((change) => change),
      ),
    )

    const zip = new JSZip()

    for (const [jiraId, employeeData] of Object.entries(sortedChanges)) {
      const xlsx = await createXLSX(jiraId, employeeData, allEmployees)
      if (xlsx === null) {
        return
      }
      zip.file(`${jiraId}.xlsx`, xlsx, { binary: true })
    }

    zip
      .generateAsync({
        type: 'blob',
        compression: 'DEFLATE',
        compressionOptions: {
          level: 9,
        },
      })
      .then((content) => {
        saveAs(content, `${selectedVlCompany}.zip`)
      })
  }

  const downloadButtonAction = async (event) => {
    const target = event.target
    target.innerText = 'Creating the archive...'
    await downloadArchive()
    target.innerText = 'Download archive'
  }

  const renderOption = ({ key, label }) =>
    key && (
      <option key={key} value={key}>
        {label}
      </option>
    )

  const close = () => {
    onCancel()
  }

  return (
    <SimpleModal
      title="Historical data"
      show={show}
      onCancel={onCancel}
      footer={
        <ButtonToolbar className="pull-right">
          <Button onClick={downloadButtonAction}>Download archive</Button>
          <Button onClick={close}>Cancel</Button>
        </ButtonToolbar>
      }
      large
    >
      <h3>Select the company</h3>
      <FormControl
        componentClass="select"
        onChange={(e) => setVlCompany(e.target.value)}
      >
        {Object.values(vlCompanies).map(renderOption)}
      </FormControl>
    </SimpleModal>
  )
}

export default HistoricalData
