import 'core-js/stable'
import 'regenerator-runtime/runtime'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App'
import { apiRequest } from './utils'

const app = document.getElementById('root')

const history = createBrowserHistory()

const options = {
  cache: 'no-cache',
}
apiRequest('config', options)
  .then((jsonData: Record<string, any>) => {
    window.config = jsonData
    ReactDOM.render(
      <Router history={history}>
        <App />
      </Router>,
      app,
    )
  })
  // eslint-disable-next-line no-console
  .catch((err: string) => console.error(err))
