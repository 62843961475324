import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SimpleModal from './components/SimpleModal'
import CompanyEvents from './components/CompanyEvents'
import CompanyFilters from './components/CompanyFilters'
import styles from './Timeline.module.scss'

export default class CompanyTimeline extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    companyId: PropTypes.number,
    month: PropTypes.object,
    editedBy: PropTypes.number,
    forCompany: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    handleTimelineFilter: PropTypes.func.isRequired,
  }

  render() {
    const {
      show,
      onCancel,
      companyId,
      employees,
      editedBy,
      forCompany,
      handleTimelineFilter,
      month,
      events,
      companies,
      companyName,
      date,
    } = this.props
    const title =
      companyId == null ? 'Monthly Timeline' : `${companyName}'s Timeline`

    return (
      <SimpleModal
        show={show}
        onCancel={onCancel}
        title={title}
        titleAddon={
          <CompanyFilters
            employees={employees}
            companyId={companyId}
            editedBy={editedBy}
            forCompany={forCompany}
            companies={companies}
            events={events}
            date={date}
            handleTimelineFilter={handleTimelineFilter}
            month={month}
          />
        }
        className={styles.modal}
        classNameBody={styles.modalBody}
      >
        <CompanyEvents
          employees={employees}
          editedBy={editedBy}
          forCompany={forCompany}
          events={events}
          companies={companies}
          companyId={companyId}
        />
      </SimpleModal>
    )
  }
}
