import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  FormControl,
  ControlLabel,
  Form,
  FormGroup,
  Col,
  Row,
} from 'react-bootstrap'
import enhanceWithClickOutside from 'react-click-outside'
import classnames from 'classnames'
import { isFloat } from '../utils'
import styles from './ColumnDropdown.module.scss'

import Slider, { createSliderWithTooltip } from 'rc-slider'

import 'rc-slider/assets/index.css?global'
import 'rc-tooltip/assets/bootstrap.css?global'

const Range = createSliderWithTooltip(Slider.Range)

class _ColumnRangeDropdown extends Component {
  state = { showOptions: false }
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    currentMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onReset: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleClickInside = () => {
    this.setState({ showOptions: !this.state.showOptions })
  }

  handleClickOutside() {
    this.setState({ showOptions: false })
  }

  handleClick = (event) => {
    event.stopPropagation()
  }

  handleSliderChange = (values) => {
    this.props.onChange({
      currentMin: values[0],
      currentMax: values[1],
      name: this.props.name,
    })
  }

  handleInputChange = (e) => {
    const { name } = e.target
    let { value } = e.target

    let ignoreUpdate = false

    if (value !== '') {
      if (!isFloat(value)) {
        return
      }
      // do not update when there is nothing after decimal dot
      if (value.slice(-1) === '.') {
        ignoreUpdate = true
      }
      value = ignoreUpdate ? value : parseFloat(e.target.value)
    } else {
      ignoreUpdate = true
    }
    let { currentMin, currentMax } = this.props
    if (name === 'min') {
      currentMin = value
    } else {
      currentMax = value
    }
    this.props.onChange({
      currentMin,
      currentMax,
      name: this.props.name,
      ignoreUpdate,
    })
  }

  resetFilter = () => {
    this.setState({ showOptions: false })
    this.props.onReset(this.props.name)
  }

  render() {
    const {
      label,
      minValue,
      maxValue,
      currentMin,
      currentMax,
      className,
      changed,
    } = this.props
    const { showOptions } = this.state

    if (currentMax === undefined || currentMin === undefined) {
      return null
    }

    const currentMinRange =
      typeof currentMin === 'string' ? minValue : currentMin
    const currentMaxRange =
      typeof currentMax === 'string' ? maxValue : currentMax

    return (
      <>
        <div>
          {showOptions && (
            <div
              onClick={this.handleClick}
              className={styles.dropdownContentWrapper}
            >
              <Form horizontal className={styles.dropdownContentWide}>
                <FormGroup className={styles.rangeWrapper} controlId="min">
                  <Col
                    componentClass={ControlLabel}
                    sm={2}
                    className={styles.rangeText}
                  >
                    Min:
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      className={styles.rangeInput}
                      type="text"
                      name="min"
                      value={Math.floor(currentMin)}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className={styles.rangeWrapper} controlId="max">
                  <Col
                    componentClass={ControlLabel}
                    sm={2}
                    className={styles.rangeText}
                  >
                    Max:
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      className={styles.rangeInput}
                      type="text"
                      name="max"
                      value={Math.ceil(currentMax)}
                      onChange={this.handleInputChange}
                    />
                  </Col>
                </FormGroup>
                <Row>
                  <Col sm={12}>
                    <Range
                      min={Math.floor(minValue)}
                      max={Math.ceil(maxValue)}
                      value={[
                        Math.floor(currentMinRange),
                        Math.ceil(currentMaxRange),
                      ]}
                      onChange={this.handleSliderChange}
                      className={styles.rangePicker}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Button onClick={this.resetFilter} bsSize="small">
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </div>
        <div
          className={classnames([
            className,
            styles.dropdown,
            changed && styles.selected,
          ])}
          onClick={this.handleClickInside}
        >
          <div className={styles.clickableLabelRange}>{label}</div>
        </div>
      </>
    )
  }
}

const ColumnRangeDropdown = enhanceWithClickOutside(_ColumnRangeDropdown)

export default ColumnRangeDropdown
