import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Col, Button, ButtonToolbar } from 'react-bootstrap'
import { validated } from 'react-custom-validation'
import moment from 'moment'
import Field from './components/Field'
import SimpleModal from './components/SimpleModal'
import {
  dateFormat,
  apiRequest,
  handleUncaughtError,
  pushNotification,
  notifications,
} from './utils'
import NotificationContext from 'utils/context/NotificationContext'
import { shareFieldsForEditor as shareFields } from './shareFields'
import styles from './ShareEditor.module.scss'

export default class ShareValueEditor extends Component {
  static contextType = NotificationContext

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    shareValues: PropTypes.array.isRequired,
  }

  constructor() {
    super()
    this.state = this.initState()
  }

  initState = () => {
    const validFrom = moment.utc().startOf('month')
    return {
      values: {
        validFrom,
        value: '',
      },
    }
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ values: { ...this.state.values, [name]: value } })
  }

  onSubmit = (resetValidations) => {
    const { validFrom, value } = this.state.values
    const options = {
      method: 'POST',
      body: {
        validFrom: validFrom.format(dateFormat),
        shareValue: Number(value),
      },
    }

    apiRequest('shareValue', options)
      .then((response) => {
        pushNotification(notifications.shareAdded, this.context.addNotification)
        this.props.onChange()
        this.setState(this.initState())
        resetValidations()
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  render() {
    const { onChange, onSubmit } = this
    const { show, onCancel, shareValues } = this.props
    const { values } = this.state

    return (
      <FormWrapper
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
        show={show}
        values={values}
        shareValues={shareValues}
      />
    )
  }
}

class _FormWrapper extends Component {
  resetValidations = () => this.props.$fieldEvent('reset')

  handleCancel = () => {
    this.props.onCancel()
    this.resetValidations()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.$submit(() => {
      this.props.onSubmit(this.resetValidations)
    })
  }

  modalFooter = () => (
    <ButtonToolbar className="pull-right">
      <Button onClick={this.handleCancel} data-test="closeButton">
        Cancel
      </Button>
      <Button type="submit" form="shareForm" bsStyle="primary">
        Create
      </Button>
    </ButtonToolbar>
  )

  render() {
    const { onChange, show, values, $field, $validation } = this.props
    const { handleSubmit, handleCancel } = this

    return (
      <SimpleModal
        show={show}
        onCancel={handleCancel}
        title="New Value of One Share"
        footer={this.modalFooter()}
      >
        <Form
          onSubmit={handleSubmit}
          id="shareForm"
          className={styles.shareForm}
        >
          {shareFields.map((field, index, units) => (
            <Col
              xs={6}
              key={index}
              className={field.name === 'validFrom' ? styles.formValidFrom : ''}
            >
              <Field
                {...field}
                key={index}
                value={values[field.name]}
                units={field.units && field.units(values)}
                validationState={$validation[field.name]}
                required={
                  field.required
                    ? field.required(values)
                    : !!$validation[field.name]
                }
                {...$field(field.name, onChange)}
              />
            </Col>
          ))}
        </Form>
      </SimpleModal>
    )
  }
}

const FormWrapper = validated(validationConfig)(_FormWrapper)

function validationConfig(props) {
  const { shareValues, values } = props

  const fields = []
  const validations = {}

  shareFields
    .filter((field) => field.validation)
    .forEach((field) => {
      fields.push(field.name)
      validations[field.name] = field.validation({ ...values, shareValues })
    })

  return {
    fields,
    validations,
  }
}
