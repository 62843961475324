import React, { Component } from 'react'
import {
  FormGroup,
  ControlLabel,
  FormControl,
  HelpBlock,
  Col,
  InputGroup,
  Glyphicon,
} from 'react-bootstrap'
import DateTime from 'react-datetime'
import Select from 'react-select'
import { get, isString } from 'lodash'
import {
  compareOptionsLexicographicallyByLabel,
  dateFormatHuman,
  monthFormatHuman,
  emptyValue,
  compareOptionsByValue,
} from '../utils'
import styles from './Field.module.scss'
import ControlLabelWrapper from './ControlLabelWrapper'

const FieldFormatter = ({ children, size, isLabel }) => {
  if (!size) {
    return <div>{children}</div>
  }
  return (
    <Col xs={size} className={isLabel && styles.formLabel}>
      {children}
    </Col>
  )
}

export default class Field extends Component {
  disableWheelAndArrowKeyEvent = (input) => {
    if (input) {
      // prevent changing of value of active inputs on mouse scroll
      input.addEventListener('wheel', (e) => {
        if (document.activeElement === e.target) {
          e.preventDefault()
        }
      })

      input.addEventListener('keydown', (e) => {
        if (
          document.activeElement === e.target &&
          (e.key === 'ArrowUp' || e.key === 'ArrowDown')
        ) {
          e.preventDefault()
        }
      })
    }
  }

  handleDateChange = (onlyMonths) => (date) => {
    const { name, onChange } = this.props
    onChange({
      target: {
        name,
        value: onlyMonths ? date.date(1) : date,
      },
    })
  }

  handleSelectChange = (value) => {
    const { name, onChange } = this.props
    onChange({
      target: {
        name,
        value,
      },
    })
  }

  renderFormField() {
    const {
      name,
      options,
      formControl,
      value,
      onChange,
      disabled,
      defaultOption,
      isNullable,
      sortByValue,
    } = this.props
    const isSelectOrNumber =
      get(formControl, 'componentClass') === 'select' ||
      get(formControl, 'type') === 'number'

    if (formControl && formControl.type === 'date') {
      return (
        <DateTime
          name={name}
          value={value}
          onChange={this.handleDateChange(formControl.onlyMonths)}
          dateFormat={
            formControl.onlyMonths ? monthFormatHuman : dateFormatHuman
          }
          viewMode={formControl.onlyMonths ? 'months' : 'days'}
          timeFormat={false}
          utc
          inputProps={{
            'data-test': name,
            disabled,
          }}
          closeOnSelect
        />
      )
    } else if (formControl && formControl.multiple) {
      return (
        <Select
          name={name}
          data-test={name}
          value={value}
          options={options}
          onChange={this.handleSelectChange}
          multi
          simpleValue
        />
      )
    } else if (formControl && formControl.creatable) {
      // value is string if created and number if selected from options
      return (
        <Select.Creatable
          name={name}
          value={isString(value) ? { value, label: value } : value}
          options={options}
          onChange={this.handleSelectChange}
          simpleValue
        />
      )
    } else {
      return (
        <FormControl
          {...formControl}
          name={name}
          data-test={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          inputRef={isSelectOrNumber ? this.disableWheelAndArrowKeyEvent : null}
        >
          {options && [
            defaultOption ? (
              <option key={defaultOption.key} value={defaultOption.key}>
                {defaultOption.label}
              </option>
            ) : (
              <option
                key=""
                disabled={!isNullable}
                className={isNullable || styles.placeholder}
                value=""
              >
                {emptyValue}
              </option>
            ),
            ...Object.values(options)
              .sort(
                sortByValue
                  ? compareOptionsByValue
                  : compareOptionsLexicographicallyByLabel,
              )
              .map(({ key, label, inactive }) => (
                <option
                  key={key}
                  value={key}
                  className={inactive === true ? styles.inactive : ''}
                >
                  {label}
                </option>
              )),
          ]}
        </FormControl>
      )
    }
  }

  render() {
    const {
      name,
      formControl,
      hideLabel,
      validationState,
      units,
      valueLength,
      labelLength,
      link,
      required,
      tooltip,
    } = this.props
    const {
      isValid,
      show,
      error: { reason },
    } = validationState || { error: {} }
    const showError = !isValid && show
    const label =
      formControl && formControl.label
        ? formControl.label
        : this.props.label || get(formControl, 'type') === 'number'

    let formField = this.renderFormField()
    if (link != null) {
      formField = (
        <InputGroup>
          {formField}
          <InputGroup.Addon>{link}</InputGroup.Addon>
        </InputGroup>
      )
    }

    return (
      <FormGroup controlId={name} validationState={showError ? 'error' : null}>
        {!hideLabel && (
          <FieldFormatter size={labelLength} isLabel>
            <ControlLabelWrapper tooltip={tooltip} name={name}>
              <ControlLabel>
                {label}
                {units && <span>&nbsp;({units})</span>}
                {tooltip && (
                  <span>
                    &nbsp;
                    <Glyphicon glyph="question-sign" size=".7em" />
                  </span>
                )}
                {required && (
                  <span className={styles.requiredAsterisk}>&nbsp;*</span>
                )}
              </ControlLabel>
            </ControlLabelWrapper>
          </FieldFormatter>
        )}
        <FieldFormatter size={valueLength} link={link}>
          {formField}
          {showError && <HelpBlock>{reason}</HelpBlock>}
        </FieldFormatter>
      </FormGroup>
    )
  }
}
