import {
  resolveDateField,
  resolveMoneyField,
  validateRequired,
  validatePositiveFloat,
  validateShareDateUnique,
} from './utils'

const fields = [
  {
    name: 'rowNumber',
    label: '#',
    displayField: true,
    editField: false,
  },
  {
    name: 'validFrom',
    label: 'Valid From',
    resolve: resolveDateField('validFrom'),
    formControl: {
      type: 'date',
      onlyMonths: true,
    },
    validation: ({ validFrom, shareValues }) => [
      [validateRequired, validFrom],
      [validateShareDateUnique, validFrom, shareValues],
    ],
    displayField: true,
    editField: true,
  },
  {
    name: 'validTo',
    label: 'Valid To',
    resolve: resolveDateField('validTo'),
    displayField: true,
    editField: false,
  },
  {
    name: 'value',
    label: 'Share Value',
    resolve: resolveMoneyField('value'),
    units: () => '€',
    formControl: {
      type: 'number',
      step: '0.01',
    },
    validation: ({ value }) => [
      [validateRequired, value],
      [validatePositiveFloat, value],
    ],
    displayField: true,
    editField: true,
  },
]

export const shareFieldsForTable = fields.filter(
  ({ displayField }) => displayField === true,
)

export const shareFieldsForEditor = fields.filter(
  ({ editField }) => editField === true,
)
