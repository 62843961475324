import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getFullName, apiRequest, handleUncaughtError } from '../utils'
import NotificationContext from 'utils/context/NotificationContext'
import logo from '../assets/default-avatar.png'
import styles from './Avatar.module.scss'

export default class Avatar extends Component {
  state = {
    url: '',
    name: '',
  }

  static contextType = NotificationContext

  static propTypes = {
    employeeId: PropTypes.number,
  }

  componentDidMount() {
    this.getAvatarAndName()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.employeeId !== this.props.employeeId) {
      this.getAvatarAndName()
    }
  }

  getAvatarAndName = () => {
    const { employeeId } = this.props

    if (employeeId != null) {
      apiRequest(`employees/${employeeId}/avatar`)
        .then((response) => {
          this.setState({
            name: getFullName(response.employee),
            url: response.avatarUrl,
          })
        })
        .catch((err) => handleUncaughtError(err, this.context.addNotification))
    } else {
      this.setState({ name: 'vacuum', url: logo }, this.getAvatar)
    }
  }

  render() {
    const { url, name } = this.state

    return (
      <span>
        <img
          src={url}
          width="20"
          className={styles.avatar}
          alt={unescape(name)}
        />
        <strong>{name}</strong>
      </span>
    )
  }
}
