import { generateKeys } from '../common/enums'

export const notifications = generateKeys({
  employeeCreated: {
    text: 'Employee was created',
    type: 'success',
  },
  employeeUpdated: {
    text: 'Employee details were updated',
    type: 'success',
  },
  contractDeleted: {
    text: 'Contract was deleted',
    type: 'success',
  },
  companyCreated: {
    text: 'Company was created',
    type: 'success',
  },
  companyUpdated: {
    text: 'Company details were updated',
    type: 'success',
  },
  manualSharesAdded: {
    text: 'Manual shares were added',
    type: 'success',
  },
  shareAdded: {
    text: 'Share value was added',
    type: 'success',
  },
  shareDeleted: {
    text: 'Share value was deleted',
    type: 'success',
  },
  draftSaved: {
    text: 'Draft was saved',
    type: 'success',
  },
  draftDeleted: {
    text: 'Draft was deleted',
    type: 'success',
  },
  networkError: {
    text: 'There was an unexpected error in the application',
    type: 'error',
    single: true,
  },
  draftLoadError: {
    text: 'Draft is obsolete - following fields have to be updated: ',
    type: 'error',
  },
  invalidFile: {
    text: 'The uploaded file is invalid',
    type: 'error',
  },
  bulkUpdateDone: {
    text: 'Bulk update performed successfully',
    type: 'success',
  },
})

export const pushNotification = (notification, addNotification, extraText) => {
  const { text, type, single, key } = notification
  addNotification({
    message: text + (extraText || ''),
    level: type,
    uid: single ? key : null,
  })
}
