import React from 'react'
import { FormGroup, InputGroup, Button } from 'react-bootstrap'
import DateTime from 'react-datetime'
import moment from 'moment'
import { dateFormatHuman } from '../utils'
import styles from './DateTimeToday.module.scss'
import classnames from 'classnames'

export default ({ onChange, className, ...props }) => (
  <FormGroup>
    <InputGroup>
      <DateTime
        {...props}
        className={classnames([className, styles.picker])}
        onChange={onChange}
        dateFormat={dateFormatHuman}
      />
      <InputGroup.Button>
        <Button className={className} onClick={() => onChange(moment.utc())}>
          Today
        </Button>
      </InputGroup.Button>
    </InputGroup>
  </FormGroup>
)
