import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styles from '../Timeline.module.scss'
import Loading from './Loading'
import TimelineEvent from './CompanyTimelineEvent'

export default (props) => {
  const { employees, editedBy, forCompany, events, companies, companyId } =
    props

  if (!events) {
    return <Loading />
  }

  let filteredEvents = events
  if (editedBy) {
    filteredEvents = filteredEvents.filter((event) =>
      editedBy === -1 ? event.createdBy === null : event.createdBy === editedBy,
    )
  }
  if (forCompany) {
    filteredEvents = filteredEvents.filter(
      (event) => event.companyId === forCompany,
    )
  }

  if (filteredEvents.length > 0) {
    return filteredEvents.map((e, i) => (
      <TimelineEvent
        key={i}
        event={e}
        initialEvent={e.comment === null}
        employees={employees}
        companies={companies}
        monthlyTimeline={companyId === null}
      />
    ))
  } else {
    return (
      <Row>
        <Col xs={12} className="text-center">
          <h4 className={styles.noEntry}>There are no changes.</h4>
        </Col>
      </Row>
    )
  }
}
