import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Table,
  Checkbox,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import _, { pick, pull, get } from 'lodash'
import update from 'immutability-helper'
import moment from 'moment'
import {
  contractFields,
  employeeFieldNames,
  employeeFieldsEditable,
  employmentDetailsFields,
  companyDetailsFields,
} from './employeeFields'
import ConfirmDialog from './components/ConfirmDialog'
import ToggleValues from './components/ToggleValues'
import { dateFormatHuman, getFutureContracts } from './utils'
import { legalForms } from './common/enums'
import styles from './ContractConflictEditor.module.scss'

const getPresentationValue = (name, contract, mapper) => {
  const field = employeeFieldsEditable.find((field) => name === field.name)
  const { render, resolve, options } = field

  let presentationValue = null
  if (render) {
    presentationValue = render(contract)
  } else if (resolve) {
    presentationValue = resolve(contract)
  } else if (options) {
    presentationValue = get(options, [contract[name], 'label'], contract[name])
  } else if (mapper[name] && contract[name]) {
    presentationValue = mapper[name][contract[name]]
  } else {
    presentationValue = contract[name] || ''
  }
  return presentationValue
}

export const getConflicts = (
  changes,
  employee,
  contractDoesNotExist,
  validFromOnly,
  mapper,
) => {
  const conflictChanges = {
    dates: [],
    fields: [],
    ids: [],
    relevantContracts: [],
    blockingFields: [],
    changedFields: [],
    needManualResolution: false,
  }
  const fieldsToChange = {}

  if (!employee.nextContract.validFrom || validFromOnly) {
    return Promise.resolve({ conflictChanges, fieldsToChange })
  }

  return getFutureContracts(employee.id, changes.validFrom).then(
    (futureContracts) => {
      const originalContract = contractDoesNotExist
        ? employee.nextContract
        : employee
      const newContract = { ...originalContract, ...changes }
      const { isEmployment } = legalForms[newContract.legalForm]
      const legalFormFields = isEmployment
        ? employmentDetailsFields
        : companyDetailsFields
      const relevantFields = pull(
        contractFields.concat(legalFormFields).map(({ name }) => name),
        'validFrom',
        'fteEquivalent',
      )
      const relevantChanges = pick(changes, relevantFields)
      const changedFields = Object.keys(relevantChanges)

      const needManualResolution = ([newValue, oldValue]) =>
        oldValue != null && oldValue !== newValue
      const changeByDefault = ([newValue, oldValue]) => oldValue == null
      const isChange = ([newValue, oldValue]) => oldValue !== newValue

      for (const contract of futureContracts) {
        const diffs = _.mapValues(relevantChanges, (v, k) => [v, contract[k]])
        contract.blockingFields = _.keys(_.pickBy(diffs, isChange))
        fieldsToChange[contract.id] = _.keys(_.pickBy(diffs, changeByDefault))
        if (_.some(diffs, needManualResolution))
          conflictChanges.needManualResolution = true
        contract.relevant = contract.blockingFields.length > 0
      }

      conflictChanges.changedFields = changedFields

      // for all relevant contract fields collect all future values
      relevantFields.forEach((name, i) => {
        const { label } = employeeFieldNames[name]
        const presentationValue = getPresentationValue(
          name,
          newContract,
          mapper,
        )

        conflictChanges.fields[i] = {
          futureValues: [],
          futurePresentationValues: [],
          label,
        }
        conflictChanges.fields[i].newValue = newContract[name]
        conflictChanges.fields[i].presentationValue = presentationValue
        conflictChanges.fields[i].name = name

        futureContracts.forEach((futureContract) => {
          const presentationValue = getPresentationValue(
            name,
            futureContract,
            mapper,
          )

          conflictChanges.fields[i].futureValues.push(futureContract[name])
          conflictChanges.fields[i].futurePresentationValues.push(
            presentationValue,
          )

          if (i === 0) {
            const validFrom = moment(futureContract.validFrom).format(
              dateFormatHuman,
            )
            conflictChanges.dates.push(validFrom)
            conflictChanges.ids.push(futureContract.id)
            conflictChanges.relevantContracts.push(futureContract.relevant)
            conflictChanges.blockingFields.push(futureContract.blockingFields)
          }
        })
      })

      // remove fields that do not conflict with any future contract
      conflictChanges.fields = conflictChanges.fields.filter((values, index) =>
        conflictChanges.fields[index].futureValues.some((futureValue) => {
          const { newValue, name } = conflictChanges.fields[index]
          const parse = employeeFieldNames[name].parse ?? _.identity
          let value
          try {
            // parse may throw exception if the value does not exist in the map of options
            value = parse(newValue)
          } catch {
            value = newValue
          }
          return isChange([value, parse(futureValue)])
        }),
      )
      return { conflictChanges, fieldsToChange }
    },
  )
}

export class ContractConflictEditor extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    validFrom: PropTypes.object.isRequired,
    closeConfirmDialog: PropTypes.func.isRequired,
    handleUpdateConfirm: PropTypes.func.isRequired,
    conflictChanges: PropTypes.object.isRequired,
    fieldsToChange: PropTypes.object.isRequired,
    deleteContracts: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      fieldsToChange: props.fieldsToChange,
      deleteContracts: props.deleteContracts ?? {},
    }
  }

  handleToggleValuesChange = ({ contractId, name }, value) => {
    this.setState((state) =>
      update(state, {
        fieldsToChange: {
          [contractId]: {
            $apply: (fields = []) => {
              if (value) {
                if (!fields.includes(name)) {
                  fields = [...fields, name]
                }
              } else {
                fields = fields.filter((field) => field !== name)
              }
              return fields
            },
          },
        },
      }),
    )
  }

  toggleContractForDeletion = (contractId) => {
    this.setState((state) =>
      update(state, {
        deleteContracts: {
          $apply: (contracts) => {
            contracts = { ...contracts }
            if (contracts[contractId]) {
              delete contracts[contractId]
            } else {
              contracts[contractId] = true
            }
            return contracts
          },
        },
      }),
    )
  }

  renderNonBlockingContractField = (data, units, i, type) => {
    let tooltipText = ''
    let className = ''
    switch (type) {
      case 'newValue':
        tooltipText =
          'This value will be inserted in all future contracts without conflict.'
        className = styles.newValue
        break
      case 'irrelevantValue':
        tooltipText = 'Values in this contract match your changes.'
        className = styles.irrelevantValue
        break
      default:
    }

    const tooltip = <Tooltip id={`irrelevantValue-${i}`}>{tooltipText}</Tooltip>

    return (
      <td key={i} className={className}>
        <OverlayTrigger key={i} placement="bottom" overlay={tooltip}>
          <span>
            {data}
            {units && ` ${units}`}
          </span>
        </OverlayTrigger>
      </td>
    )
  }

  renderContractField = (data, units, i) => (
    <td key={i}>
      <span>
        {data}
        {units && ` ${units}`}
      </span>
    </td>
  )

  renderBlockingField = (data, newValue, units, i, contractId, name) => {
    const fields = get(this.state, ['fieldsToChange', contractId], [])

    return (
      <td key={i}>
        <ToggleValues
          name={{ contractId, name }}
          oldValue={data}
          newValue={newValue}
          units={units}
          value={fields.includes(name)}
          onChange={this.handleToggleValuesChange}
        />
      </td>
    )
  }

  renderConfirmDialogBody = (validFrom, conflicts, deleteContracts) => {
    const newChangesDate = moment.utc(validFrom).format(dateFormatHuman)
    const {
      renderContractField,
      renderNonBlockingContractField,
      renderBlockingField,
    } = this

    return (
      <Row>
        <Col xs={12}>
          <p>
            These <span className={styles.newValue}>green</span> values from
            New/Updated contract are in conflict with next Existing contract(s).
            Please choose if you want to keep old values in Existing contract,
            update it with new values or delete whole contract.
          </p>
          <Table className={styles.table} responsive>
            <thead>
              <tr>
                <th />
                <th>New contract</th>
                {conflicts.dates.map((c, i) => (
                  <th key={i}>Existing</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Valid From</strong>
                </td>
                <td>{newChangesDate}</td>
                {conflicts.dates.map((c, i) =>
                  conflicts.relevantContracts[i]
                    ? renderContractField(c, null, i)
                    : renderNonBlockingContractField(
                        c,
                        null,
                        i,
                        'irrelevantValue',
                      ),
                )}
              </tr>
              {conflicts.fields.map((values, i) => {
                const field = employeeFieldNames[values.name]
                const units = field.units
                  ? field.units(values.presentationValue)
                  : null
                return (
                  <tr key={i}>
                    <td>
                      <strong>{values.label}</strong>
                    </td>
                    {conflicts.changedFields.includes(values.name)
                      ? renderNonBlockingContractField(
                          values.presentationValue,
                          units,
                          i,
                          'newValue',
                        )
                      : renderContractField(values.presentationValue, units, i)}
                    {values.futurePresentationValues.map((f, i) =>
                      !conflicts.relevantContracts[i]
                        ? renderNonBlockingContractField(
                            f,
                            units,
                            i,
                            'irrelevantValue',
                          )
                        : conflicts.blockingFields[i].includes(values.name)
                        ? renderBlockingField(
                            f,
                            values.presentationValue,
                            units,
                            i,
                            conflicts.ids[i],
                            values.name,
                          )
                        : renderContractField(f, units, i),
                    )}
                  </tr>
                )
              })}
              <tr className={styles.deleteConfirm}>
                <td colSpan={2}>
                  <strong>Do you want to delete existing contract?</strong>
                </td>
                {conflicts.ids.map((contractId, i) => (
                  <td key={i}>
                    {conflicts.relevantContracts[i] && (
                      <Checkbox
                        className={styles.checkbox}
                        checked={!!deleteContracts[contractId]}
                        onChange={() =>
                          this.toggleContractForDeletion(contractId)
                        }
                      >
                        <strong>Delete</strong>
                      </Checkbox>
                    )}
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      show,
      validFrom,
      closeConfirmDialog,
      handleUpdateConfirm,
      conflictChanges,
    } = this.props
    const { fieldsToChange, deleteContracts } = this.state

    return (
      <ConfirmDialog
        onCancel={closeConfirmDialog}
        onConfirm={(e) => handleUpdateConfirm(fieldsToChange, deleteContracts)}
        body={this.renderConfirmDialogBody(
          validFrom,
          conflictChanges,
          deleteContracts,
        )}
        show={show}
        large
      />
    )
  }
}
