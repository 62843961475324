export const dateFormat = 'YYYY-MM-DD' // internal storage
export const dateFormatHuman = 'D. MMM YYYY'
export const monthFormat = 'YYYY-MM'
export const monthFormatHuman = 'MMM YYYY'

export const emptyValue = '\u2014\u2014\u2014' // 3x em dash

export const compareOptionsLexicographicallyByLabel = (a, b) =>
  a.label.localeCompare(b.label)

export const compareOptionsByValue = (a, b) => a.value - b.value

export const stopPropagation = (e) => e.stopPropagation()
