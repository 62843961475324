import xlsx from 'xlsx'
import { saveAs } from 'file-saver'

export const separator = ','

export function createXLSX(rows, name, datePart) {
  const worksheet = xlsx.utils.aoa_to_sheet(rows)
  const workbook = {
    SheetNames: [name],
    Sheets: {
      [name]: worksheet,
    },
  }
  const options = { bookType: 'xlsx', type: 'binary' }
  const workbookData = xlsx.write(workbook, options)
  const fileName = `${name}-${datePart}.xlsx`

  saveAs(
    new Blob([saveWorkbookToBuffer(workbookData)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
    fileName,
  )
}

function saveWorkbookToBuffer(seq) {
  const buf = new ArrayBuffer(seq.length)
  const view = new Uint8Array(buf)
  const mask = 0xff // get only last 8 bits

  for (let i = 0; i !== seq.length; ++i) {
    view[i] = seq.charCodeAt(i) & mask // eslint-disable-line no-bitwise
  }
  return buf
}
