import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

export default (props) => {
  const { tooltip, name, children } = props

  return tooltip ? (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={name}>{tooltip}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  )
}
