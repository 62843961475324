import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import ScrollArea from 'react-scrollbar'
import classnames from 'classnames'
import moment from 'moment'
import { shareFieldsForTable as shareFields } from './shareFields'
import {
  apiRequest,
  handleUncaughtError,
  getConfig,
  pushNotification,
  notifications,
} from './utils'
import NotificationContext from 'utils/context/NotificationContext'
import Loading from './components/Loading'
import TableTitle from './components/TableTitle'
import ShareEditor from './ShareEditor'
import Share from './Share'
import styles from './Shares.module.scss'

export default class Shares extends Component {
  state = {
    showEditor: false,
  }

  static contextType = NotificationContext

  componentDidMount() {
    this.getShareValues()
    this.polling = window.setInterval(this.getShareValues, getConfig().polling)
  }

  componentWillUnmount() {
    window.clearInterval(this.polling)
  }

  getShareValues = () => {
    apiRequest('shareValues')
      .then((response) => {
        let validTo = null
        const shares = response.map((share) => {
          share.validTo = validTo
          validTo = moment.utc(share.validFrom).subtract(1, 'day')
          return share
        })
        this.setState({ shares })
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  deleteShareValue = (id) => {
    const options = {
      method: 'DELETE',
    }

    apiRequest(`shareValue/${id}`, options)
      .then((response) => {
        this.getShareValues()
        pushNotification(
          notifications.shareDeleted,
          this.context.addNotification,
        )
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  showEditor = () => {
    this.setState({ showEditor: true })
  }

  hideEditor = () => {
    this.setState({ showEditor: false })
  }

  renderShareTable = () => (
    <Col xs={12}>
      <div className={styles.shares}>
        <div className={styles.sharesHeader}>
          <div className={styles.sharesRow}>
            {shareFields.map(({ name, label }) => (
              <div
                key={name}
                className={classnames([
                  styles.sharesHeaderCell,
                  styles[`box-${name}`],
                ])}
              >
                {label}
              </div>
            ))}
            <div
              className={classnames([
                styles.sharesHeaderCell,
                styles.sharesHeaderButtons,
              ])}
            />
          </div>
        </div>
        <ScrollArea
          className={styles.sharesBody}
          horizontal={false}
          verticalContainerClassNameVertical="vertical"
        >
          {this.state.shares.map((share, index) => (
            <Share
              key={share.id}
              rowNumber={index + 1}
              share={share}
              deleteShareValue={this.deleteShareValue}
            />
          ))}
        </ScrollArea>
      </div>
    </Col>
  )

  render() {
    const { showEditor, shares } = this.state

    if (!shares) {
      return <Loading />
    }

    return (
      <Grid>
        <TableTitle name="Shares" onClick={this.showEditor} />
        <Row>
          {this.renderShareTable()}
          <ShareEditor
            show={showEditor}
            onCancel={this.hideEditor}
            onChange={() => {
              this.getShareValues()
              this.hideEditor()
            }}
            shareValues={shares}
          />
        </Row>
      </Grid>
    )
  }
}
