import React from 'react'
import styles from './Office.module.scss'
import { EmployeeSummaryBySomething } from './types.common'

export default (props: EmployeeSummaryBySomething) => {
  const { label, employees } = props
  let { change } = props
  let changeStyle = styles.noChange
  if (change > 0) {
    change = `+${change}`
    changeStyle = styles.positiveChange
  } else if (change < 0) {
    changeStyle = styles.negativeChange
  }

  return (
    <div className={styles.officeData}>
      <strong>{label}</strong>: {employees}{' '}
      <span className={changeStyle}>({change})</span>
    </div>
  )
}
