import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import styles from './ToggleValues.module.scss'

export default class ToggleValues extends Component {
  static propTypes = {
    name: PropTypes.object.isRequired,
    oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    units: PropTypes.string,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  onChange = (value) => {
    this.props.onChange(this.props.name, value)
  }

  render() {
    const { oldValue, newValue, units, value } = this.props
    const unitsText = units !== null ? ` ${units}` : null

    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="newValue">
            Upper value ({oldValue}
            {unitsText}) is old, lower value ({newValue}
            {unitsText}) is new.
          </Tooltip>
        }
      >
        <ToggleButtonGroup
          type="radio"
          value={value}
          onChange={this.onChange}
          vertical
          className={styles.toggle}
          name="chooseValue"
        >
          <ToggleButton className={styles.bold} value={false}>
            {oldValue}
            {unitsText}
          </ToggleButton>
          <ToggleButton className={styles.bold} value>
            {newValue}
            {unitsText}
          </ToggleButton>
        </ToggleButtonGroup>
      </OverlayTrigger>
    )
  }
}
