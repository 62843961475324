import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo.svg'
import styles from './Logo.module.scss'

export default () => (
  <Link className={styles.logo} to="/">
    <img className={styles.image} src={logo} alt="VacuumLabs" />
  </Link>
)
