import React, { Component } from 'react'
import { uniq } from 'lodash'
import moment from 'moment'
import { Form, FormGroup, FormControl } from 'react-bootstrap'
import DateTime from 'react-datetime'
import classnames from 'classnames'
import {
  monthFormatHuman,
  compareOptionsLexicographicallyByLabel,
} from '../utils'
import styles from '../Timeline.module.scss'

const renderOption = ({ key, label }) =>
  key && (
    <option key={key} value={key}>
      {label}
    </option>
  )

export default class CompanyFilters extends Component {
  changeTimelineMonth = (month) => {
    if (moment.isMoment(month)) {
      const { handleTimelineFilter, editedBy, forCompany } = this.props
      handleTimelineFilter(month, editedBy, forCompany)
    }
  }

  changeTimelineEditor = (e) => {
    const { value } = e.target
    const { handleTimelineFilter, month, forCompany } = this.props
    handleTimelineFilter(month, Number(value), forCompany)
  }

  changeTimelineCompany = (e) => {
    const { value } = e.target
    const { handleTimelineFilter, month, editedBy } = this.props
    handleTimelineFilter(month, editedBy, Number(value))
  }

  render() {
    const {
      employees,
      companyId,
      editedBy,
      forCompany,
      companies,
      events = [],
      date,
    } = this.props

    if (!date || companyId != null || companies == null) {
      return null
    }

    const editedByList = uniq(events.map(({ createdBy }) => createdBy))
    const companyIdList = uniq(events.map(({ companyId }) => companyId))
    if (editedBy && !editedByList.includes(editedBy)) {
      if (editedBy === -1) {
        const vacuumId = null
        if (!editedByList.includes(vacuumId)) {
          editedByList.push(vacuumId)
        }
      } else {
        editedByList.push(editedBy)
      }
    }
    if (forCompany && !companyIdList.includes(forCompany)) {
      companyIdList.push(forCompany)
    }

    const editedByOptions = editedByList
      .map((employeeId) => {
        const employee = employees.find(
          (employee) => employee.value === employeeId,
        )
        if (employee) {
          return {
            key: employee.value,
            label: employee.label,
          }
        } else {
          return {
            key: -1,
            label: 'vacuum',
          }
        }
      })
      .sort(compareOptionsLexicographicallyByLabel)

    const companyIdOptions = companyIdList
      .map((companyId) => {
        const company = companies[companyId]
        if (company) {
          return {
            key: companyId,
            label: company.legalName,
          }
        } else {
          return {
            key: null,
            label: '',
          }
        }
      })
      .sort(compareOptionsLexicographicallyByLabel)

    return (
      <Form inline className={styles.filterForm}>
        <FormGroup controlId="formMonth">
          <DateTime
            name="dateTimeline"
            value={date}
            onChange={this.changeTimelineMonth}
            dateFormat={monthFormatHuman}
            viewMode="months"
            closeOnSelect
            inputProps={{
              className: classnames(['form-control', styles.dateInput]),
            }}
          />
        </FormGroup>
        &nbsp;
        <FormControl
          componentClass="select"
          onChange={this.changeTimelineEditor}
          value={editedBy || ''}
        >
          <option value="0">All Editors</option>
          {editedByOptions.map(renderOption)}
        </FormControl>
        &nbsp;
        <FormControl
          componentClass="select"
          onChange={this.changeTimelineCompany}
          value={forCompany || ''}
        >
          <option value="0">All Companies</option>
          {companyIdOptions.map(renderOption)}
        </FormControl>
      </Form>
    )
  }
}
