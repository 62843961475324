import React, { Component } from 'react'
import { Button, Row, Col, Alert } from 'react-bootstrap'
import { getConfig, sessionExpired, urls } from '../utils'
import Logo from './Logo'
import styles from './Login.module.scss'

class Login extends Component {
  constructor(props) {
    super(props)
    let expired = false
    if (localStorage[sessionExpired]) {
      expired = true
      localStorage.removeItem(sessionExpired)
    }
    this.state = {
      expired,
    }
  }

  render() {
    const {
      location: { pathname, search },
    } = this.props
    const { expired } = this.state
    const { error } = getConfig()
    const pathnameWithoutTrailingSlash =
      pathname[0] === '/' ? pathname.substr(1) : pathname
    const redirect = encodeURI(`${pathnameWithoutTrailingSlash}${search}`)

    return (
      <div className={styles.wrapper}>
        {expired && (
          <Row>
            <Col xs={12}>
              <Alert bsStyle="danger">
                <strong>Your session has expired!</strong> Please log in.
              </Alert>
            </Col>
          </Row>
        )}
        <Row className={styles.logo}>
          <Col xs={12} className={styles.col}>
            <Logo />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <a
              href={`${urls.serverUrl}auth/login?redirect=${urls.clientUrl}${redirect}`}
            >
              <Button bsStyle="success">Login</Button>
            </a>
            {error && <pre className={styles.error}>{unescape(error)}</pre>}
          </Col>
        </Row>
      </div>
    )
  }
}

export default Login
