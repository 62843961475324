import React from 'react'
import Notification from 'types/Notification'

const NotificationContext = React.createContext({
  addNotification: (notification: Notification) => {
    // eslint-disable-next-line no-console
    console.log(
      `Provider is not defined. Can't print notification: ${notification}`,
    )
  },
})

export default NotificationContext
