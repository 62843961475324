import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Form, Col, Button } from 'react-bootstrap'
import { validated } from 'react-custom-validation'
import moment from 'moment'
import classNames from 'classnames'
import Field from './components/Field'
import SimpleModal from './components/SimpleModal'
import {
  monthFormatHuman,
  getFullName,
  validateDate,
  validateNonZeroInteger,
  apiRequest,
  handleUncaughtError,
  pushNotification,
  notifications,
} from './utils'
import NotificationContext from 'utils/context/NotificationContext'
import styles from './ManualShareEditor.module.scss'

export default class ManualShareEditor extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    date: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    employeeId: PropTypes.number,
    getEmployeeById: PropTypes.func.isRequired,
  }

  static contextType = NotificationContext

  constructor(props) {
    super(props)
    this.state = this.initState()
  }

  componentDidMount() {
    this.update()
  }

  componentDidUpdate(prevProps) {
    const reset =
      !prevProps.show && prevProps.employeeId !== this.props.employeeId
    this.update(reset)
  }

  update = (reset) => {
    const { show, employeeId, getEmployeeById } = this.props

    if (reset) {
      this.setState({ employee: null })
    }

    if (show) {
      getEmployeeById(employeeId).then((employee) =>
        this.setState({ employee }),
      )
    }
  }

  initState = () => ({
    values: {
      validFrom: this.props.date,
      shareNumber: '',
    },
  })

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ values: { ...this.state.values, [name]: value } })
  }

  onSubmit = (resetValidations) => {
    const { validFrom, shareNumber } = this.state.values
    const { employeeId } = this.props
    const options = {
      method: 'POST',
      body: {
        validFrom,
        shareNumber,
      },
    }
    apiRequest(`employees/${employeeId}/manualShares`, options)
      .then((response) => {
        pushNotification(
          notifications.manualSharesAdded,
          this.context.addNotification,
        )
        this.props.onChange()
        this.setState(this.initState())
        resetValidations()
      })
      .catch((err) => handleUncaughtError(err, this.context.addNotification))
  }

  render() {
    const { show, date, onCancel } = this.props
    const { values, employee } = this.state
    const { shareNumber, manualShares, manualSharesHistory } = employee || {}
    const { onChange, onSubmit } = this
    const employeeName = employee ? getFullName(employee) : ''

    return (
      <FormWrapper
        onCancel={onCancel}
        show={show && employee != null}
        date={date}
        values={values}
        onChange={onChange}
        employeeName={employeeName}
        shareNumbers={manualSharesHistory || []}
        onSubmit={onSubmit}
        salaryShares={shareNumber}
        manualShares={manualShares}
      />
    )
  }
}

class _FormWrapper extends Component {
  resetValidations = () => this.props.$fieldEvent('reset')

  handleCancel = () => {
    this.props.onCancel()
    this.resetValidations()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.$submit(() => {
      this.props.onSubmit(this.resetValidations)
    })
  }

  render() {
    const {
      show,
      date,
      values: { validFrom, shareNumber },
      $field,
      $validation,
      onChange,
      employeeName,
      shareNumbers,
      salaryShares,
      manualShares,
    } = this.props
    const { handleCancel, handleSubmit } = this
    const title = `${employeeName}'s Shares`

    return (
      <SimpleModal show={show} onCancel={handleCancel} title={title}>
        <Form onSubmit={handleSubmit}>
          <Col xs={5} className={styles.formDate}>
            <Field
              label="Date"
              name="validFrom"
              value={validFrom}
              formControl={{ type: 'date', onlyMonths: true }}
              validationState={$validation.validFrom}
              {...$field('validFrom', onChange)}
            />
          </Col>
          <Col xs={5}>
            <Field
              label="Shares"
              name="shareNumber"
              value={shareNumber}
              formControl={{ type: 'number' }}
              validationState={$validation.shareNumber}
              {...$field('shareNumber', onChange)}
            />
          </Col>
          <Col xs={2} className={styles.formButton}>
            <Button type="submit" bsStyle="primary">
              Create
            </Button>
          </Col>
        </Form>
        <Table className={styles.table}>
          <thead>
            <tr className={styles.money}>
              <th>Manual Shares</th>
              <th>Salary Shares</th>
              <th>Total Shares</th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.money}>
              <td>{manualShares}</td>
              <td>{salaryShares}</td>
              <td>{manualShares + salaryShares}</td>
            </tr>
          </tbody>
        </Table>
        <Table striped className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th className={styles.money}>Shares</th>
            </tr>
          </thead>
          <tbody>
            {shareNumbers.map(({ validFrom, shareNumber, id }) => {
              const shareDate = moment.utc(validFrom)
              const isFuture = shareDate.isAfter(date)

              return (
                <tr key={id} className={classNames(isFuture && styles.future)}>
                  <td>{shareDate.format(monthFormatHuman)}</td>
                  <td className={styles.money}>{shareNumber}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </SimpleModal>
    )
  }
}

const FormWrapper = validated(validationConfig)(_FormWrapper)

function validationConfig(props) {
  const {
    values: { validFrom, shareNumber },
  } = props
  return {
    fields: ['validFrom', 'shareNumber'],
    validations: {
      validFrom: [[validateDate, validFrom]],
      shareNumber: [[validateNonZeroInteger, shareNumber]],
    },
  }
}
